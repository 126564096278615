import React, { useState, useEffect } from "react";
import { store } from "../../store";
import { connect } from "react-redux";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import {
    Grid,
    Box,
    Tab,
    Button
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CompareArrows from "@material-ui/icons/CompareArrows";
import "react-datepicker/dist/react-datepicker.css";
import EditIcon from "@material-ui/icons/Edit";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { toast } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
    deleteDeviceAction,
    getDevicesAction,
    saveSharedDevicesAction,
    updateDeviceStatusAction,
    updateSharedDeviceStatusAction,
} from "../../actions/devicesActions";
import {
    getSpacesAction,
} from "../../actions/spacesActions";
import { addDevicesToSpaceAction } from "../../actions/spacesActions";
import DevicesShareModal from "../../components/devicesComponents/DevicesShareModal";
import MoveDeviceToSpaceModal from "../../components/devicesComponents/SpacesMoveModal";
import DeviceInfo from "../../components/devicesComponents/DeviceInfo";
import DevicesDeleteModal from "../../components/devicesComponents/DevicesDeleteModal";
import {
    DEVICES_SHARED_SUCCESSFULLY,
    deviceMovedMessage,
    deviceDeletedSuccessfully,
    deviceMoveToSpaceErrorMess,
    DEVICE_TYPE_SWITCH_4,
    DEVICE_TYPE_SWITCH_2,
    DEVICE_TYPE_SWITCH,
    DEVICE_TYPE_FAN,
    DEVICE_TYPE_LIGHT_LWCT,
    DEVICE_TYPE_LIGHT_LW,
    DEVICE_TYPE_LIGHT_LRGB,
    DEVICE_TYPE_LIGHT_LRGBW,
    DEVICE_TYPE_LIGHT_LRGBWCT,
    DEVICE_TYPE_TEMP_SENSOR,
    DEVICE_TYPE_SOCKET,
    DEVICE_OFFLINE_WARNING
} from "../../helper/constants";
import socket from "../../helper/socket";
import globalHelper from "../../helper/globalHelper";
import Loader from "../../components/Loader";
import SharedDevicesAccordion from "../../components/devicesComponents/SharedDevicesAccordion";
import EditGroupName from "../../components/devicesComponents/EditGroupName";
import EditDeviceName from "../../components/devicesComponents/EditDeviceName";
import MultipleDeviceControlModal from "../../components/devicesComponents/MultipleDeviceControlModal";
import { defaultValue, Validations } from "./MultipleDeviceControlValidation";
import SpaceSharingDevices from "../../components/devicesComponents/SpaceSharingDevices";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";

const DeviceList = ({
    devices,
    shared_devices,
    getDevicesAction,
    getSpacesAction,
    addDevicesToSpaceAction,
    updateDeviceStatusAction,
    updateSharedDeviceStatusAction,
    deleteDeviceAction,
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [sharedShowHide, setSharedShowHide] = useState(false);
    const [mobileNo, setMobileNo] = useState("");
    const [showMoveDeviceModal, setShowMoveDeviceModal] = useState(false);
    const [spacesDropDownId, setSpacesDorpDownId] = useState("");
    const [devicesList, setDevicesList] = useState([]);
    const [deviceInfoList, setdeviceInfoList] = useState([]);
    const [noSpaceDevices, setNoSpaceDevices] = useState([]);
    const [noSpaceDevicesId, setNoSpaceDevicesId] = useState([]);
    const [selectedDevicesDropdownValue, setSelectedDevicesDropdownValue] = useState([]);
    const [showDeviceInfo, setShowDeviceInfo] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [deleteMultipleDevicesModalShown, setDeleteMultipleDevicesModalShown] = useState(false);
    const [deleteSingleDeviceModalShown, setDeleteSingleDeviceModalShown] = useState(false);
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [showEditDevice, setShowEditDevice] = useState(false);
    const [showMultipleDeviceControlModal, setShowMultipleDeviceControlModal] = useState(false);
    const [showSpaceShare, setShowSpaceShare] = useState(false);
    const [shareInvalidModalShow, setShareInvalidModalShow] = useState(false);
    const [editParamsRowData, setEditParamsRowData] = useState();
    const localUserData = localStorage.getItem('smi-user');
    const jsonLocalUserData = JSON.parse(localUserData);
    const [value, setValue] = React.useState('1');
    /* breadCrumb */
    const DevicesBreadCrumb = [
        {
            title: "Devices",
            link: "/devices",
            classData: "active_anv_link",
        }
    ];

    useEffect(() => {

        setLoading(true);
        // You can await here
        getDevicesAction().then((response) => {
            setDevicesList(response);
            if (response && response.ownerDevice.devices.length) {
                response.ownerDevice.devices.forEach((device, index) => {
                    if (device.spaceId === undefined) {
                        noSpaceDevices.push({ label: device.instanceId.name, value: device.deviceMacId });
                        noSpaceDevicesId.push(device._id);
                    }
                    socket.io.on(`deviceDetail${device.deviceMacId}`, (socketData) => {
                        const socketListenedData = JSON.parse(socketData);
                        changeDeviceStatus(socketListenedData);
                    });
                    socket.io.on(`deviceDetailOnOff${device.deviceMacId}`, (socketData) => {
                        const socketListenedOnlineOffline = JSON.parse(socketData);
                        changeDeviceOnlineOfflineState(socketListenedOnlineOffline);
                    });
                });
            }
            if (response && response.sharedDevices.length) {
                response.sharedDevices.forEach((device, index) => {
                    socket.io.on(`deviceDetail${device.deviceMacId}`, (socketData) => {
                        const sharedSocketListenedData = JSON.parse(socketData);
                        changeSharedDeviceStatus(sharedSocketListenedData);
                    });
                });
            }
            setIsFetching(false);
            setLoading(false);
            setGroupName(response.ownerDevice.name);
        });
        getSpacesAction();
    }, []);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    /* handle multiple device(s) modal close */
    const onDeleteMultipleDevicesModelClose = () => {
        setDeleteMultipleDevicesModalShown(false);
    };
    /* handle multiple device(s) confirm */
    const onDeleteMultipleDevicesConfirm = async () => {
        handleMutipleDevicesDelete();
        onDeleteMultipleDevicesModelClose();
    };
    /* handle multiple device(s) delete */
    const handleMutipleDevicesDelete = async () => {
        if (selectedRows.length > 0) {
            devicesList.ownerDevice.devices.map(async (deviceData) => {
                if (selectedRows.includes(deviceData._id)) {
                    const deleteDevice = await deleteDeviceAction({
                        deviceMacId: deviceData.deviceMacId,
                    });
                    if (deleteDevice) {
                        toast.success(deviceDeletedSuccessfully, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        refreshComponent();
                    }
                }
            });
        }
        setSelectedRows([]);
    };
    /* handleMove singleDevice Delete modal event */
    const onDeleteSingleDeviceModelClose = () => {
        setDeleteSingleDeviceModalShown(false);
    };
    const onDeleteSingleDeviceConfirm = async () => {
        handleSingleDeviceDelete();
        onDeleteSingleDeviceModelClose();
    };
    const handleSingleDeviceDelete = async () => {
        const deleteSingle = devices.filter(e => e.deviceMacId === deviceInfoList.deviceMacId)
        const deviceMac = Object.assign({}, deleteSingle[0])
        const deleteDeviceSuccess = await deleteDeviceAction({
            deviceMacId: deviceMac.deviceMacId,
        })
        if (deleteDeviceSuccess.status === 200) {
            toast.success(deleteDeviceSuccess.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refreshComponent();
            setShowDeviceInfo(false);
        }
    };

    /* on/off status changes for owner devices */
    function changeDeviceStatus(socketListenedData) {
        const response = store.getState().devices;
        const updatedList =
            response &&
            response.map((device) => {
                if (socketListenedData.UUID === device.deviceMacId) {
                    const SocketDataObjkeys = Object.keys(socketListenedData.get_OnOff);
                    SocketDataObjkeys.forEach((key, index) => {
                        if (
                            key === DEVICE_TYPE_SWITCH_4 ||
                            DEVICE_TYPE_SWITCH_2

                        ) {
                            if (key === device.deviceMacId.split("-")[1]) {
                                device.on = socketListenedData.get_OnOff[key];
                                device.instanceId.caps.get_OnOff.params.on =
                                    socketListenedData.get_OnOff[key];
                                device.ownerDeviceOnOff =
                                    socketListenedData.get_OnOff[key];
                                device.instanceId.online = socketListenedData.online;
                                device.ownerDeviceOnline = socketListenedData.online;
                            }
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN
                            || String(device.deviceType.name) === DEVICE_TYPE_SWITCH
                            || String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR
                            || String(device.deviceType.name) === DEVICE_TYPE_SOCKET
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.online = socketListenedData.online;
                            device.ownerDeviceOnline = socketListenedData.online;
                            device.on = socketListenedData.get_OnOff.on;
                            device.instanceId.caps.get_OnOff.params.on =
                                socketListenedData.get_OnOff.on;
                            device.ownerDeviceOnOff =
                                socketListenedData.get_OnOff.on;
                            device.instanceId.updatedAt = socketListenedData.updatedAt;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN) {
                            device.instanceId.caps.get_FanSpeed.params.speed =
                                socketListenedData?.get_FanSpeed?.speed;
                        }
                        if (
                            socketListenedData?.get_Brightness?.brightness &&
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_Brightness.params.brightness =
                                socketListenedData?.get_Brightness?.brightness;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorTemperature.params.temperature =
                                socketListenedData?.get_ColorTemperature?.temperature;
                        }
                        if (String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorSpectrum.params.R = socketListenedData?.get_ColorSpectrum?.R;
                            device.instanceId.caps.get_ColorSpectrum.params.G = socketListenedData?.get_ColorSpectrum?.G;
                            device.instanceId.caps.get_ColorSpectrum.params.B = socketListenedData?.get_ColorSpectrum?.B;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR) {
                            device.instanceId.caps.get_TemperatureControl.params = socketListenedData?.get_TemperatureControl;
                            device.instanceId.caps.get_HumidityControl.params = socketListenedData?.get_HumidityControl;
                        }
                    });
                    return device;
                } else {
                    return device;
                }
            });
        updateDeviceStatusAction(updatedList);
    }

    /* online/offline status changes for owner devices */
    function changeDeviceOnlineOfflineState(socketListenedOnlineOffline) {
        const response = store.getState().devices;
        const updatedList =
            response &&
            response.map((device) => {
                if (socketListenedOnlineOffline.UUID === device.deviceMacId) {
                    if (String(device.deviceType.name) === DEVICE_TYPE_SWITCH_4
                        || String(device.deviceType.name) === DEVICE_TYPE_SWITCH_2
                        || String(device.deviceType.name) === DEVICE_TYPE_FAN
                        || String(device.deviceType.name) === DEVICE_TYPE_SWITCH
                        || String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR
                        || String(device.deviceType.name) === DEVICE_TYPE_SOCKET
                        || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW
                        || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                        String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGB ||
                        String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                        String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                        device.instanceId.online = socketListenedOnlineOffline.online;
                        device.ownerDeviceOnline = socketListenedOnlineOffline.online;
                    }
                    return device;
                }
                else {
                    return device;
                }

            });
        updateDeviceStatusAction(updatedList);
    }



    /* on/off status changes for shared devices */
    function changeSharedDeviceStatus(socketListenedData) {
        const sharedResponse = store.getState().shared_devices;
        const updatedList =
            sharedResponse &&
            sharedResponse.map((device) => {
                if (socketListenedData.UUID === device.deviceMacId) {
                    const SocketDataObjkeys = Object.keys(socketListenedData.get_OnOff);
                    SocketDataObjkeys.forEach((key, index) => {
                        if (
                            key === DEVICE_TYPE_SWITCH_4 ||
                            DEVICE_TYPE_SWITCH_2
                        ) {
                            if (key === device.deviceMacId.split("-")[1]) {
                                device.on = socketListenedData.get_OnOff[key];
                                device.instanceId.caps.get_OnOff.params.on =
                                    socketListenedData.get_OnOff[key];
                                device.ownerDeviceOnOff = socketListenedData.get_OnOff[key];
                            }
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN
                            || String(device.deviceType.name) === DEVICE_TYPE_SWITCH
                            || String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR
                            || String(device.deviceType.name) === DEVICE_TYPE_SOCKET
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.on = socketListenedData?.get_OnOff.on;
                            device.instanceId.caps.get_OnOff.params.on =
                                socketListenedData?.get_OnOff.on;
                            device.ownerDeviceOnOff =
                                socketListenedData?.get_OnOff.on;
                            device.instanceId.updatedAt = socketListenedData.updatedAt;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN) {
                            device.instanceId.caps.get_FanSpeed.params.speed =
                                socketListenedData?.get_FanSpeed?.speed;
                        }
                        if (socketListenedData?.get_Brightness?.brightness &&
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_Brightness.params.brightness =
                                socketListenedData?.get_Brightness?.brightness;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorTemperature.params.temperature =
                                socketListenedData?.get_ColorTemperature?.temperature;
                        }
                        if (String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorSpectrum.params.R = socketListenedData?.get_ColorSpectrum?.R;
                            device.instanceId.caps.get_ColorSpectrum.params.G = socketListenedData?.get_ColorSpectrum?.G;
                            device.instanceId.caps.get_ColorSpectrum.params.B = socketListenedData?.get_ColorSpectrum?.B;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR) {
                            device.instanceId.caps.get_TemperatureControl.params = socketListenedData?.get_TemperatureControl;
                            device.instanceId.caps.get_HumidityControl.params = socketListenedData?.get_HumidityControl;
                        }
                    });
                    return device;
                } else {
                    return device;
                }
            });
        updateSharedDeviceStatusAction(updatedList);
    }
    /* prepare shared devices array for accordion */
    let dataArray = [];
    let arrayFinal = [];
    let finalArrayShared = [];
    shared_devices.map((sharedDevicesValue, i) => {
        if (!dataArray.includes(sharedDevicesValue.userId)) {
            dataArray.push(sharedDevicesValue.userId);
        }
    });
    dataArray.map((dataArrayValue, i) => {
        let finalData = shared_devices.filter((item, y) => item.userId === dataArrayValue);
        arrayFinal = finalData;
        finalArrayShared.push(arrayFinal);
    });
    /* shareModalOpen event */
    const shareModalOpen = () => {
        setSharedShowHide(true);
    };
    /* shareModalClose event */
    const shareModalClose = () => {
        setSharedShowHide(false);
        toast.dismiss();
    };
    /* shareModal user mobile number change event */
    const handleChangeUsers = (e) => {
        if (e.target.value) {
            setMobileNo(e.target.value);
        }
    };
    /* space onchange handle event */
    const handleChangeSpaces = (e) => {
        setSpacesDorpDownId(e.target.value);
    };
    /* move to space handle submit */
    const handleSubmitSpace = async () => {
        setLoading(true);
        if (spacesDropDownId.length == 0) {
            toast.error(deviceMoveToSpaceErrorMess, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
        } else {
            let moveSpacesArray = [];
            if (selectedRows.length > 0) {
                devicesList.ownerDevice.devices.map((deviceData) => {
                    if (selectedRows.includes(deviceData._id)) {
                        moveSpacesArray.push(deviceData.deviceMacId);
                    }
                });
            }
            /* save device move to space action call */
            const addDevicesToSpaceResponse = await addDevicesToSpaceAction({
                spaceId: spacesDropDownId,
                deviceMacIds: '"' + moveSpacesArray + '"',
            });
            setLoading(false);
            if (addDevicesToSpaceResponse) {
                refreshComponent();
                setSelectedRows([]);
                setSpacesDorpDownId("");
                toast.success(deviceMovedMessage, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            handleMoveDeviceModalClose();
        }
    };
    /* shareModal submit event */
    const handleSubmitSharedDevices = async (e) => {
        setLoading(true);
        e.preventDefault();
        let sharedDevicesArray = [];
        if (selectedRows.length > 0) {
            devicesList.ownerDevice.devices.forEach((devicesItem, index) => {
                let newObj = {};
                newObj.shared_with_mobile_no = mobileNo;
                newObj.userId = jsonLocalUserData.user._id;
                newObj.user_name = jsonLocalUserData.user.name;
                if (selectedRows.includes(devicesItem._id)) {
                    newObj.deviceMacId = devicesItem.deviceMacId;
                    newObj.deviceType = devicesItem.deviceType._id;
                    newObj.instanceId = devicesItem.instanceId._id;
                    newObj.spaceId = devicesItem.spaceId;
                    newObj.manufacturer = devicesItem.manufacturer._id;
                    newObj.isShared = true;
                    let data = Object.assign(newObj);
                    sharedDevicesArray.push(data);
                }
            });
        }
        /* save shared devices action call */
        const saveSharedSuccess = await saveSharedDevicesAction(sharedDevicesArray);
        setLoading(false);
        if (!saveSharedSuccess) {
            toast.success(DEVICES_SHARED_SUCCESSFULLY, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSelectedRows([]);
        } else {
            toast.error(saveSharedSuccess.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        shareModalClose();
    };
    /* handleMove device open modal event */
    const handleMoveDeviceModal = () => {
        setShowMoveDeviceModal(true);
    };
    /* handleMove device close modal event */
    const handleMoveDeviceModalClose = () => {
        refreshComponent();
        setShowMoveDeviceModal(false);
    };
    /* handle space share open modal event */
    const handleSpaceShareOpen = () => {
        const ShareDeviceArr = [];
        selectedRows.forEach(rowId => {
            const isShareAllowed = noSpaceDevicesId.find(o => o === rowId);
            ShareDeviceArr.push(isShareAllowed);
        })
        if(ShareDeviceArr.includes(undefined)) {
            setShareInvalidModalShow(true);
            setShowSpaceShare(false);
        }
        else {
            let selectedDevicesValue = [];
            devicesList.ownerDevice.devices.forEach(async(devicesDataItem) => {
                if (selectedRows.includes(devicesDataItem._id)) {                    
                    selectedDevicesValue.push({ label: devicesDataItem.instanceId.name, value: devicesDataItem.deviceMacId });
                    setSelectedDevicesDropdownValue(selectedDevicesValue);
                }
            });
            setShareInvalidModalShow(false);
            setShowSpaceShare(true);
        }
        
    }
    /* handle space share close modal event */
    const handleSpaceShareClose = () => {
        setSelectedRows([]);
        setShowSpaceShare(false);
        toast.dismiss();
    }
    /* handle device info event */
    const handleShowDeviceInfo = () => {
        setShowDeviceInfo(false);
    }

    const onShareInvalidModalClose = () => {
        setShareInvalidModalShow(false);
    }

    /* handle multiple device control modal close */
    const handleMulitpleDeviceControlModalClose = () => {
        setShowMultipleDeviceControlModal(false);
    }

    const updateFirmwareSettingState = () => {
        setLoading(true);
        setIsFetching(true);
        noSpaceDevices.length = 0;
        noSpaceDevicesId.length = 0;
        getDevicesAction().then((response) => {
            setDevicesList(response);
            if (response && response.ownerDevice.devices.length) {
                response.ownerDevice.devices.forEach((device, index) => {
                    if (device.spaceId === undefined) {
                        noSpaceDevices.push({ label: device.instanceId.name, value: device.deviceMacId });
                        noSpaceDevicesId.push(device._id);
                    }
                    if (device.deviceMacId === deviceInfoList.deviceMacId) {
                        setdeviceInfoList(device);
                    }
                });
            }
            setGroupName(response.ownerDevice.name);
            setIsFetching(false);
            setLoading(false);
        });
        getSpacesAction();
    };

    /* refresh after delete device(s) */
    const refreshComponent = () => {
        setLoading(true);
        setIsFetching(true);
        noSpaceDevices.length = 0;
        noSpaceDevicesId.length = 0;
        getDevicesAction().then((response) => {
            setDevicesList(response);
            if (response && response.ownerDevice.devices.length) {
                response.ownerDevice.devices.forEach((device, index) => {
                    if (device.spaceId === undefined) {
                        noSpaceDevices.push({ label: device.instanceId.name, value: device.deviceMacId });
                        noSpaceDevicesId.push(device._id);
                    }
                });
            }
            setGroupName(response.ownerDevice.name);
            setIsFetching(false);
            setLoading(false);
        });
        getSpacesAction();
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarFilterButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    item
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    {selectedRows.length && selectedRows.length > 0 ? (
                        <>
                            <div>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Delete device(s)
                                    </Tooltip>
                                }
                            >
                                    <div>
                            <DeleteOutlinedIcon
                                onClick={() => {
                                    setDeleteMultipleDevicesModalShown(true);
                                }}
                                style={{ fontSize: 40, color: "#fff", background: "#868aa8", padding: "8", margin: "0 5", cursor: "pointer" }}
                            />
                                    </div>
                            </OverlayTrigger>
                            </div>
                            <div>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Move to space
                                    </Tooltip>
                                }
                            >
                                    <div>
                            <CompareArrows
                                style={{ fontSize: 40, marginRight: "20px", cursor: "pointer" }}
                                onClick={handleMoveDeviceModal}
                            />{" "}
                                    </div>
                            </OverlayTrigger>
                            </div>
                            <div>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Share device(s)
                                        </Tooltip>
                                    }
                                >
                                    <div>
                            <ShareIcon
                                onClick={handleSpaceShareOpen}
                                style={{ fontSize: 30, marginRight: "20px", marginTop: "5px", cursor: "pointer" }}
                            />{" "}
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </>
                    ) : ("")}
                </Grid>
            </GridToolbarContainer>
        );
    };

    const sendSocketEventData = async (deviceData, deviceExtraPayload, deviceTemperature, deviceRGB) => {
        if (deviceData.instanceId.online) {
            const payload = await globalHelper.getDeviceSocketPayload(deviceData, deviceExtraPayload, deviceTemperature, deviceRGB);
            socket.io.emit("deviceDetail-v1", payload);
        }
        else {
            toast.error(DEVICE_OFFLINE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const columns = [
        {
            field: "deviceType",
            headerName: "Image",
            type: "singleSelect",
            editable: false,
            sortable: false,
            filterable: false,
            width: 100,
            valueOptions: ["light", "switch", "fan"],
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <img
                            alt={params.value.icon}
                            style={{ height: "50px", width: "50px" }}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${params.value.icon}`}
                        />
                    </div>
                );
            },
        },
        {
            field: "ownerName",
            headerName: "Name",
            width: 160,
            align: "left",
            headerAlign: "center",
            editable: true,
            sortable: true,
            filterable: true,
            renderCell: (params) => {
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                Edit device name
                            </Tooltip>
                        }
                    >
                        <span
                            onClick={
                                () => {
                                    setEditParamsRowData(params.row)
                                    setShowEditDevice(true);
                                }}
                            style={{ color: "#3f51b5", cursor: "pointer" }}
                        >
                            {params.row.ownerName}
                        </span>
                    </OverlayTrigger>
                );
            },
        },
        {
            field: "ownerDeviceOnline",
            headerName: "Online",
            type: "boolean",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: true,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        justifyContent="center"
                    >
                        <p
                            style={{
                                fontSize: 10,
                                marginTop: "13px",
                                marginRight: "10px",
                            }}
                        >
                            {params.row.ownerDeviceOnline ? "online" : "offline"}
                        </p>
                        <img
                            style={{ width: "20px", height: "13px" }}
                            src={`${process.env.PUBLIC_URL}images/${params.row.ownerDeviceOnline ? "cloudact.png" : "cloud.png"
                                }`}
                        />
                    </Grid>
                );
            },
        },
        {
            field: "ownerDeviceOnOff",
            headerName: "On",
            type: "boolean",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.deviceType.name === DEVICE_TYPE_TEMP_SENSOR) {
                    return (
                        <Grid
                        container
                        justifyContent="center"
                        style={{ alignItems: "center", cursor: "pointer" }}
                        >
                            <img src="images/temperature-icon.png" width="20px" height="27px" className="pr-2" />
                        {(params.row.instanceId?.caps?.get_TemperatureControl?.params?.currentTemperature).toFixed(2)}</Grid>
                    );
                }
                else {
                    const imgPath =
                        params.row.ownerDeviceOnline &&
                            params.row.ownerDeviceOnOff
                            ? "images/onbtn.png"
                            : "images/offbtn.png";
                    return (
                        <Grid
                            container
                            justifyContent="center"
                            style={{ alignItems: "center", cursor: "pointer" }}
                        >
                            <img
                                alt={params.row._id}
                                onClick={() => {
                                    sendSocketEventData(params.row);
                                }}
                                style={{ width: "35px", height: "35px" }}
                                src={process.env.PUBLIC_URL + imgPath}
                            />
                        </Grid>
                    );
                }
            },
        },
        {
            field: "spaceId",
            headerName: "Space",
            width: 120,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                let spaceName =
                    params.value && params.value.name ? params.value.name : "-";
                return spaceName;
            },
        },
        {
            field: "deviceInfo",
            headerName: "Info",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                Device info
                            </Tooltip>
                        }
                    >
                    <ChevronRightIcon
                        onClick={() => {
                            setShowDeviceInfo(true);
                            setdeviceInfoList(params.row);
                        }}
                        style={{ fontSize: 20, cursor: "pointer" }}
                    />
                    </OverlayTrigger>
                );
            },
        }
    ];

    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            {sharedShowHide &&
            <DevicesShareModal
                ShowHide={sharedShowHide}
                shareModalOpen={shareModalOpen}
                shareModalClose={shareModalClose}
                handleChangeUsers={handleChangeUsers}
                handleSubmitSharedDevices={handleSubmitSharedDevices}
                loading={loading}
                />}
            {showMoveDeviceModal && 
                <MoveDeviceToSpaceModal
                show={showMoveDeviceModal}
                onHide={handleMoveDeviceModalClose}
                handleChangeSpaces={handleChangeSpaces}
                spacesDropDownId={spacesDropDownId}
                handleSubmitSpace={handleSubmitSpace}
                loading={loading}
                />}
            {showSpaceShare &&
            <SpaceSharingDevices
                show={showSpaceShare}
                onHide={handleSpaceShareClose}
                onClose={handleSpaceShareClose}
                refreshComponent={refreshComponent}
                devicesOptions={noSpaceDevices}
                loading={loading}
                selectedDevicesDropdownValue={selectedDevicesDropdownValue}
                    devicesList={devicesList}
                />}
            <DevicesDeleteModal
                modalShown={deleteMultipleDevicesModalShown}
                onModelClose={onDeleteMultipleDevicesModelClose}
                onDeleteConfirm={onDeleteMultipleDevicesConfirm}
                headerTitle="Delete Devices"
                message={`Are you sure you want to deleted this device(s)?`}
            />
            {/* device info single delete device */}
            <DevicesDeleteModal
                modalShown={deleteSingleDeviceModalShown}
                onModelClose={onDeleteSingleDeviceModelClose}
                onDeleteConfirm={onDeleteSingleDeviceConfirm}
                headerTitle="Delete Device"
                message={`Are you sure you want to deleted this device?`}
            />
           {shareInvalidModalShow &&
            <DevicesDeleteModal
                modalShown={shareInvalidModalShow}
                onModelClose={onShareInvalidModalClose}
                headerTitle="Invalid Selection"
                message={`Please select device(s) that are not in space`}
                hideButton={true}
            />}
            {showMultipleDeviceControlModal && (
                <MultipleDeviceControlModal
                    show={showMultipleDeviceControlModal}
                    onHide={handleMulitpleDeviceControlModalClose}
                    devicesList={devicesList}
                    defaultValue={defaultValue}
                    validations={Validations}
                    refreshComponent={refreshComponent}
                />)}
            {showDeviceInfo ? (<>
                <DeviceInfo
                    updateFirmwareSettingState={updateFirmwareSettingState}
                    deviceInfoList={deviceInfoList}
                    handleShowDeviceInfo={handleShowDeviceInfo}
                    sendSocketEventData={sendSocketEventData}
                    sharedCondition={true}
                    setDeleteSingleDeviceModalShown={setDeleteSingleDeviceModalShown}
                    setShowEditDevice={setShowEditDevice}
                    setEditParamsRowData={setEditParamsRowData}
                    setShowDeviceInfo={setShowDeviceInfo}
                    refreshComponent={refreshComponent}
                />
            </>)
                : showEditGroup ? (
                    <>
                        <EditGroupName
                            setShowEditGroup={setShowEditGroup}
                            devicesList={devicesList}
                            refreshComponent={refreshComponent}
                        />
                    </>
                ) : showEditDevice ? (
                    <>
                        <EditDeviceName
                            setShowEditDevice={setShowEditDevice}
                            editParamsRowData={editParamsRowData}
                                refreshComponent={refreshComponent}
                        />
                    </>
                ) : (
                    <>
                        <div className="m-grid__item m-grid__item--fluid m-wrapper">
                            <div className="m-content">
                                <div className="m-portlet m-portlet--mobile">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                                    <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                                        <h2>Devices</h2>
                                                        <CustomBreadCrumb breadCrumData={DevicesBreadCrumb} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-portlet__body data_height">
                                                <div className="row DeviceListHeader">
                                                    <Grid container xs={12} spacing={3}
                                                        item
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "initial",
                                                            flexWrap: "nowrap",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                            margin: "0 0 10px"
                                                        }}>
                                                <Grid container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            xs={12}
                                                            item>
                                                        <div>
                                                            <img
                                                                style={{ width: "30px", height: "30px" }}
                                                    src={process.env.PUBLIC_URL + "images/groupicn.png"}
                                                />
                                                            <h3>
                                                    {groupName ? groupName : ""}
                                                                </h3>
                                                                <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip>
                                                                    Edit group name
                                                                </Tooltip>
                                                            }
                                                        >
                                                    <EditIcon
                                                        onClick={() => {
                                                            setShowEditGroup(true);
                                                        }}
                                                        style={{
                                                            fontSize: "40px",
                                                            padding: "8px",
                                                            color: "#fff",
                                                            background: "#868aa8",
                                                            cursor: "pointer",
                                                        }}
                                                        />
                                                        </OverlayTrigger>
                                                            </div>

                                                            <Grid>
                                                                <>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        style={{ fontSize: 12, marginTop: "5px", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setShowMultipleDeviceControlModal(true);
                                                                        }}
                                                                    >
                                                                        Multiple Device Control
                                                                    </Button>
                                                                </>
                                                            </Grid>

                                             </Grid>

                                        </Grid>

                                        </div>
                                                <div className="DeviceListTab">
                                        <Box sx={{ width: '100%', typography: 'body1' }}>
                                                        <TabContext value={value}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                        <Tab label="Owner Devices" value="1" />
                                                                    <Tab label="Group Shared With Me" value="2" />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value="1">
                                                    <>
                                                                    <div className="row">
                                                            <div
                                                                style={{
                                                                    height: "85vh",
                                                                    width: "100%",
                                                                                margin: "0 auto",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                }}
                                                            >
                                                                        {devices && devices.length ? (
                                                                                <div style={{ height: "85vh", width: "100%", margin: "0 auto" }}>
                                                                            {devices && devices.length ? (
                                                                                <DataGrid
                                                                                            getRowId={(row) => row?._id}
                                                                                    isRowSelectable={(params) => params.row.shared !== true}
                                                                                    checkboxSelection={true}
                                                                                    disableSelectionOnClick={true}
                                                                                    rows={devices}
                                                                                    rowHeight={40}
                                                                                            columns={columns}
                                                                                    components={{
                                                                                        Toolbar: CustomToolbar,
                                                                                    }}
                                                                                            onSelectionModelChange={(newSelection) => {
                                                                                                setSelectedRows(newSelection);
                                                                                    }}
                                                                                    selectionModel={selectedRows}
                                                                                />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            </div>) : (<>
                                                                                    <b style={{ color: "black" }}>No Devices Found</b>
                                                                </>)}
                                                            </div>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                                <TabPanel value="2">
                                                    <>
                                                                    <div className="row">
                                                            <div
                                                                style={{
                                                                    height: "85vh",
                                                                    width: "100%",
                                                                                margin: "0 auto",
                                                                }}
                                                            >
                                                                        {finalArrayShared && finalArrayShared.length ? finalArrayShared.map((value, p) => {
                                                                    return (
                                                                        <>
                                                                            <SharedDevicesAccordion sharedItemsData={value}
                                                                            />
                                                                        </>
                                                                    )
                                                                        }) : <>
                                                                                    <div style={{
                                                                                        height: "45vh",
                                                                                        width: "100%",
                                                                                        margin: "0 auto",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}>
                                                                                        <b style={{ color: "black" }}>No Shared Group Found</b>
                                                                                    </div>
                                                                        </>}
                                                            </div>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                                <TabPanel value="3">Item Three</TabPanel>
                                            </TabContext>
                                        </Box>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    devices: state.devices,
    shared_devices: state.shared_devices,
});

export default connect(mapStateToProps, {
    getDevicesAction,
    getSpacesAction,
    saveSharedDevicesAction,
    addDevicesToSpaceAction,
    updateDeviceStatusAction,
    updateSharedDeviceStatusAction,
    deleteDeviceAction,
})(DeviceList);
