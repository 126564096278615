import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import addMinutes from "date-fns/addMinutes";
import moment from "moment";
import { Typography } from "@material-ui/core";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { SketchPicker } from "react-color";
import {
    createMultipleDeviceSchedulerAction,
    EditSchedulerAction
} from "../../actions/devicesActions";
import { DimmingSlider, ToneSlider } from "../../containers/devices/Sliders";
import {
    DEVICE_TYPE_SOCKET,
    DEVICE_TYPE_TEMP_SENSOR,
    DEVICE_TYPE_SWITCH_4,
    DEVICE_TYPE_SWITCH_2,
    DEVICE_TYPE_SWITCH,
    DEVICE_TYPE_FAN,
    DEVICE_TYPE_LIGHT_LW,
    DEVICE_TYPE_LIGHT_LWCT,
    DEVICE_TYPE_LIGHT_LRGB,
    DEVICE_TYPE_LIGHT_LRGBW,
    DEVICE_TYPE_LIGHT_LRGBWCT,
    WEEK_DAY_SELECTION_WARNING,
    schedulerDeviceTypeOptions,
    schedulerRepeatOptions,
    dateTimeErrorMessage,
    ADD_SCHEDULER_TIME_WARNING
} from "../../helper/constants";
import LoadingSubmit from "../LoadingSubmit";
import globalHelper from "../../helper/globalHelper";
import "../../containers/scheduler/Scheduler.css";

const AddEditSchedulerModal = ({
    devices, spaces, createMultipleDeviceSchedulerAction,
    EditSchedulerAction, editSchedulerModal,
    show, onHide, validations, defaultValue,
    refreshComponent, schedulerId }) => {
    const [devicesDropdownOptions, setDevicesDropdownOptions] = useState([]);
    const [spacesDropdownOptions, setSpacesDropdownOptions] = useState([]);
    const [showRepeatDailyWeeklyFields, setShowRepeatDailyWeeklyFields] = useState(false);
    const [disableEndRepeatOccurrence, setDisableEndRepeatOccurrence] = useState(true);
    const [disableEndRepeatDate, setDisableEndRepeatDate] = useState(true);
    const [showSpeed, setShowSpeed] = useState(false);
    const [showBrightnessControl, setShowBrightnessControl] = useState(false);
    const [showTemperatureControl, setShowTemperatureControl] = useState(false);
    const [showRGBControl, setShowRGBControl] = useState(false);
    const [showWeekDayField, setShowWeekDayField] = useState(false);
    const [daysOfWeekFieldValue, setDaysOfWeekFieldValue] = useState([new Date().getDay()]);

    useEffect(() => {
        callUseEffect();
    }, []);

    const callUseEffect = async () => {
        if (editSchedulerModal !== undefined) {
            devicesDropdownOptions.length = 0;
            spacesDropdownOptions.length = 0;
            if (defaultValue.schedulerType === "type") {
                devices.forEach((ownerDevices) => {
                    if ((defaultValue.deviceType.value === ownerDevices.deviceType.name)) {
                        devicesDropdownOptions.push({
                            label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
                            value: ownerDevices.deviceMacId
                        });
                        if (defaultValue.deviceType.value === DEVICE_TYPE_FAN) {
                            setShowSpeed(true);
                        }
                        if (defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LW ||
                            defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LWCT ||
                            defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LRGBW ||
                            defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            setShowBrightnessControl(true);
                        }
                        if (defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LWCT ||
                            defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            setShowTemperatureControl(true);
                        }
                        if (defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LRGB ||
                            defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LRGBW ||
                            defaultValue.deviceType.value === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            setShowRGBControl(true);
                        }
                    }
                });
            }
            else if (defaultValue.schedulerType === "space") {
                spaces.forEach((spacesData) => {
                    spacesDropdownOptions.push({
                        label: spacesData.name,
                        value: spacesData._id
                    })
                });
                devices.forEach((ownerDevices) => {
                    defaultValue.spacesSelectedValue.forEach((spacesSelectedObj) => {
                        if ((ownerDevices.spaceId) && (ownerDevices.spaceId._id === spacesSelectedObj.value)) {
                            devicesDropdownOptions.push({
                                label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
                                value: ownerDevices.deviceMacId
                            });
                            defaultValue.deviceMacId.forEach((selectedDevices) => {
                                if (selectedDevices.value === ownerDevices.deviceMacId) {
                                    if (ownerDevices.deviceType.name === DEVICE_TYPE_FAN) {
                                        setShowSpeed(true);
                                    }
                                    if (ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LW ||
                                        ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                                        ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                                        ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
                                        setShowBrightnessControl(true);
                                    }
                                    if (ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                                        ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
                                        setShowTemperatureControl(true);
                                    }
                                    if (ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LRGB ||
                                        ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                                        ownerDevices.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
                                        setShowRGBControl(true);
                                    }
                                }
                            })
                        }
                    })
                });
            }
            if ((defaultValue.scheduler_repeat_options.value === "Daily") || (defaultValue.scheduler_repeat_options.value === "Weekly")) {
                setShowRepeatDailyWeeklyFields(true);
                if (defaultValue.end_repeat === "occurrence") {
                    setDisableEndRepeatOccurrence(false);
                    setDisableEndRepeatDate(true);
                }
                if (defaultValue.end_repeat === "after") {
                    setDisableEndRepeatDate(false);
                    setDisableEndRepeatOccurrence(true);
                }
                if (defaultValue.end_repeat === "never") {
                    setDisableEndRepeatDate(true);
                    setDisableEndRepeatOccurrence(true);
                }
            }
            if (defaultValue.scheduler_repeat_options.value === "Weekly") {
                setShowWeekDayField(true);
                setDaysOfWeekFieldValue(defaultValue.daysOfWeekValue);
            }
        }
        else {
            defaultValue.schedule_date = addMinutes(new Date(), 2);
            defaultValue.schedule_fromDate = new Date();
            defaultValue.schedule_time = addMinutes(new Date(), 2);
            defaultValue.end_repeat_date = addDays(new Date(), 1);
        }
    }

    const handleDevicesFieldOptions = (deviceTypeValue, setFieldValue) => {
        let selectedTypeDevicesValue = [];
        devicesDropdownOptions.length = 0;
        setFieldValue("deviceMacId", []);
        devices.forEach((ownerDevices) => {
            if (ownerDevices.deviceType.name === deviceTypeValue) {

                selectedTypeDevicesValue.push({
                    label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
                    value: ownerDevices.deviceMacId
                })
                setFieldValue("deviceMacId", selectedTypeDevicesValue);

                devicesDropdownOptions.push({
                    label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
                    value: ownerDevices.deviceMacId
                });
            }
        })
        if (deviceTypeValue === DEVICE_TYPE_FAN) {
            setShowSpeed(true);
        }
        else {
            setShowSpeed(false);
        }
        if (deviceTypeValue === DEVICE_TYPE_LIGHT_LW ||
            deviceTypeValue === DEVICE_TYPE_LIGHT_LWCT ||
            deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBW ||
            deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBWCT) {
            setShowBrightnessControl(true);
        }
        else {
            setShowBrightnessControl(false);
        }
        if (deviceTypeValue === DEVICE_TYPE_LIGHT_LWCT ||
            deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBWCT) {
            setShowTemperatureControl(true);
        }
        else {
            setShowTemperatureControl(false);
        }
        if (deviceTypeValue === DEVICE_TYPE_LIGHT_LRGB ||
            deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBW ||
            deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBWCT) {
            setShowRGBControl(true);
        }
        else {
            setShowRGBControl(false);
        }
    }

    const handleSpacesFieldOptions = (schedulerTypeValue) => {
        if (schedulerTypeValue === "space") {
            devicesDropdownOptions.length = 0;
            spacesDropdownOptions.length = 0;
            setShowSpeed(false);
            setShowBrightnessControl(false);
            setShowTemperatureControl(false);
            setShowRGBControl(false);
            spaces.forEach((spacesData) => {
                spacesDropdownOptions.push({
                    label: spacesData.name,
                    value: spacesData._id
                })
            });
        }
        else if (schedulerTypeValue === "type") {
            devicesDropdownOptions.length = 0;
            spacesDropdownOptions.length = 0;
            setShowSpeed(false);
            setShowBrightnessControl(false);
            setShowTemperatureControl(false);
            setShowRGBControl(false);
        }
    }

    const handleSpaceDevicesFieldOptions = (onChangeSpacesArr, setFieldValue) => {
        let selectedSpaceDevicesValue = [];
        /*   let actionClear = action; */
        devicesDropdownOptions.length = 0;
        setFieldValue("deviceMacId", []);
        setShowSpeed(false);
        setShowBrightnessControl(false);
        setShowTemperatureControl(false);
        setShowRGBControl(false);
        /*  if (actionClear.action === "clear" || onChangeSpacesArr === null) {
             setFieldValue("deviceMacId", []);
         } */
        onChangeSpacesArr != null && onChangeSpacesArr.forEach((spacesElement) => {
            devices.forEach((ownerDevices) => {
                if ((ownerDevices.spaceId) && (ownerDevices.spaceId._id === spacesElement.value)) {
                    selectedSpaceDevicesValue.push({
                        label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
                        value: ownerDevices.deviceMacId
                    })
                    setFieldValue("deviceMacId", selectedSpaceDevicesValue);
                    devicesDropdownOptions.push({
                        label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
                        value: ownerDevices.deviceMacId
                    });
                    handleSpaceDevicesControl(selectedSpaceDevicesValue, "space");
                }
            })
        })
    }

    const handleRepeatDailyWeeklyFields = (onChangeRepeatValue, setFieldValue) => {
        if (onChangeRepeatValue === "Daily" || onChangeRepeatValue === "Weekly") {
            setShowRepeatDailyWeeklyFields(true);
        }
        else {
            setShowRepeatDailyWeeklyFields(false);
        }
        if (onChangeRepeatValue === "Weekly") {
            setShowWeekDayField(true);
            setFieldValue("daysOfWeekValue", []);
        }
        else {
            setShowWeekDayField(false);
        }
    }

    const handleDisableEndRepeatTypeFields = (endRepeatValue) => {
        if (endRepeatValue === "occurrence") {
            setDisableEndRepeatOccurrence(false);
            setDisableEndRepeatDate(true);
        }
        else if (endRepeatValue === "after") {
            setDisableEndRepeatDate(false);
            setDisableEndRepeatOccurrence(true);
        }
        else {
            setDisableEndRepeatDate(true);
            setDisableEndRepeatOccurrence(true);
        }
    }

    const handleSpaceDevicesControl = (devicesSelected, schedulerTypeChecked) => {
        if (schedulerTypeChecked === "space") {
            setShowSpeed(false);
            setShowBrightnessControl(false);
            setShowTemperatureControl(false);
            setShowRGBControl(false);
            if (devicesSelected && devicesSelected.length > 0) {
                devicesSelected.forEach((selectedMacId) => {
                    devices.forEach((deviceDetailObj) => {
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) && (deviceDetailObj.deviceType.name === DEVICE_TYPE_FAN)) {
                            setShowSpeed(true);
                        }
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                            (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LW ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                            setShowBrightnessControl(true);
                        }
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                            (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                            setShowTemperatureControl(true);
                        }
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                            (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGB ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                            setShowRGBControl(true);
                        }
                    })
                })
            }
            else {
                setShowBrightnessControl(false);
                setShowTemperatureControl(false);
                setShowRGBControl(false);
                setShowSpeed(false);
            }
        }
    }

    const handleWeekDayField = (weekDayValue, weekDayChecked, setFieldValue) => {
        // * Insert week day name in array (state)
        if (
            (weekDayValue == 0 ||
                weekDayValue == 1 ||
                weekDayValue == 2 ||
                weekDayValue == 3 ||
                weekDayValue == 4 ||
                weekDayValue == 5 ||
                weekDayValue == 6) &&
            weekDayChecked == true
        ) {
            setDaysOfWeekFieldValue([
                ...daysOfWeekFieldValue,
                parseInt(weekDayValue),
            ])
            setFieldValue("daysOfWeekValue", [
                ...daysOfWeekFieldValue,
                parseInt(weekDayValue),
            ]);
        }
        // * Delete week day name from array (state)
        if (
            (weekDayValue == 0 ||
                weekDayValue == 1 ||
                weekDayValue == 2 ||
                weekDayValue == 3 ||
                weekDayValue == 4 ||
                weekDayValue == 5 ||
                weekDayValue == 6) &&
            weekDayChecked == false &&
            daysOfWeekFieldValue.includes(parseInt(weekDayValue))
        ) {
            const oneIndexValue = daysOfWeekFieldValue.indexOf(
                parseInt(weekDayValue)
            );
            daysOfWeekFieldValue.splice(oneIndexValue, 1);
            setDaysOfWeekFieldValue(daysOfWeekFieldValue);
            setFieldValue("daysOfWeekValue", daysOfWeekFieldValue);
            if (daysOfWeekFieldValue.length == 0) {
                toast.error(
                    WEEK_DAY_SELECTION_WARNING, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                }
                );
            }
        }
    }

    const devicesCustomStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            maxHeight: '80px',
            overflowY: state.hasValue ? 'scroll' : 'hidden',
        }),
    }

    const spacesFilterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    const spacesCustomValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => label.split(" (")[0]).toString()
            : "Select...";
    };


    const filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => label.split(" (")[0]).toString()
            : "Select...";
    };

    const handleSubmit = async (evt, { setSubmitting, setErrors, resetForm, errors }) => {
        const currentDate = new Date().toLocaleString();
        const parseCurrentDate = Date.parse(currentDate);
        const parseUserDate = Date.parse(evt.schedule_date);
        const parseRepeatFromTime = Date.parse(evt.schedule_time);
        const parseRepeatFromDate = Date.parse(evt.schedule_fromDate);
        if (
            (evt.scheduler_repeat_options.value === "Once") &&
            (parseUserDate < parseCurrentDate)
        ) {
            toast.error(dateTimeErrorMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (
            (evt.scheduler_repeat_options.value === "Daily" ||
                evt.scheduler_repeat_options.value === "Weekly") &&
            (parseRepeatFromTime < parseCurrentDate) &&
            (parseRepeatFromDate < parseCurrentDate) &&
            (editSchedulerModal === undefined)
        ) {
            toast.error(ADD_SCHEDULER_TIME_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            let deviceDetailArray = [];
            let newDeviceMacId = [];
            let weekDayValue;
            evt.deviceMacId.forEach(async (selectedDevices) => {
                devices.forEach(async (findDeviceDetail) => {
                    if (findDeviceDetail.deviceMacId === selectedDevices.value) {
                        findDeviceDetail.pageName = "Rules";
                        let sendFanSpeedValue = undefined;
                        let payload;
                        findDeviceDetail.requestedDeviceStatus = evt.deviceStatus;
                        if (findDeviceDetail.deviceType.name === DEVICE_TYPE_FAN) {
                            sendFanSpeedValue = Number(evt.fanSpeed);
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, sendFanSpeedValue);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_4 ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_2 ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_TEMP_SENSOR ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_SOCKET) {
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LW) {
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LWCT) {
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGB) {
                            const RGBObj = {
                                r: evt.R,
                                g: evt.G,
                                b: evt.B
                            };
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, undefined, undefined, RGBObj);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW) {
                            const RGBObj = {
                                r: evt.R,
                                g: evt.G,
                                b: evt.B
                            };
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, undefined, RGBObj);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            const RGBObj = {
                                r: evt.R,
                                g: evt.G,
                                b: evt.B
                            };
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature, RGBObj);
                        }
                        deviceDetailArray.push(payload);
                    }
                })
            })
            if (evt.scheduler_repeat_options.value === "Weekly") {
                if (evt.daysOfWeekValue.length === 0) {
                    weekDayValue = [new Date().getDay()];
                }
                else {
                    weekDayValue = evt.daysOfWeekValue;
                }
            }
            else {
                weekDayValue = "N/A";
            }
            evt.deviceDetailArr = deviceDetailArray;
            evt.deviceType = evt.schedulerType === "type" ? evt.deviceType.value : "N/A";
            evt.spacesSelectedValue = evt.schedulerType === "space" ? evt.spacesSelectedValue : "N/A";
            evt.deviceMacId.map((ids) => {
                newDeviceMacId.push({ label: ids.label.split(" (")[0], value: ids.value });
            });
            evt.deviceMacId = newDeviceMacId;
            let tempUTCFromDate = evt.schedule_fromDate;
            evt.schedule_fromDate = new Date(evt.schedule_fromDate).toISOString().slice(0, 10);
            evt.schedule_time = moment(evt.schedule_time).format("HH:mm:ss");
            const tempUTCDateTime = new Date(`${evt.schedule_fromDate} ${evt.schedule_time}`);
            const formatUTCDateTime = moment.utc(tempUTCDateTime).toString();
            evt.scheduleUTC = formatUTCDateTime;
            tempUTCFromDate = moment.utc(tempUTCDateTime).toString();
            evt.schedule_fromDate = new Date(tempUTCFromDate).toISOString().slice(0, 10);
            evt.scheduler_repeat_options = evt.scheduler_repeat_options.value;
            evt.repeat_in = evt.scheduler_repeat_options === "Once" ? "N/A" : evt.repeat_in;
            evt.daysOfWeekValue = weekDayValue;
            evt.end_repeat = evt.scheduler_repeat_options === "Once" ? "N/A" : evt.end_repeat;
            evt.end_repeat_occurrence = evt.end_repeat === "occurrence" ? evt.end_repeat_occurrence : "N/A";
            evt.end_repeat_date = evt.end_repeat === "after" ? evt.end_repeat_date : "N/A";
            evt.fanSpeed = showSpeed ? evt.fanSpeed : "N/A";
            evt.brightness = showBrightnessControl ? evt.brightness : "N/A";
            evt.temperature = showTemperatureControl ? evt.temperature : "N/A";
            evt.R = showRGBControl ? evt.R : "N/A";
            evt.G = showRGBControl ? evt.G : "N/A";
            evt.B = showRGBControl ? evt.B : "N/A";
            if (editSchedulerModal !== undefined) {
                evt.id = schedulerId;
                evt.last_execution_on = defaultValue.last_execution_on;
                const editSchedulerRes = await EditSchedulerAction(evt);
                if (editSchedulerRes.status === 200) {
                    onHide();
                    resetForm(defaultValue);
                    setSubmitting(false);
                    toast.success(editSchedulerRes.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    refreshComponent();

                }
            }
            else {
                const createSchedulerRes = await createMultipleDeviceSchedulerAction(evt);
                if (createSchedulerRes.status === 200) {
                    resetForm(defaultValue);
                    setSubmitting(false);
                    onHide();
                    toast.success(createSchedulerRes.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    refreshComponent();
                }
            }
        }
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                validationSchema={validations}
                onSubmit={handleSubmit}
                initialValues={defaultValue}>
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldTouched,
                    errors,
                    status,
                    submitCount,
                    resetForm,
                    setFieldValue,
                    isSubmitting,
                    isValidating
                }) => (
                    <Modal
                        show={show}
                        onHide={onHide}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered
                        onShow={() => (show ? resetForm() : false)} >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {editSchedulerModal === undefined ? "Add Scheduler" : "Edit Scheduler"}
                            </Modal.Title>
                        </Modal.Header>
                        <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body className="show-grid">
                                <Form.Row>
                                    <Form.Label as={Col} md="3" className="mt-2">Scheduler Name <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Group as={Col} md="8" className="mr-2" controlId="ruleName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Scheduler Name"
                                            name="schedulerName"
                                            value={values.schedulerName}
                                            onChange={handleChange}
                                            isInvalid={touched.schedulerName && !!errors.schedulerName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.schedulerName ? "scheduler name is a required field" : ""}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Label as={Col} md="3" className="mt-1">Scheduler Type</Form.Label>
                                    <Form.Group as={Col} md="3" className="mt-1 form-group col-md-3 d-flex align-items-center radio-button--align" controlId="schedulerType">
                                        <Form.Label className="m-0">Device Type</Form.Label>
                                        <Form.Control
                                            className="m-0"
                                            type="radio"
                                            name="schedulerType"
                                            style={{ cursor: "pointer" }}
                                            value="type"
                                            checked={values.schedulerType === "type" ? true : false}
                                            onChange={(onChangeSchedulerType) => {
                                                setFieldValue("schedulerType", onChangeSchedulerType.target.value);
                                                handleSpacesFieldOptions(onChangeSchedulerType.target.value);
                                                setFieldValue("deviceMacId", []);
                                                setFieldValue("spacesSelectedValue", []);
                                                setFieldValue("deviceType", '');
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" className="mt-1 form-group col-md-3 d-flex align-items-center radio-button--align" controlId="schedulerType">
                                        <Form.Label className="m-0">Spaces</Form.Label>
                                        <Form.Control
                                            className="m-0"
                                            type="radio"
                                            name="schedulerType"
                                            style={{ cursor: "pointer" }}
                                            value="space"
                                            checked={values.schedulerType === "space" ? true : false}
                                            onChange={(onChangeSchedulerType) => {
                                                setFieldValue("schedulerType", onChangeSchedulerType.target.value);
                                                handleSpacesFieldOptions(onChangeSchedulerType.target.value);
                                                setFieldValue("deviceMacId", []);
                                                setFieldValue("spacesSelectedValue", []);
                                            }}
                                        />

                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    {(values.schedulerType === "type") &&
                                        (<>
                                        <Form.Label as={Col} md="3" className="mt-4">Device Type <span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="deviceType">
                                                <Select
                                                value={values.deviceType}
                                                    onChange={(onChangeDeviceType) => {
                                                        setFieldValue("deviceType", onChangeDeviceType);
                                                        handleDevicesFieldOptions(onChangeDeviceType.value, setFieldValue);
                                                    }}
                                                options={schedulerDeviceTypeOptions}
                                                isInvalid={touched.deviceType && !!errors.deviceType}
                                                />
                                            <Form.Control.Feedback type="invalid">
                                                {" "}
                                                {errors.deviceType ? "device type is a required field" : ""}{" "}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </>)
                                    }
                                    {(values.schedulerType === "space") &&
                                        (<>
                                        <Form.Label as={Col} md="3" className="mt-4">Spaces <span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="spacesSelectedValue">
                                            {/* <Select
                                                    value={values.spacesSelectedValue}
                                                onChange={(onChangeSpacesSelected, action) => {
                                                    setFieldValue("spacesSelectedValue", onChangeSpacesSelected);
                                                    handleSpaceDevicesFieldOptions(onChangeSpacesSelected, setFieldValue, action, values.deviceMacId);
                                                    }}
                                                    options={spacesDropdownOptions}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            /> */}
                                            <MultiSelect
                                                name="spacesSelectedValue"
                                                className="m-input"
                                                options={spacesDropdownOptions}
                                                value={values.spacesSelectedValue}
                                                valueRenderer={spacesCustomValueRenderer}
                                                filterOptions={spacesFilterOptions}
                                                onChange={(onChangeSpacesSelected, action) => {
                                                    setFieldValue("spacesSelectedValue", onChangeSpacesSelected);
                                                    handleSpaceDevicesFieldOptions(onChangeSpacesSelected, setFieldValue);
                                                }}
                                                labelledBy="Select"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {" "}
                                                {(values.schedulerType === "space") &&
                                                    (values.spacesSelectedValue == null || values.spacesSelectedValue.length == 0) ?
                                                    "spaces is a required field" : ""}
                                                {" "}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </>)
                                    }
                                    <Form.Label as={Col} md="3" className="mt-4">Devices<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="deviceMacId">
                                        <MultiSelect
                                            name="deviceMacId"
                                            className="m-input"
                                            options={devicesDropdownOptions}
                                            value={values.deviceMacId}
                                            valueRenderer={customValueRenderer}
                                            filterOptions={filterOptions}
                                            onChange={(devicesArray) => {
                                                setFieldValue("deviceMacId", devicesArray);
                                                handleSpaceDevicesControl(devicesArray, values.schedulerType);
                                            }}
                                            labelledBy="Select"
                                            isInvalid={touched.deviceMacId && !!errors.deviceMacId}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.deviceMacId ? "devices is a required field" : ""}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Label as={Col} md="3" className="mt-4">Repeat</Form.Label>
                                    <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="scheduler_repeat_options">
                                        <Select
                                            value={values.scheduler_repeat_options}
                                            defaultValue={[{ value: "Once", label: "Once" }]}
                                            onChange={(onChangeRepeatOptions) => {
                                                setFieldValue("scheduler_repeat_options", onChangeRepeatOptions);
                                                handleRepeatDailyWeeklyFields(onChangeRepeatOptions.value, setFieldValue);
                                                setFieldValue("repeat_in", 1);
                                                setFieldValue("end_repeat_occurrence", 1);
                                            }}
                                            options={schedulerRepeatOptions}
                                            isInvalid={touched.scheduler_repeat_options && !!errors.scheduler_repeat_options}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.scheduler_repeat_options}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {showRepeatDailyWeeklyFields &&
                                        (<> <Form.Label as={Col} md="3" className="mt-4">Repeat every <span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Group as={Col} md="7" className="mr-1 mt-3" controlId="repeat_in">
                                                <Form.Control
                                                    type="number"
                                                    placeholder="1"
                                                    min={1}
                                                    max={365}
                                                    name="repeat_in"
                                                    value={values.repeat_in}
                                                    onChange={handleChange}
                                                    isInvalid={touched.repeat_in && !!errors.repeat_in}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {" "}
                                                    {errors.repeat_in}{" "}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Label as={Col} md="1" className="mt-4">
                                                {values.scheduler_repeat_options.value === "Weekly" ? "week(s)" : "day(s)"}
                                            </Form.Label>
                                        </>)}
                                    {(values.scheduler_repeat_options.value === "Once") &&
                                        (<>
                                        <Form.Label as={Col} md="3" className="mt-4">Date Time <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="schedule_date">
                                                <DatePicker
                                                    className="form-control m-input"
                                                    onChange={(onSchedulerDateChange) => {
                                                        setFieldValue("schedule_date", onSchedulerDateChange);
                                                    }}
                                                    selected={values.schedule_date}
                                                    placeholderText="MM/DD/YYYY HH:MM"
                                                    showTimeInput
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    minTime={new Date().getTime()}
                                                minDate={new Date()}
                                            />
                                            </Form.Group>
                                        </>)}
                                    {showRepeatDailyWeeklyFields &&
                                        (<>
                                        <Form.Label as={Col} md="3" className="mt-4">From Date</Form.Label>
                                        <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="schedule_fromDate">
                                                <DatePicker
                                                    className="form-control m-input"
                                                    selected={values.schedule_fromDate}
                                                    onChange={(onSchedulerFromDateChange) => {
                                                        setFieldValue("schedule_fromDate", onSchedulerFromDateChange);
                                                        setFieldValue("end_repeat_date", addDays(new Date(onSchedulerFromDateChange), 1));
                                                    }}
                                                    placeholderText="MM/DD/YYYY"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    dateFormat="MM/dd/yyyy"
                                                    minDate={new Date()}
                                            />
                                            </Form.Group>
                                        <Form.Label as={Col} md="3" className="mt-4">From Time</Form.Label>
                                        <Form.Group as={Col} md="8" className="mr-3 mt-3" controlId="schedule_time">
                                                <DatePicker
                                                    className="form-control m-input"
                                                    selected={values.schedule_time}
                                                    onChange={(onSchedulerFromTimeChange) => {
                                                        setFieldValue("schedule_time", onSchedulerFromTimeChange);
                                                    }}
                                                    placeholderText="HH:MM"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                            />
                                            </Form.Group>

                                        {showWeekDayField &&
                                            (<>
                                            <Form.Label as={Col} md="3" className="mt-3">Days</Form.Label>
                                            <Form.Group as={Col} md="7" controlId="daysOfWeekValue" className="mt-2" >
                                                    <div className="btn-group">
                                                        <label
                                                            className={
                                                                daysOfWeekFieldValue.includes(0)
                                                                    ? "btn btn-primary"
                                                                    : "btn btn-secondary"
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="sunday"
                                                                defaultChecked={
                                                                    daysOfWeekFieldValue.includes(0)
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(OnWeekDayChange) => {
                                                                    handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                                }}
                                                                className="days_of_week"
                                                                autoComplete="off"
                                                                id="checkbox1"
                                                                value="0"
                                                            />
                                                            <span className="d-none d-sm-inline">SUN</span>
                                                        </label>
                                                        <label
                                                            className={
                                                                daysOfWeekFieldValue.includes(1)
                                                                    ? "btn btn-primary"
                                                                    : "btn btn-secondary"
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="monday"
                                                                defaultChecked={
                                                                    daysOfWeekFieldValue.includes(1)
                                                                        ? true
                                                                        : false
                                                                }
                                                                className="days_of_week"
                                                                autoComplete="off"
                                                                id="checkbox2"
                                                                value="1"
                                                                onChange={(OnWeekDayChange) => {
                                                                    handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                                }}
                                                            />
                                                            <span className="d-none d-sm-inline">MON</span>
                                                        </label>
                                                        <label
                                                            className={
                                                                daysOfWeekFieldValue.includes(2)
                                                                    ? "btn btn-primary"
                                                                    : "btn btn-secondary"
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="tuesday"
                                                                defaultChecked={
                                                                    daysOfWeekFieldValue.includes(2)
                                                                        ? true
                                                                        : false
                                                                }
                                                                className="days_of_week"
                                                                autoComplete="off"
                                                                id="checkbox3"
                                                                value="2"
                                                                onChange={(OnWeekDayChange) => {
                                                                    handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                                }}
                                                            />
                                                            <span className="d-none d-sm-inline">TUE</span>
                                                        </label>
                                                        <label
                                                            className={
                                                                daysOfWeekFieldValue.includes(3)
                                                                    ? "btn btn-primary"
                                                                    : "btn btn-secondary"
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="wednesday"
                                                                defaultChecked={
                                                                    daysOfWeekFieldValue.includes(3)
                                                                        ? true
                                                                        : false
                                                                }
                                                                className="days_of_week"
                                                                autoComplete="off"
                                                                id="checkbox4"
                                                                value="3"
                                                                onChange={(OnWeekDayChange) => {
                                                                    handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                                }}

                                                        />
                                                        <span className="d-none d-sm-inline">WED</span>
                                                    </label>
                                                    <label
                                                        className={
                                                            daysOfWeekFieldValue.includes(4)
                                                                ? "btn btn-primary"
                                                                : "btn btn-secondary"
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name="thursday"
                                                            defaultChecked={
                                                                daysOfWeekFieldValue.includes(4)
                                                                    ? true
                                                                    : false
                                                            }
                                                            className="days_of_week"
                                                            autoComplete="off"
                                                            id="checkbox5"
                                                            value="4"
                                                            onChange={(OnWeekDayChange) => {
                                                                handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                            }}

                                                        />
                                                        <span className="d-none d-sm-inline">THU</span>
                                                    </label>
                                                    <label
                                                        className={
                                                            daysOfWeekFieldValue.includes(5)
                                                                ? "btn btn-primary"
                                                                : "btn btn-secondary"
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name="friday"
                                                            defaultChecked={
                                                                daysOfWeekFieldValue.includes(5)
                                                                    ? true
                                                                    : false
                                                            }
                                                            className="days_of_week"
                                                            autoComplete="off"
                                                            id="checkbox6"
                                                            value="5"
                                                            onChange={(OnWeekDayChange) => {
                                                                handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                            }}

                                                            />
                                                            <span className="d-none d-sm-inline">FRI</span>
                                                        </label>
                                                        <label
                                                            className={
                                                                daysOfWeekFieldValue.includes(6)
                                                                    ? "btn btn-primary"
                                                                    : "btn btn-secondary"
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="saturday"
                                                                defaultChecked={
                                                                    daysOfWeekFieldValue.includes(6)
                                                                        ? true
                                                                        : false
                                                                }
                                                                className="days_of_week"
                                                                autoComplete="off"
                                                                id="checkbox7"
                                                                value="6"
                                                                onChange={(OnWeekDayChange) => {
                                                                    handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                                                                }}
                                                            />
                                                            <span className="d-none d-sm-inline">SAT</span>
                                                        </label>
                                                    </div>
                                                <Form.Text className="text-muted">
                                                    Note <InfoOutlined fontSize='medium' viewBox="0 0 35 9" color='action' />Days is a required field or else default value will be submitted.
                                                </Form.Text>
                                                </Form.Group>
                                            </>)}
                                        <Form.Label as={Col} md="3" className="mt-2">End repeat</Form.Label>
                                        <Form.Group as={Col} md="1" className=" mt-2 col-md-9 form-group  d-flex align-items-center radio-button--align" controlId="end_repeat">
                                            <Form.Label as={Col} md="8" className="m-0 pl-4">Never</Form.Label>
                                            <Form.Control
                                                className="m-0"
                                                type="radio"
                                                    name="end_repeat"
                                                style={{ cursor: "pointer" }}
                                                    value="never"
                                                checked={values.end_repeat === "never" ? true : false}
                                                    onChange={(onChangeEndRepeatType) => {
                                                        setFieldValue("end_repeat", onChangeEndRepeatType.target.value);
                                                        handleDisableEndRepeatTypeFields(onChangeEndRepeatType.target.value);
                                                        setFieldValue("end_repeat_occurrence", 1);
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Label as={Col} md="3" className="mt-4"></Form.Label>
                                        <Form.Group as={Col} md="1" className="mt-3 d-flex align-items-center radio-button--align" controlId="end_repeat">
                                            <Form.Label as={Col} md="1" className="m-0 pl-4">On</Form.Label>
                                                <Form.Control
                                                type="radio"
                                                className="m-0"
                                                    name="end_repeat"
                                                style={{ cursor: "pointer" }}
                                                    value="occurrence"
                                                checked={values.end_repeat === "occurrence" ? true : false}
                                                    onChange={(onChangeEndRepeatType) => {
                                                        setFieldValue("end_repeat", onChangeEndRepeatType.target.value);
                                                        handleDisableEndRepeatTypeFields(onChangeEndRepeatType.target.value);
                                                    }}
                                                />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" className="mt-3" controlId="end_repeat_occurrence">
                                                <Form.Control
                                                    type="number"
                                                name="end_repeat_occurrence"
                                                min={1}
                                                    onChange={handleChange}
                                                    disabled={disableEndRepeatOccurrence}
                                                    placeholder={
                                                        disableEndRepeatOccurrence
                                                            ? "occurrence(s)"
                                                            : 1
                                                    }
                                                    value={values.end_repeat_occurrence}
                                                isInvalid={touched.end_repeat_occurrence && !!errors.end_repeat_occurrence}
                                                />
                                            <Form.Control.Feedback type="invalid">
                                                {" "}
                                                {errors.end_repeat_occurrence}{" "}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        <Form.Label as={Col} md="3" className="mt-4">occurrence(s)</Form.Label>
                                        <Form.Label as={Col} md="3" className="mt-3"></Form.Label>
                                        <Form.Group as={Col} md="1" className="mt-3 d-flex align-items-center radio-button--align" controlId="end_repeat">
                                            <Form.Label as={Col} md="1" className="m-0 pl-4">After</Form.Label>
                                                <Form.Control
                                                className="m-0"
                                                type="radio"
                                                    name="end_repeat"
                                                style={{ cursor: "pointer" }}
                                                    value="after"
                                                checked={values.end_repeat === "after" ? true : false}
                                                    onChange={(onChangeEndRepeatType) => {
                                                        setFieldValue("end_repeat", onChangeEndRepeatType.target.value);
                                                        handleDisableEndRepeatTypeFields(onChangeEndRepeatType.target.value);
                                                        setFieldValue("end_repeat_occurrence", 1);
                                                    }}
                                                />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" className="mt-2" controlId="end_repeat_date">
                                                <DatePicker
                                                    className="form-control m-input"
                                                    disabled={disableEndRepeatDate}
                                                selected={values.end_repeat_date}
                                                    onChange={(onSchedulerEndRepeatDateChange) => {
                                                        setFieldValue("end_repeat_date", onSchedulerEndRepeatDateChange);
                                                    }}
                                                onKeyDown={(e) => e.preventDefault()}
                                                    placeholderText="MM/DD/YYYY HH:MM"
                                                    showTimeInput
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    minTime={new Date().getTime()}
                                                    minDate={addDays(
                                                        new Date(values.schedule_fromDate),
                                                        1
                                                    )}
                                                />
                                            </Form.Group>
                                        <Form.Label as={Col} md="2" className="mt-4"></Form.Label>
                                        </>)}
                                    <Form.Label as={Col} md="3" className="mt-3">On/Off</Form.Label>
                                    <Form.Group as={Col} md="7" controlId="deviceStatus" className="mt-3">
                                        <label className="switch">
                                            <input
                                                name="deviceStatus"
                                                type="checkbox"
                                                checked={values.deviceStatus}
                                                onChange={(deviceStatusChecked) => {
                                                    setFieldValue("deviceStatus", deviceStatusChecked.target.checked);
                                                    setFieldValue("fanSpeed", deviceStatusChecked.target.checked ? Number(defaultValue.fanSpeed) : 0);
                                                    setFieldValue("brightness", deviceStatusChecked.target.checked ? Number(defaultValue.brightness) : 0);
                                                    setFieldValue("temperature", deviceStatusChecked.target.checked ? Number(defaultValue.temperature) : 0);
                                                    setFieldValue("R", deviceStatusChecked.target.checked ? Number(defaultValue.R) : -1);
                                                    setFieldValue("G", deviceStatusChecked.target.checked ? Number(defaultValue.G) : -1);
                                                    setFieldValue("B", deviceStatusChecked.target.checked ? Number(defaultValue.B) : -1);
                                                }}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </Form.Group>
                                    {showSpeed && (
                                        <>
                                            <Form.Label as={Col} md="3">Speed</Form.Label>
                                            <Form.Group as={Col} md="3" controlId="fanSpeed">
                                                <div className="speedslider mt-4">
                                                    <input
                                                        name="fanSpeed"
                                                        type="range"
                                                        value={values.fanSpeed}
                                                        min="1"
                                                        max="5"
                                                        step="1"
                                                        className="slider"
                                                        id="myRange"
                                                        onChange={(speedValue) => {
                                                            setFieldValue("fanSpeed", Number(speedValue.target.value));
                                                            setFieldValue("deviceStatus",
                                                                (Number(speedValue.target.value) > 0) ||
                                                                    (Number(speedValue.target.value) > 0) ||
                                                                    (Number(speedValue.target.value) > 0) ? true : false);
                                                        }}
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Label as={Col} md="4">{values.fanSpeed}</Form.Label>
                                        </>)}
                                    {showBrightnessControl && (<>
                                        <Form.Label as={Col} className="mt-2" md="3">Dimming : {values.brightness}%</Form.Label>
                                        <Form.Group as={Col} md="8" controlId="brightness">
                                            <Typography style={{ width: "100%" }}>
                                                <DimmingSlider
                                                    value={values.brightness}
                                                    defaultValue={values.brightness}
                                                    onChangeCommitted={(event, value) => {
                                                        setFieldValue("brightness", Number(value));
                                                        setFieldValue("deviceStatus",
                                                            (Number(value) > 0) || (Number(value) > 0) || (Number(value) > 0) ? true : false);
                                                    }}
                                                    min={1}
                                                    max={100}
                                                />
                                            </Typography>
                                        </Form.Group>
                                    </>)}
                                    {showTemperatureControl && (<>
                                        <Form.Label as={Col} className="mt-3" md="3">Tone : {values.temperature}%</Form.Label>
                                        <Form.Group as={Col} md="8" controlId="temperature">
                                            <Typography style={{ width: "100%" }}>
                                                <ToneSlider
                                                    min={1}
                                                    max={100}
                                                    defaultValue={values.temperature}
                                                    value={values.temperature}
                                                    onChangeCommitted={(event, value) => {
                                                        setFieldValue("temperature", Number(value));
                                                        setFieldValue("deviceStatus",
                                                            (Number(value) > 0) || (Number(value) > 0) || (Number(value) > 0) ? true : false);
                                                    }}
                                                />
                                            </Typography>
                                        </Form.Group>
                                    </>)}
                                    {showRGBControl && (<>
                                        <Form.Label as={Col} className="mt-3" md="3">Color Picker</Form.Label>
                                        <Form.Group as={Col} md="8" className="mt-2" controlId="rgb">
                                            <Typography style={{ width: "25%" }} component={'div'}>
                                                <SketchPicker
                                                    color={{ r: values.R, g: values.G, b: values.B }}
                                                    disableAlpha={true}
                                                    onChangeComplete={(e) => {
                                                        setFieldValue("R", e.rgb.r);
                                                        setFieldValue("G", e.rgb.g);
                                                        setFieldValue("B", e.rgb.b);
                                                        setFieldValue("deviceStatus",
                                                            (e.rgb.r > 0) || (e.rgb.g > 0) || (e.rgb.b > 0) ? true : false);
                                                    }}
                                                />
                                            </Typography>
                                        </Form.Group>
                                    </>)}
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <LoadingSubmit isLoading={isSubmitting} text="Save" />
                                <Button variant="danger" onClick={onHide}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = state => ({
    devices: state.devices,
    spaces: state.spaces
})

export default connect(mapStateToProps, {
    createMultipleDeviceSchedulerAction,
    EditSchedulerAction
})(AddEditSchedulerModal);
