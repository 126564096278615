import * as yup from "yup";

export const Validations = yup.object({
    devices: yup.array().ensure().required(),
});

export const defaultValue = {
    devices: [],
    deviceStatus: true,
    fanSpeed: 3,
    brightness: 7,
    temperature: 6,
    r: 1,
    g: 1,
    b: 1,
};