import axios from 'axios'
import apiurl from '../config/api'

export function getUsers() {
  return axios.get(apiurl.getUsers)
    .then(result => {
      return result.data
    })
}

export function getUserDetails(userId) {
  return axios.get(`${apiurl.getUserDetails}/${userId}`)
    .then(result => {
      return result.data
    })
}