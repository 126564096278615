import { SETTINGS_LOADED } from '../actions/settingsAction'

export function settingsReducer(state = null, action) {
  switch (action.type) {

    case SETTINGS_LOADED:
      return action.settings

    default:
      return state
  }
}