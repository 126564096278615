export function SetLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function GetLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function isAuthenticate() {
  return GetLocalStorage("smi-user");
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}
