import React, { useState, useEffect } from "react";
import { store } from "../../store";
import { connect } from "react-redux";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import {
    Grid,
    Box,
    Button
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import {
    getShareSpacesDeviceAction,
    updateSharedSpaceDeviceStatusAction
} from "../../actions/spacesActions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
    DEVICE_TYPE_SWITCH_4,
    DEVICE_TYPE_SWITCH_2,
    DEVICE_TYPE_SWITCH,
    DEVICE_TYPE_TEMP_SENSOR,
    DEVICE_TYPE_SOCKET,
    DEVICE_TYPE_FAN,
    DEVICE_TYPE_LIGHT_LWCT,
    DEVICE_TYPE_LIGHT_LW,
    DEVICE_TYPE_LIGHT_LRGB,
    DEVICE_TYPE_LIGHT_LRGBW,
    DEVICE_TYPE_LIGHT_LRGBWCT,
    ALL_DEVICE_OFFLINE_WARNING,
    DEVICE_OFFLINE_WARNING,
    NO_DEVICES_AVAILABLE_IN_SPACE_WARNING
} from "../../helper/constants";
import socket from "../../helper/socket";
import globalHelper from "../../helper/globalHelper";
import Loader from "../../components/Loader";
import SpaceDevicesMultipleDeviceControl from "../../components/devicesComponents/SpaceDevicesMultipleDeviceControl";
import { defaultValue, Validations } from "../spaces/SpaceDevicesMultipleDeviceControlValidation";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";

const SpacesDevicesList = ({
    shared_space_devices,
    getShareSpacesDeviceAction,
    updateSharedSpaceDeviceStatusAction
}) => {
    const [noSpaceDevices, setNoSpaceDevices] = useState([]);
    const [spaceDevicesList, setSpaceDevicesList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [showMultipleDeviceControlModal, setShowMultipleDeviceControlModal] = useState(false);
    const localUserData = localStorage.getItem('smi-user');
    const jsonLocalUserData = JSON.parse(localUserData);
    const { Id } = useParams();

    /* breadCrumb */
    const SpacesDeviceBreadCrumb = [
        {
            title: "Shared Spaces",
            link: "/spaces/spaces-shared-with-me",
            classData: "",
        },
        {
            title: "Shared Spaces Devices",
            link: `/spaces/spaces-shared-with-me/spaces-device/${Id}`,
            classData: "active_anv_link",
        }
    ];

    useEffect(() => {
        getSpacesDevicesList();
    }, []);

    const getSpacesDevicesList = async () => {
        const shareSpaceDevicesRes = await getShareSpacesDeviceAction(Id);
        if (shareSpaceDevicesRes.status === 200) {
            if (shareSpaceDevicesRes.data && shareSpaceDevicesRes.data.length) {
                shareSpaceDevicesRes.data.forEach((device, index) => {
                    if (device.spaceId === undefined) {
                        noSpaceDevices.push({ label: device.instanceId.name, value: device.deviceMacId })
                    }
                    socket.io.on(`deviceDetail${device.deviceMacId}`, (socketData) => {
                        const socketListenedData = JSON.parse(socketData);
                        changeDeviceStatus(socketListenedData);
                    });
                });
            }
            setSpaceDevicesList(shareSpaceDevicesRes.data);
        }
        else if (shareSpaceDevicesRes.status === 422) {
            toast.error(shareSpaceDevicesRes.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setIsFetching(false);
    }

    /* on/off status changes for shared space devices */
    function changeDeviceStatus(socketListenedData) {
        const response = store.getState().shared_space_devices;
        const updatedList =
            response &&
            response.map((device) => {
                if (socketListenedData.UUID === device.deviceMacId) {
                    const SocketDataObjkeys = Object.keys(socketListenedData.get_OnOff);
                    SocketDataObjkeys.forEach((key, index) => {
                        if (
                            key === DEVICE_TYPE_SWITCH_4 ||
                            DEVICE_TYPE_SWITCH_2

                        ) {
                            if (key === device.deviceMacId.split("-")[1]) {
                                device.on = socketListenedData.get_OnOff[key];
                                device.instanceId.caps.get_OnOff.params.on =
                                    socketListenedData.get_OnOff[key];
                            }
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN
                            || String(device.deviceType.name) === DEVICE_TYPE_SWITCH
                            || String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR
                            || String(device.deviceType.name) === DEVICE_TYPE_SOCKET
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.on = socketListenedData.get_OnOff.on;
                            device.instanceId.caps.get_OnOff.params.on =
                                socketListenedData.get_OnOff.on;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN) {
                            device.instanceId.caps.get_FanSpeed.params.speed =
                                socketListenedData?.get_FanSpeed?.speed;
                        }
                        if (
                            socketListenedData?.get_Brightness?.brightness &&
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_Brightness.params.brightness =
                                socketListenedData?.get_Brightness?.brightness;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorTemperature.params.temperature =
                                socketListenedData?.get_ColorTemperature?.temperature;
                        }
                        if (String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorSpectrum.params.R = socketListenedData?.get_ColorSpectrum?.R;
                            device.instanceId.caps.get_ColorSpectrum.params.G = socketListenedData?.get_ColorSpectrum?.G;
                            device.instanceId.caps.get_ColorSpectrum.params.B = socketListenedData?.get_ColorSpectrum?.B;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR) {
                            device.instanceId.caps.get_TemperatureControl.params = socketListenedData?.get_TemperatureControl;
                            device.instanceId.caps.get_HumidityControl.params = socketListenedData?.get_HumidityControl;
                        }
                    });
                    return device;
                } else {
                    return device;
                }
            });
        updateSharedSpaceDeviceStatusAction(updatedList);
    }

    /* handle multiple device control modal open */
    const handleMulitpleDeviceControlModalOpen = () => {
        let offlineDevicesArr = [];
        spaceDevicesList.map((item) => {
            if (item?.instanceId?.online) {
                setShowMultipleDeviceControlModal(true);
            }
            else if (!item?.instanceId?.online) {
                offlineDevicesArr.push(item);

            }
        });
        if (spaceDevicesList.length === 0) {
            toast.error(NO_DEVICES_AVAILABLE_IN_SPACE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (offlineDevicesArr.length === (spaceDevicesList.length)) {
            toast.error(ALL_DEVICE_OFFLINE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    /* handle multiple device control modal close */
    const handleMulitpleDeviceControlModalClose = () => {
        setShowMultipleDeviceControlModal(false);
    };


    const sendSocketEventData = async (deviceData, deviceExtraPayload, deviceTemperature, deviceRGB) => {
        if (deviceData.instanceId.online) {
            const payload = await globalHelper.getDeviceSocketPayload(deviceData, deviceExtraPayload, deviceTemperature, deviceRGB);
            socket.io.emit("deviceDetail-v1", payload);
        }
        else {
            toast.error(DEVICE_OFFLINE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    /* custom toolbar for devices */
    const DevicesCustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    item
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    <>
                        <div className="mr-2">
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        fontSize: 12, marginTop: "4px",
                                        marginRight: "15px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        handleMulitpleDeviceControlModalOpen();
                                    }}
                                >
                                    Multiple Device Control
                                </Button>
                            </>
                        </div>
                    </>
                </Grid>
            </GridToolbarContainer>
        );
    };

    const columns = [
        {
            field: "deviceType",
            headerName: "Image",
            type: "singleSelect",
            editable: false,
            sortable: false,
            width: 100,
            valueOptions: ["light", "switch", "fan"],
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <img
                            alt={params.value.icon}
                            style={{ height: "50px", width: "50px" }}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${params.value.icon}`}
                        />
                    </div>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 120,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return params.row.instanceId.name;
            },
        },
        {
            field: "instanceId",
            headerName: "Online",
            type: "boolean",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        justifyContent="center"
                    >
                        <p
                            style={{
                                fontSize: 10,
                                marginTop: "13px",
                                marginRight: "10px",
                            }}
                        >
                            {params.value.online ? "online" : "offline"}
                        </p>
                        <img
                            style={{ width: "20px", height: "13px" }}
                            src={`${process.env.PUBLIC_URL}images/${params.value.online ? "cloudact.png" : "cloud.png"
                                }`}
                        />
                    </Grid>
                );
            },
        },
        {
            field: "on",
            headerName: "On",
            type: "boolean",
            width: 100,
            editable: false,
            align: "center",
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.deviceType.name === DEVICE_TYPE_TEMP_SENSOR) {
                    return (
                        <Grid
                            container
                            justifyContent="center"
                            style={{ alignItems: "center", cursor: "pointer" }}
                        >
                            <img src="images/temperature-icon.png" width="20px" height="27px" className="pr-2" />
                            {(params.row.instanceId?.caps?.get_TemperatureControl?.params?.currentTemperature).toFixed(2)}</Grid>
                    );
                }
                else {
                    const imgPath =
                        params.row.instanceId.online &&
                            params.row.instanceId.caps.get_OnOff.params.on
                            ? "images/onbtn.png"
                            : "images/offbtn.png";
                    return (
                        <Grid
                            container
                            justifyContent="center"
                            style={{ alignItems: "center", cursor: "pointer" }}
                        >
                            <img
                                alt={params.row._id}
                                onClick={() => {
                                    sendSocketEventData(params.row);
                                }}
                                style={{ width: "35px", height: "35px" }}
                                src={process.env.PUBLIC_URL + imgPath}
                            />
                        </Grid>
                    );
                }
            },
        },
        {
            field: "spaceId",
            headerName: "Space",
            width: 120,
            align: "center",
            headerAlign: "center",
            editable: false,
            renderCell: (params) => {
                let spaceName =
                    params.value && params.value.name ? params.value.name : "-";
                return spaceName;
            },
        },
    ];

    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            {showMultipleDeviceControlModal && (
                <SpaceDevicesMultipleDeviceControl
                    show={showMultipleDeviceControlModal}
                    onHide={handleMulitpleDeviceControlModalClose}
                    sharedSpaceDevicesCondition={true}
                    defaultValue={defaultValue}
                    validations={Validations}
                />)}
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                    <div className="m-portlet m-portlet--mobile">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                    <h2>Devices</h2>
                                    <CustomBreadCrumb breadCrumData={SpacesDeviceBreadCrumb} />
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="row spacesListDetails">
                                <Grid xs={12}
                                    style={{
                                        display: "flex",
                                        flexDirection: "initial",
                                        flexWrap: "nowrap",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        margin: "0 0 10px"
                                    }}>
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={process.env.PUBLIC_URL + "images/groupicn.png"}
                                    />
                                    <h3 style={{ padding: "0 3vh", margin: "0" }}>{jsonLocalUserData?.user?.name ? `${jsonLocalUserData?.user?.name}_group` : ""}</h3>
                                </Grid>
                                <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>
                                    <DataGrid
                                        getRowId={(row) => row._id}
                                        disableSelectionOnClick={true}
                                        rows={shared_space_devices}
                                        rowHeight={40}
                                        columns={columns}
                                        components={{
                                            Toolbar: DevicesCustomToolbar,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    shared_space_devices: state.shared_space_devices,
});

export default connect(mapStateToProps, {
    getShareSpacesDeviceAction,
    updateSharedSpaceDeviceStatusAction
})(SpacesDevicesList);
