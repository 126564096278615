import * as UserService from '../services/UserService'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const CLIENT_USER_LOGGED_IN = 'CLIENT_USER_LOGGED_IN'
export const CLIENT_USER_SEND_OTP = 'CLIENT_USER_SEND_OTP'
export const CLIENT_USER_VERIFY_OTP = 'CLIENT_USER_VERIFY_OTP'
export const CLIENT_USER_RESET_PWD = 'CLIENT_USER_RESET_PWD'
export const GET_COUNTRY_CODES = 'GET_COUNTRY_CODES'

export function loginAction(credentials) {
  return (dispatch) => {
    return UserService.login(credentials)
      .then((result) => {
        dispatch(userLoggedInAction(result))
        return result
      })
  }
}

export function logoutAction() {
  return (dispatch) => {
    return UserService.logout()
      .then(() => {
        dispatch(userLoggedOutAction())
        return true
      })
  }
}

export function userLoggedOutAction() {
  return {
    type: USER_LOGGED_OUT,
  }
}

export function forgotPassAction(credentials) {
  return (dispatch) => {
    return UserService.forgotPassword(credentials)
      .then((result) => {
        dispatch(forgotPasswordAction(result))
        return result
      })
  }
}

export function forgotPasswordAction(forgotPass) {
  return {
    type: FORGOT_PASSWORD,
    forgotPass
  }
}

export function clientLoginAction(credentials) {
  return (dispatch) => {
    return UserService.clientLogin(credentials)
      .then((result) => {
        dispatch(userLoggedInAction(result))
        return result
      })
  }
}

export const clientSendOtpAction = (credentials) => {
  return (dispatch) => {
    return UserService.clientSendOtp(credentials).then((authRes) => {
      dispatch({
        type: CLIENT_USER_SEND_OTP,
        authRes,
      });
      return authRes;
    });
  }
};

export const clientVerifyOtpAction = (credentials) => {
  return (dispatch) => {
    return UserService.clientVerifyOtp(credentials).then((authRes) => {
      dispatch({
        type: CLIENT_USER_VERIFY_OTP,
        authRes,
      });
      return authRes;
    });
  }
};

export const clientResetPasswordAction = (credentials) => {
  return (dispatch) => {
    return UserService.clientResetPassword(credentials).then((authRes) => {
      dispatch({
        type: CLIENT_USER_RESET_PWD,
        authRes,
      });
      return authRes;
    });
  }
};

export function userLoggedInAction(user) {
  return {
    type: USER_LOGGED_IN,
    user
  }
}

export const getCountryCodesAction = () => {
  return (dispatch) => {
    return UserService.getCountryCodesList().then((authRes) => {
      dispatch({
        type: GET_COUNTRY_CODES,
        authRes: authRes.data.data || [],
      });
      return authRes;
    });
  };
};