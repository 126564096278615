import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { createSpaceAction } from "../../actions/spacesActions";
import { toast } from "react-toastify";
import {
  SPACES_UPDATED_SUCCESSFULLY,
  SPACE_NAME_WARNING
} from "../../helper/constants";

const EditSpaces = ({ spaces, setShowEditSpaces, editParams, createSpaceAction,
  refreshComponent }) => {
  const fileInput = useRef(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [spaceName, setSpaceName] = useState("");
  const [spaceImage, setSpaceImage] = useState({});

  useEffect(() => {
    if (spaceName === "") {
      setSpaceName(editParams.name)
    }
    if (Object.entries(spaceImage).length === 0) {
      setSpaceImage(editParams.image);
    }
  }, []);
  const handleEditSpaceChange = (e) => {
    setSpaceName(e.target.value);
  };

  const onEditImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSpaceImage(event.target.files[0])
      setImageSelected(true)

    }
  }

  const getSpaceImgSrc = (spaceImageSrc) => {
    if (spaces.length == 0) {
      return;
    } else {
      if (typeof spaceImageSrc === "string") {
        var resultArr = spaceImageSrc.split("/");
        var result = resultArr[1] + "/" + resultArr[2] + "/" + resultArr[3]
        return result;
      } else {
        return URL.createObjectURL(spaceImageSrc);
      }
    }
  }

  const handleEdit = () => {
    if (spaceName === '') {
      toast.error(SPACE_NAME_WARNING, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      const data = new FormData();
      data.append("name", spaceName);
      data.append("spaceId", editParams._id);
      data.append("image", spaceImage);
      spaceName && spaceImage && createSpaceAction(data).then((item) => {
        if (item.status === 200) {
          toast.success(SPACES_UPDATED_SUCCESSFULLY, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowEditSpaces(false)
          refreshComponent();
        }
      });
    }
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_PUBLIC_URL}public/images/space_default/missing_images.jpg`
  }

  return (
    <>
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="row">
                  <div className="m-portlet__head-title col-lg-9">
                    <div
                      xs={12}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <h2>Edit Spaces</h2>
                    </div>
                  </div>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        Back
                      </Tooltip>
                    }
                  >
                  <ChevronRightIcon
                    onClick={() => {
                      setShowEditSpaces(false)
                    }}
                      style={{ fontSize: 40, position: "absolute", right: 40, cursor: "pointer" }}
                  />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  onChange={handleEditSpaceChange}
                  name="name"
                  label="Space Name"
                  defaultValue={editParams.name}
                />
                <div
                  style={{
                    margin: "2vh auto",
                    border: "2px dashed gray",
                    padding: "1vh",
                    borderRadius: "5px",
                  }}
                >
                  {imageSelected ? (
                    <img onClick={() => { fileInput.current.click() }} style={{ height: "320px", width: "320px" }} src={getSpaceImgSrc(spaceImage)} />
                  ) : (
                    <img
                      onClick={() => {
                        fileInput.current.click();
                      }}
                        onError={addDefaultSrc}
                      style={{ height: "320px", width: "320px" }}
                        src={`${process.env.REACT_APP_PUBLIC_URL}${editParams.image}`}
                    />
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={onEditImageChange}
                  ref={fileInput}
                  style={{ display: "none" }}
                />
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  color="primary"
                  style={{ margin: "3vh auto" }}
                >
                  Update
                </Button>
              </Grid>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  spaces: state.spaces,
});


export default connect(mapStateToProps, {
  createSpaceAction
})(EditSpaces);

