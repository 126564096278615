import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker"; // Added new import
import { createRulesAction } from "../../actions/rulesActions";
import { rulesDeviceType, DEVICE_TYPE_TEMP_SENSOR, DEVICE_TYPE_FAN, WEEK_DAY_SELECTION_WARNING } from "../../helper/constants";
import LoadingSubmit from "../LoadingSubmit";
import globalHelper from "../../helper/globalHelper";
import moment from "moment"; // Added new import
import InfoOutlined from '@material-ui/icons/InfoOutlined'; // Added new import

const AddEditRulesModal = ({ devices, createRulesAction, show, onHide, validations, defaultValue, refreshComponent, editParamsRowData, paramsId }) => {
  const [devicesDropdownOptions, setDevicesDropdownOptions] = useState([]);
  const [temperatureSensorDropdownOptions, setTemperatureSensorDropdownOptions] = useState([]);
  const [showFanSpeed, setShowFanSpeed] = useState(false);
  const [daysOfWeekFieldValue, setDaysOfWeekFieldValue] = useState(editParamsRowData === undefined ? [] : defaultValue.days);

  useEffect(() => {
    devices.forEach((ownerDevices) => {
      devicesDropdownOptions.push({
        label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
        value: ownerDevices.deviceMacId
      });
      if (ownerDevices.deviceType.name === DEVICE_TYPE_TEMP_SENSOR) {
        if (paramsId !== undefined && editParamsRowData === undefined && ownerDevices._id === paramsId) {
          defaultValue.temperatureSensorId = [{
            label: ownerDevices.instanceId.name,
            value: ownerDevices.deviceMacId
          }];
        }
            temperatureSensorDropdownOptions.push({
              label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
              value: ownerDevices.deviceMacId
            });
      }
      if (defaultValue.fanSpeed) {
        setShowFanSpeed(true);
      }
    })
    {
      (editParamsRowData !== undefined) &&
        (defaultValue.devices.length > 0) &&
        defaultValue.devices.forEach((e) => {
          let newDevicesLabel = devices.find(o => o.deviceMacId == e.value)
          e.label = newDevicesLabel?.instanceId?.name
        })
    }
    {
      (editParamsRowData !== undefined) &&
        (defaultValue.temperatureSensorId.length > 0) &&
        defaultValue.temperatureSensorId.forEach((e) => {
          let newTemperatureSensorLabel = devices.find(o => o.deviceMacId == e.value)
          e.label = newTemperatureSensorLabel?.instanceId?.name
        })
    } 
  }, []);

  const handleFanSpeedShowHide = (selectedArray) => {
    let isFanArr = [];
    if (selectedArray && selectedArray.length > 0) {
      selectedArray.forEach((userSelectedDevices) => {
        let obj = devices.find(o => o.deviceMacId === userSelectedDevices.value && o.deviceType.name === DEVICE_TYPE_FAN);
        if (obj) {
          isFanArr.push(obj);
        }
      })
      if (isFanArr.length > 0) {
        setShowFanSpeed(true);
      }
      else if (isFanArr.length <= 0) {
        setShowFanSpeed(false);
      }
    }
    else {
      setShowFanSpeed(false);
    }
  };

  const sourceSensorCustomStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: '80px',
      overflowY: state.hasValue ? 'scroll' : 'hidden',
    }),
  }

  const devicesCustomStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: '80px',
      overflowY: state.hasValue ? 'scroll' : 'hidden',
    }),
  }

  const sourceSensorFilterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
  };

  const sourceSensorCustomValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => label.split(" (")[0]).toString()
      : "Select...";
  };

  const targetDevicesFilterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
  };

  const targetDevicesCustomValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => label.split(" (")[0]).toString()
      : "Select...";
  };

  /* handle submit add/edit rules */
  const handleSubmit = async (evt, { setSubmitting, setErrors, resetForm }) => {
    let deviceDetailArr = [];
    setSubmitting(true);
    evt.devices.forEach(async (selectedDevices) => {
      devices.forEach(async (findDeviceDetail) => {
        if (findDeviceDetail.deviceMacId === selectedDevices.value) {
          findDeviceDetail.pageName = "Rules";
          let sendFanSpeedValue = undefined;
          if (showFanSpeed) {
            sendFanSpeedValue = Number(evt.fanSpeed);
          }
          findDeviceDetail.requestedDeviceStatus = evt.deviceStatus;
          const payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, sendFanSpeedValue);
          deviceDetailArr.push(payload);
        }
      })
    });

    const createRuleReqPayloadObj = {
      temperatureSensorId: evt.temperatureSensorId,
      ruleName: evt.ruleName,
      rule: evt.rule,
      set_rule_from: evt.set_rule_from,
      set_rule_to: evt.set_rule_to,
      ruleDelay: Math.floor(evt.ruleDelay),
      last_execution_on: 'N/A',
      devices: evt.devices,
      sendNotification: evt.sendNotification,
      deviceStatus: evt.deviceStatus,
      deviceDetail: deviceDetailArr,
      isEnabled: true
      //from_time: moment(evt.from_time).utc(),
      //to_time: moment(evt.to_time).utc(),
      //days: evt.days
    };

    if (evt.from_time !== '' && evt.from_time !== undefined) {
      createRuleReqPayloadObj.from_time = moment(evt.from_time).utc();
    }

    if (evt.to_time !== '' && evt.to_time !== undefined) {
      createRuleReqPayloadObj.to_time = moment(evt.to_time).utc();
    }

    if (evt.days.length > 0) {
      createRuleReqPayloadObj.days = evt.days;
    }

    if (showFanSpeed) {
      createRuleReqPayloadObj.fanSpeed = Number(evt.fanSpeed);
    }

    const editRuleReqPayloadObj = {
      _id: defaultValue._id,
      temperatureSensorId: evt.temperatureSensorId,    
      ruleName: evt.ruleName,
      rule: evt.rule,
      set_rule_from: evt.set_rule_from,
      set_rule_to: evt.set_rule_to,
      ruleDelay: Math.floor(evt.ruleDelay),
      last_execution_on: defaultValue.last_execution_on,
      devices: evt.devices,
      sendNotification: evt.sendNotification,
      deviceStatus: evt.deviceStatus,
      deviceDetail: deviceDetailArr,
      isEnabled: true
      //from_time: moment(evt.from_time).utc(),
      //to_time: moment(evt.to_time).utc(),
      //days: evt.days
    };

    if (evt.from_time !== '' && evt.from_time !== undefined) {
      editRuleReqPayloadObj.from_time = moment(evt.from_time).utc();
    }

    if (evt.to_time !== '' && evt.to_time !== undefined) {
      editRuleReqPayloadObj.to_time = moment(evt.to_time).utc();
    }

    if (evt.days.length > 0) {
      editRuleReqPayloadObj.days = evt.days;
    }

    if (showFanSpeed) {
      editRuleReqPayloadObj.fanSpeed = Number(evt.fanSpeed);
    }

    const addEditReqPayload = editParamsRowData === undefined ? createRuleReqPayloadObj : editRuleReqPayloadObj;

    // Validate from and to time if either one or both available
    let proceedFurther = true;
    if ((evt.from_time !== '' && evt.from_time !== undefined) || (evt.to_time !== '' && evt.to_time !== undefined)) {
      if (evt.from_time && !evt.to_time) {
        toast.error('to time is a required field', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        proceedFurther = false;
      }
      else if (evt.to_time && !evt.from_time) {
        toast.error('from time is a required field', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        proceedFurther = false;
      } else if (evt.to_time && evt.from_time && (Date.parse(evt.from_time) >= Date.parse(evt.to_time))) {
        toast.error('to time should be greater than from time', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        proceedFurther = false;
      }
    }

    // Validate sensor range for decimal values
    if (evt.set_rule_from % 1 !== 0 || evt.set_rule_to % 1 !== 0) {
      toast.error('only whole numbers are supported for sensor range', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      proceedFurther = false;
    }
    
    if (proceedFurther) {
      const addEditRulesRes = await createRulesAction(addEditReqPayload);
      if (addEditRulesRes.status === 200) {
        toast.success(addEditRulesRes.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onHide();
        setSubmitting(false);
        refreshComponent();
      }
      else if (addEditRulesRes.status === 422) {
        toast.error(addEditRulesRes.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleWeekDayField = (weekDayValue, weekDayChecked, setFieldValue) => {
    // * Insert week day name in array (state)
    if (
        (weekDayValue == 0 ||
            weekDayValue == 1 ||
            weekDayValue == 2 ||
            weekDayValue == 3 ||
            weekDayValue == 4 ||
            weekDayValue == 5 ||
            weekDayValue == 6) &&
        weekDayChecked == true
    ) {
        setDaysOfWeekFieldValue([
            ...daysOfWeekFieldValue,
            parseInt(weekDayValue),
        ])
        setFieldValue("days", [
            ...daysOfWeekFieldValue,
            parseInt(weekDayValue),
        ]);
    }
    // * Delete week day name from array (state)
    if (
        (weekDayValue == 0 ||
            weekDayValue == 1 ||
            weekDayValue == 2 ||
            weekDayValue == 3 ||
            weekDayValue == 4 ||
            weekDayValue == 5 ||
            weekDayValue == 6) &&
        weekDayChecked == false &&
        daysOfWeekFieldValue.includes(parseInt(weekDayValue))
    ) {
        const oneIndexValue = daysOfWeekFieldValue.indexOf(
            parseInt(weekDayValue)
        );
        daysOfWeekFieldValue.splice(oneIndexValue, 1);
        setDaysOfWeekFieldValue(daysOfWeekFieldValue);
        setFieldValue("days", daysOfWeekFieldValue);
        if (daysOfWeekFieldValue.length == 0) {
          toast.error(
            WEEK_DAY_SELECTION_WARNING, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          );
        }
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validations}
        onSubmit={handleSubmit}
        initialValues={defaultValue}>
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          setFieldTouched,
          errors,
          resetForm,
          setFieldValue,
          isSubmitting
        }) => (
          <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            onShow={() => (show ? resetForm() : false)} >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {editParamsRowData === undefined ? "Add Rule" : "Edit Rule"}
              </Modal.Title>
            </Modal.Header>
            <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body className="show-grid">
                <Form.Row>
                  <Form.Label as={Col} md="3" className="mt-2">Rule Name <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Group as={Col} md="7" className="mr-2" controlId="ruleName">
                    <Form.Control
                      type="text"
                      placeholder="Rule Name"
                      name="ruleName"
                      value={values.ruleName}
                      onChange={handleChange}
                      isInvalid={touched.ruleName && !!errors.ruleName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.ruleName ? "rule name is a required field" : ""}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2">Source Sensor <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Group as={Col} md="7" className="mr-2" controlId="temperatureSensorId">
                    <MultiSelect
                      name="temperatureSensorId"
                      className="m-input"
                      options={temperatureSensorDropdownOptions}
                      value={values.temperatureSensorId}
                      valueRenderer={sourceSensorCustomValueRenderer}
                      filterOptions={sourceSensorFilterOptions}
                      onChange={(temperatureSensorArr) => {
                        setFieldValue("temperatureSensorId", temperatureSensorArr);
                      }}
                      labelledBy="Select"
                      isInvalid={touched.temperatureSensorId && !!errors.temperatureSensorId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.temperatureSensorId ? "source sensor is a required field" : ""}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2">Rule Type</Form.Label>
                  <Form.Group as={Col} md="7" className="mr-2" controlId="rule">
                    <Select
                      onChange={(deviceTypeValue) => {
                        setFieldValue("rule", deviceTypeValue.value);
                      }}
                      defaultValue={defaultValue.rule === "" ? [
                        { value: "Temperature Sensor", label: "Temperature Sensor" }
                      ] : [{ value: values.rule, label: values.rule }]}
                      options={rulesDeviceType}
                      isInvalid={touched.rule && !!errors.rule}
                    />
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2">Time range</Form.Label>
                  <Form.Group as={Col} md="3" controlId="from_time">
                    <DatePicker
                      name="from_time"
                      className="form-control m-input"
                      selected={values.from_time}
                      onChange={(fromTimeValue) => {
                          setFieldValue("from_time", fromTimeValue);
                      }}
                      placeholderText="From Time"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      //onKeyDown={(e) => e.preventDefault()}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" className="mr-1" controlId="to_time">
                    <DatePicker
                      name="to_time"
                      className="form-control m-input"
                      selected={values.to_time}
                      onChange={(toTimeValue) => {
                          setFieldValue("to_time", toTimeValue);
                      }}
                      placeholderText="To Time"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      //onKeyDown={(e) => e.preventDefault()}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </Form.Group>
                  <Form.Label as={Col} md="3"></Form.Label>
                  <Form.Group as={Col} md="7" controlId="days">
                    <Form.Text className="text-muted">
                      Note <InfoOutlined fontSize='medium' viewBox="0 0 35 9" color='action' />Default: 24 hours. Select Time range to restrict execution.
                    </Form.Text>
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-3">Day range</Form.Label>
                  <Form.Group as={Col} md="7" controlId="days" className="mt-2" >
                    <div className="btn-group">
                      <label
                        className={
                          daysOfWeekFieldValue.includes(0)
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                        }
                      >
                        <input
                          type="checkbox"
                          name="sunday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(0)
                              ? true
                              : false
                          }
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox1"
                          value="0"
                        />
                        <span className="d-none d-sm-inline">SUN</span>
                      </label>
                      <label
                        className={
                          daysOfWeekFieldValue.includes(1)
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                      >
                        <input
                          type="checkbox"
                          name="monday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(1)
                              ? true
                              : false
                          }
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox2"
                          value="1"
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                        />
                        <span className="d-none d-sm-inline">MON</span>
                      </label>
                      <label
                        className={
                          daysOfWeekFieldValue.includes(2)
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                      >
                        <input
                          type="checkbox"
                          name="tuesday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(2)
                              ? true
                              : false
                          }
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox3"
                          value="2"
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                        />
                        <span className="d-none d-sm-inline">TUE</span>
                      </label>
                      <label
                        className={
                          daysOfWeekFieldValue.includes(3)
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                      >
                        <input
                          type="checkbox"
                          name="wednesday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(3)
                              ? true
                              : false
                          }
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox4"
                          value="3"
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                        />
                        <span className="d-none d-sm-inline">WED</span>
                      </label>
                      <label
                        className={
                          daysOfWeekFieldValue.includes(4)
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                      >
                        <input
                          type="checkbox"
                          name="thursday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(4)
                              ? true
                              : false
                          }
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox5"
                          value="4"
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                        />
                        <span className="d-none d-sm-inline">THU</span>
                      </label>
                      <label
                        className={
                          daysOfWeekFieldValue.includes(5)
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                      >
                        <input
                          type="checkbox"
                          name="friday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(5)
                              ? true
                              : false
                          }
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox6"
                          value="5"
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                        />
                        <span className="d-none d-sm-inline">FRI</span>
                      </label>
                      <label
                        className={
                          daysOfWeekFieldValue.includes(6)
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                          }
                      >
                        <input
                          type="checkbox"
                          name="saturday"
                          defaultChecked={
                            daysOfWeekFieldValue.includes(6)
                              ? true
                              : false
                          }
                          className="days_of_week"
                          autoComplete="off"
                          id="checkbox7"
                          value="6"
                          onChange={(OnWeekDayChange) => {
                            handleWeekDayField(OnWeekDayChange.target.value, OnWeekDayChange.target.checked, setFieldValue);
                          }}
                        />
                        <span className="d-none d-sm-inline">SAT</span>
                      </label>
                    </div>
                    <Form.Text className="text-muted">
                      Note <InfoOutlined fontSize='medium' viewBox="0 0 35 9" color='action' />Default: Everyday. Select Day(s) to restrict execution.
                    </Form.Text>
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2">Sensor range <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Group as={Col} md="3" controlId="set_rule_from">
                    <Form.Control
                      type="number"
                      placeholder="1"
                      min={1}
                      max={99}
                      name="set_rule_from"
                      value={values.set_rule_from}
                      onChange={handleChange}
                      isInvalid={touched.set_rule_from && !!errors.set_rule_from}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.set_rule_from}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label as={Col} md="1" className="mt-2" >to</Form.Label>
                  <Form.Group as={Col} md="3" className="mr-1" controlId="set_rule_to">
                    <Form.Control
                      type="number"
                      placeholder="99"
                      min={1}
                      max={99}
                      name="set_rule_to"
                      value={values.set_rule_to}
                      onChange={handleChange}
                      isInvalid={touched.set_rule_to && !!errors.set_rule_to}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.set_rule_to}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label as={Col} md="3"></Form.Label>
                  <Form.Group as={Col} md="7" controlId="set_rule_range">
                    <Form.Text className="text-muted">
                      Note <InfoOutlined fontSize='medium' viewBox="0 0 35 9" color='action' />Only whole numbers are supported.
                    </Form.Text>
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2">Execution Delay <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Group as={Col} md="3" controlId="ruleDelay" >
                    <Form.Control
                      type="number"
                      placeholder="1 - 60"
                      min={0}
                      max={60}
                      name="ruleDelay"
                      value={values.ruleDelay}
                      onChange={handleChange}
                      isInvalid={touched.ruleDelay && !!errors.ruleDelay}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.ruleDelay}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="mt-2" style={{ marginLeft: "8px", marginRight: "250px" }}>min(s)</Form.Label>
                  <Form.Label as={Col} md="3" className="mt-2">Target Devices {!(values.sendNotification) && <span style={{ color: 'red' }}>*</span>}</Form.Label>
                  <Form.Group as={Col} md="7" controlId="devices">
                    <MultiSelect
                      name="devices"
                      className="m-input"
                      options={devicesDropdownOptions}
                      value={values.devices}
                      valueRenderer={targetDevicesCustomValueRenderer}
                      filterOptions={targetDevicesFilterOptions}
                      onChange={(devicesArray) => {
                        setFieldValue("devices", devicesArray);
                        handleFanSpeedShowHide(devicesArray);
                      }}
                      labelledBy="Select"
                      isInvalid={touched.devices && !!errors.devices}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.devices ? "target devices is a required field" : ""}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2"></Form.Label>
                  <Form.Group
                    as={Col} md="6" controlId="sendNotification" className="mt-2 ml-4"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Send Notification to Mobile App"
                      value={values.sendNotification}
                      checked={values.sendNotification}
                      onChange={(e) =>
                        setFieldValue("sendNotification", e.target.checked)
                      }
                    />
                  </Form.Group>
                  {values.devices.length > 0 && (
                    <>
                      <Form.Label as={Col} md="3" className="mt-2">On/Off</Form.Label>
                      <Form.Group as={Col} md="7" controlId="deviceStatus">
                        <label className="switch">
                          <input
                            name="deviceStatus"
                            type="checkbox"
                            checked={values.deviceStatus}
                            onChange={(status) => {
                              setFieldValue("deviceStatus", status.target.checked);
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                        <Form.Control.Feedback type="invalid">
                          {" "}
                          {errors.deviceStatus}{" "}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                  
                  {showFanSpeed && (
                    <>
                      <Form.Label as={Col} md="3">Speed</Form.Label>
                      <Form.Group as={Col} md="3" controlId="deviceStatus">
                        <div className="speedslider mt-4">
                          <input
                            name="speed"
                            type="range"
                            value={values.fanSpeed}
                            min="1"
                            max="5"
                            step="1"
                            className="slider"
                            id="myRange"
                            onChange={(speedValue) => {
                              setFieldValue("fanSpeed", speedValue.target.value);
                            }}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {" "}
                          {errors.fanSpeed}{" "}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Label as={Col} md="4">{values.fanSpeed}</Form.Label>
                    </>)}

                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <LoadingSubmit isLoading={isSubmitting} text="Save" />
                <Button variant="danger" onClick={onHide}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => ({
  devices: state.devices
})

export default connect(mapStateToProps, {
  createRulesAction
})(AddEditRulesModal);
