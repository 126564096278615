//export const DOMAIN = '192.168.4.125'; //for QC
//export const DOMAIN = '192.168.1.91:3005'; // Jatin PC
//export const DOMAIN = '192.168.2.187:3001'; // Hitesh PC
//export const DOMAIN = 'phpdemo02.kcspl.in:3000'; // Live
export const DOMAIN = "http://localhost:3005/v1";
//export const DOMAIN = "api.smartindia.us"; // AWS Staging
//export const DOMAIN = 'api.smartindia.app'; // AWS Live

//export const BASE_URL = 'http://' + DOMAIN + '/api/';
//export const SOCKET_CONNECTION_URL = "http://phpdemo02.kcspl.in:3000/";

export const BASE_URL = "https://" + DOMAIN + "/api/";

//  export const SOCKET_CONNECTION_URL = "http://localhost:3005/"; // AWS Staging
// export const SOCKET_CONNECTION_URL = "https://api.smartindia.us/"; // AWS Live

export const WebUrlTermsAndService =
  "https://smartindia.app/terms/?ismobile=true";
export const WebUrlPrivacy = "https://smartindia.app/privacy/?ismobile=true";
export const WebUrlAbout = "https://smartindia.app/about/?ismobile=true";
export const WebUrlContactSupport = "https://smartindia.app/support/";

export const SUPPORT_SMARTINDIA_EMAIL = "support@smartindia.app";

//export const SOCKET_CONNECTION_URL="";

///----------------- Webservice  API Name --------------------------------------

//******************** |  ESP Device Client API |*************************

export const API_ESP_DOMAIN = "http://192.168.4.1/";
// http:// ip:2067/deviceinfo
// EC:FA:BC:2C:A9:66/ Switch 4
//192.168.43.209
//ecfabc2ca966
//http://172.16.0.209:2067/update
// 192.168.43.99/ fan ip
// EC:FA:BC:2C:AC:6E/ fan mac
//http://192.168.43.99:2067/update

export const API_ESP_SCAN = API_ESP_DOMAIN + "scan";
export const API_ESP_WIFI_SET = API_ESP_DOMAIN + "wifisave?";
export const API_ESP_DEVICE_INFO = API_ESP_DOMAIN + "deviceinfo";
export const API_ESP_DEVICE_STATE = API_ESP_DOMAIN + "state";
export const API_ESP_WIFI_CLOSE = API_ESP_DOMAIN + "close?";

export const API_AUTO_DEVICE_INFO = ":2067/deviceinfo";
export const API_AUTO_WIFI_CLOSE = ":2067/close?";
//http://192.168.4.1/wifisave?s=KCS_WING-A_JIO&p=36987412500

//******************** |  AWS Credential |*************************

export const AWS_REGION = "ap-south-1";
export const AWS_BUCKETID = "app.smartindia.staging";
export const AWS_ACCESSKEY = "AKIAYFKGGHLGXG7QKFTV";
export const AWS_SECRETKEY = "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";

//******************** |  Authentication 9 |*************************

export const API_POST_LOGIN = BASE_URL + "auth/login";
export const API_POST_REGISTER = BASE_URL + "auth/register";
export const API_POST_SEND_OTP = BASE_URL + "auth/send-otp";
export const API_POST_RESET_PASSWORD = BASE_URL + "auth/reset-password";
export const API_POST_VERIFY_OTP = BASE_URL + "auth/verify-otp";
export const API_POST_LOGOUT = BASE_URL + "auth/logout";
export const API_POST_REFRESH_TOKEN = BASE_URL + "auth/token";

export const API_POST_CHANGE_PASSWORD = BASE_URL + "users/change-password";
export const API_POST_EDIT_PROFILE = BASE_URL + "users/edit-profile";

//*********************** |  Group 5 |*******************************

export const API_POST_GROUP_UPDATE_GROUP_INFO =
  BASE_URL + "group/update-group-info";
export const API_POST_GROUP_ADD_CONTACT = BASE_URL + "group/add-contact";
export const API_POST_GROUP_DELETE_CONTACT = BASE_URL + "group/delete-contact";
export const API_POST_GROUP_LEAVE = BASE_URL + "group/leave";
export const API_POST_CONTACT_GET_LIST = BASE_URL + "contact/get-list";

//*********************** |  Device 9 |******************************

export const API_POST_DEVICE_TYPE = BASE_URL + "device-type";
export const API_POST_SAVE_DEVICE = BASE_URL + "device/save-device-list";
export const API_POST_REMOVE_DEVICE = BASE_URL + "device/remove-devices";
export const API_POST_EDIT_DEVICE = BASE_URL + "device/edit-device";
export const API_GET_SPACE_EDIT_DEVICES = BASE_URL + "device/list?";
export const API_POST_GET_DEVICE_INFO = BASE_URL + "device/get-device-info";
export const API_GET_USER_DEVICE = BASE_URL + "device/get-user-devices";
export const API_POST_ADD_DEVICE_SPACE =
  BASE_URL + "device/add-devices-to-space";
export const API_POST_REBOOT_DEVICE = BASE_URL + "device/reboot-device";

//*********************** |  Space 6 |*******************************

export const API_GET_SPACE_LIST = BASE_URL + "space/get-space-list";
export const API_GET_SPACE_DEVICES = BASE_URL + "space/get-space-devices";
export const API_GET_USER_SPACE = BASE_URL + "space/get-user-space";
export const API_POST_CREATE_SPACES = BASE_URL + "space/create";
export const API_POST_REMOVE_SPACES = BASE_URL + "space/remove-space";
export const API_POST_SPACE_REMOVE_DEVICE = BASE_URL + "space/remove-device";

//*********************** |  Notification 2 |*******************************

export const API_GET_NOTIFICATION = BASE_URL + "notifications";
export const API_POST_REMOVE_NOTIFICATION = BASE_URL + "notifications";

//*********************** |  Mix 3 |*******************************

export const API_GET_MANUFACTURER = BASE_URL + "manufacturer";
export const API_GET_COMMON = BASE_URL + "pages/get-common-pages/";
export const API_GET_CONFIGURATION = BASE_URL + "default/configuration";

///------------------- Webservice  API Name FINISH ---------------------

export const FONT_NAME = "SF-Pro";
export const SECURITY_KEY = "oVxdIw7SIhmiJvSwrg64nw==";

export const WS_STATUSCODE_SUCCESS = 200;
export const WS_STATUSCODE_USERBLOCKED = -2;
export const WS_STATUSCODE_SESSION_EXPIRE = -3;
export const WS_STATUSCODE_USERNAME_PASSWORD_INVALID = -1;
export const WS_STATUSCODE_FAIL_ERROR = 0;

export const WS_STATUSCODE_BAD_REQUEST_400 = 400;
export const WS_STATUSCODE_UNAUTHORIZED_401 = 401;
export const WS_STATUSCODE_FORBIDDEN_403 = 403;
export const WS_STATUSCODE_NOT_FOUND_404 = 404;
export const WS_STATUSCODE_UNPROCESSABLE_422 = 422;
export const WS_STATUSCODE_INTERNAL_SERVER_ERROR_500 = 500;

///WS MethodType

export const HTTP_POST = "post";
export const HTTP_GET = "get";
export const HTTP_DELETE = "delete";
export const HTTP_PATCH = "patch";

///Common Response parameter
export const ApiStatus = "status";
export const ApiData = "data";
export const ApiAccessPoints = "Access_Points";

export const ApiMessage = "message";
export const ApiSuccess = "success";
export const ApiFail = "fail";
export const ApiTotalPages = "TotalPages";

///webservice  keys
export const WS_EMAIL = "email";
export const WS_MOBILE_NO = "mobileNo";
export const WS_MANUFACTURE_ID = "manufactureId";
export const WS_DEVICES = "Devices";
export const WS_CONTACTNAME = "contactName";
export const WS_REFRESH_TOKEN = "refresh_token";

export const WS_SOCIAL_ID = "socialId";
export const WS_SOCIAL_TYE = "socialType";

export const WS_PASSWORD = "password";
export const WS_NAME = "name";
export const WS_ADMIN = "admin";
export const WS_DEVICE_TYPE = "mobileDeviceType";
export const WS_DEVICE_TOKEN = "mobileDeviceToken";
export const WS_ISREGISTER = "isRegister";
export const WS_OTP = "otp";
export const WS_ISSOCIAL = "isSocial";
export const WS_DEVICEID = "deviceId";
export const WS_DEVICETOKEN = "DeviceToken";
export const WS_DEVICETYPE_CAP = "DeviceType";

export const WS_PROFILEPHOTO = "profilePhoto";
export const WS_USER = "user";
export const WS_USERID = "userId";
export const WS_ID = "id";
export const WS_TWITTER_ID = "twitterId";

export const WS_APPLE_ID = "appleId";

export const WS_LOGINTYPE = "LoginType";
export const WS_FBID = "fbId";
export const WS_GOOGLEID = "googleId";

//webservice keys for Reset Password
export const WS_OLDPASSWORD = "oldPassword";
export const WS_NEWPASSWORD = "newPassword";

//WS SaveDevice

export const WS_DEVICEMACID = "deviceMacId";
export const WS_DEVICEMACIDS = "deviceMacIds";
export const WS_DEVICEMAC = "deviceMac";

export const WS_DEVICEIPADDRESS = "deviceIpAddress";
export const WS_DEVICENAME = "deviceName";
export const WS_FIRMWAREID = "firmwareId";
export const WS_DEVICETYPE = "deviceType";
export const WS_FIRMWARE_ID = "firmware_id";

//WS CONFIGURATION Res Param
export const WS_TIMEOUT = "timeout";
export const WS_ESPTIMEOUT = "espTimeout";
export const WS_ESPCLOSETIMEOUT = "espCloseTimeout";
export const WS_PRIVACY = "privacy";
export const WS_ABOUTUS = "aboutus";
export const WS_CONTACTSUPPORT = "contactSupport";
export const WS_TERMS = "terms";
export const WS_FAQ = "faq";

//webservice  keys for Group
export const WS_GROUP_ID = "groupId";

//WS SaveDevice
export const WS_SPACEID = "spaceId";
export const WS_IMAGE = "image";

//WS Profile Edit

export const WS_PROFILE_PHOTO = "profilePhoto";
export const WS_NOTIFICATIONSTATUS = "notificationStatus";
export const WS_THEMENAME = "themeName";

///preference keys
export const PREF_IS_LOGIN = "isLogin";
export const PREF_IS_SOCIAL = "isSocial";
export const PREF_SOCIAL_TYPE = "socialType";

export const PREF_DISPLAY_NAME = "displayName";
export const PREF_EMAIL = "email";
export const PREF_MOBILENO = "MobileNo";
export const PREF_USER_TOKEN = "userToken";
export const PREF_AUTH_TOKEN = "authToken";
export const PREF_ACCESS_TOKEN = "accessToken";
export const PREF_REFRESH_TOKEN = "refreshToken";
export const PREF_USER_ID = "userId";
export const PREF_USER_DETAIL = "userDetail";
export const PREF_MOBILE_NUMBER = "Mobile Number";
export const PREF_FORGOT_MOBILE = "forgot_mobile";
export const PREF_FORGOT_OTP = "forgot_otp";
export const PREF_NOTIFICATION_TOKEN = "notificationToken";
export const PREF_CONFIG_TIMEOUT = "configurationTimeout";
export const PREF_CONFIG_TIMEOUT_60 = "configurationTimeout60";
export const PREF_CONFIG_TIMEOUT_120 = "configurationTimeout120";
export const PREF_CONFIG_OTP_120 = "Otp120";

export const PREF_PRIVACYURL = "privacyUrl";
export const PREF_ABOUTAPPURL = "aboutAppUrl";
export const PREF_CONTACTSUPPORTURL = "contactSupportUrl";
export const PREF_TERMSURL = "termsUrl";
export const PREF_A_FAQ_URL = "androidFaqUrl";
export const PREF_I_FAQ_URL = "iosFaqUrl";

///*********** | Intent Activity Result keys |***************

export const RESULT_HOME = "homeResult";
export const RESULT_GROUP = "groupResult";
export const RESULT_SPACES = "spacesResult";
export const RESULT_PROFILE = "profileResult";

export const FROM_NOTIFICATION = "formNotification";

export const RESULT_CONTACT_INFO = "contactInfoResult";
export const RESULT_PERMISSION_DENIED = "permissionDenied";
export const RESULT_ADD_SPACES = "addSpacesResult";
export const RESULT_ALL_DEVICES = "allDevicesResult";

//Socket connection event name
export const EVENT_DEVICE_DETAIL = "deviceDetail";
export const EVENT_HOME_SCREEN = "homeScreen";
export const EVENT_SPACES = "spaces";
export const EVENT_SPACES_DETAIL = "spaceDetail";
export const ALL_DEVICES_DETAIL = "allDeviceDetails";
export const HOME_SCREEN_ON_OFF = "homeScreenOnOff";
export const SPACE_DEVICE_DETAIL_ON_OFF = "spaceDetailOnOff";
export const DEVICE_DETAIL_ON_OFF = "deviceDetailOnOff";
export const SOCKET_CONNECTION_TIMEOUT = 1000;
export const SOCKET_CONNECTION_700 = 1500;
//Device type for socket connection
export const DEVICE_TYPE_ON_OFF = "OnOff";
export const DEVICE_TYPE_FAN = "Fan";
export const DEVICE_TYPE_SWITCH_4 = "Switch4";
export const DEVICE_TYPE_SWITCH_2 = "Switch2";
export const DEVICE_TYPE_SWITCH = "Switch";
export const DEVICE_TYPE_LIGHT = "Light";
export const DEVICE_TYPE_TEMP_SENSOR = "TemperatureSensor";
export const DEVICE_TYPE_SOCKET = "Socket";

export const DEVICE_TYPE_LIGHT_LW = "LW";
export const DEVICE_TYPE_LIGHT_LWCT = "LWCT";
export const DEVICE_TYPE_LIGHT_LRGB = "LRGB";
export const DEVICE_TYPE_LIGHT_LRGBW = "LRGBW";
export const DEVICE_TYPE_LIGHT_LRGBWCT = "LRGBWCT";
export const DEVICE_TYPE_LIGHT_LRGB_W_W = "LRGB_W_W";
export const DEVICE_TYPE_LIGHT_LRGBW_W = "LRGBW_W";
export const DEVICE_TYPE_LIGHT_LRGB_WCT = "LRGB_WCT";

//l for Led (Light)
export const DEVICE_TYPE_LIGHT_L = "led";
export const DEVICE_TYPE_LIGHT_L1 = "led1";
export const DEVICE_TYPE_LIGHT_L2 = "led2";
export const DEVICE_TYPE_LIGHT_L3 = "led3";

export const SOCIAL_FB_TYPE = "fb";
export const SOCIAL_GOOGLE_TYPE = "gl";
export const SOCIAL_TWITTER_TYPE = "tw";
export const SOCIAL_APPLE_TYPE = "ap";

export const SOCIAL_TYPE_FACEBOOK = "facebook";
export const SOCIAL_TYPE_GOOGLE = "google";
export const SOCIAL_TYPE_TWITTER = "twitter";
export const SOCIAL_TYPE_LINKEDIN = "linkedin";
export const SOCIAL_TYPE_APPLE = "apple";

// Time out Param
export const TIME_30 = 30;
export const TIME_45 = 45;
export const TIME_60 = 60;
export const TIME_90 = 90;
export const TIME_120 = 120;

// Analytics Screen Screen Names
export const ADD_DEVICES_TO_SPACE_SCREEN = "Add Devices to Spaces Screen";
export const ABOUT_US_SCREEN = "About Us";
export const ADD_SPACES = "Add Spaces Screen";
export const SPACE_DETAIL_SCREEN = "Space detail screen";
export const AUTO_SCAN_SCREEN = "Auto scan screen";
export const CHOOSE_NETWORK = "Choose a network Screen";
export const CHANGE_PASSWORD_SCREEN = "Change Password Screen";
export const CONTACT_SUPPORT_SCREEN = "Contact Support Screen";
export const REGISTER_USER_SCREEN = "Register User Screen";
export const DEVICE_DETAIL_SCREEN = "Device Details Screen";
export const ADD_DEVICE_SCREEN = "Add Device Screen";
export const EDIT_DEVICE_SCREEN = "Edit Device Screen";
export const EDIT_PROFILE_SCREEN = "Edit Profile Screen";
export const FORGET_PASSWORD_MOBILE_NUMBER = "Enter Mobile Number Screen";
export const ENTER_WIFI_PASSWORD = "Enter Wifi Password Screen";
export const FORGOT_PASSWORD = "Forgot Password Screen";
export const OWNED_GROUP_SCREEN = "Owned Group Detail Screen";
export const SHARED_GROUP_SCREEN = "Shared Group Detail Screen";
export const DASHBOARD_SCREEN = "Dashboard Screen";
export const HOME_SCREEN = "Home Screen";
export const INTRO_SCREEN = "Intro Screen";
export const LOGIN_SCREEN = "Login Screen";
export const MANUAL_SCAN_SCREEN = "Manual Scan Screen";
export const NOTIFICATION_LIST_SCREEN = "Notification List Screen";
export const PRIVACY_POLICY_SCREEN = "Privacy Policy Screen";
export const PROFILE_SCREEN = "Profile Screen";
export const RESET_PASSWORD_SCREEN = "Reset Password Screen";
export const SPACES_SCREEN = "Spaces Screen";
export const TERMS_OF_SERVICES_SCREEN = "Terms of Services Screen";
export const VERIFY_MOBILE_NUMBER_SCREEN = "Verify Mobile Number Screen";
export const DEVICES_SHARED_SUCCESSFULLY = "Device shared successfully";
export const DEVICE_EDIT_UPDATE_WARNING = "Please update device name";
export const DEVICES_GROUP_UPDATE_WARNING = "Please update group name";
export const GROUP_DETAIL_UPDATED_SUCCESSFULLY = "Group detail updated successfully";
export const WEEK_DAY_SELECTION_WARNING = "Please select atleast one week day";
export const SCHEDULER_DELETED_SUCCESSFULLY = "Scheduler deleted successfully";
export const DELETE_SCHEDULER_WARNING = "Are you sure you want to delete this scheduler(s)?";
export const SPACES_CREATED_SUCCESSFULLY = "Spaces created successfully";
export const SPACES_UPDATED_SUCCESSFULLY = "Spaces updated successfully";
export const DELETE_SPACES_WARNING = "Are you sure you want to delete this space(s)?";
export const UPDATE_SPACE_WARNING = "Please update space";
export const DELETE_DEVICES_FROM_SPACES_WARNING = "Are you sure you want to delete device(s) from this space?";
export const DELETE_SPACE_WARNING = "Are you sure you want to delete this space?";
export const DELETE_RULES_WARNING = "Are you sure you want to delete this rule(s)?";
export const RESTART_DEVICE_WARNING = "Are you sure you want to restart this device?";
export const FIRMWARE_SETTING_UPDATED_SUCCESSFULLY = "Firmware settings updated successfully";
export const ADD_SCHEDULER_TIME_WARNING = "From Time should be greater than current time";
export const SPACE_SHARED_SUCCESSFULLY = "Space shared successfully";
export const dateTimeErrorMessage =
  "Date Time should be greater than current date time";
export const deviceMovedMessage = "Device moved successfully";
export const deviceDeletedSuccessfully = "Device(s) deleted successfully";
export const deviceMoveToSpaceErrorMess = "Please select atleast one space";
export const deviceOffline = "Device is offline";
export const SPACE_REVOCATION_SUCCESSFULLY = "Space Revoked Successfully";
export const RULE_DEVICE_TYPE_DEFAULT_VALUE = "Temperature Sensor";
export const RULES_DELETED_SUCCESSFULLY = "Rule(s) deleted successfully";
export const DEVICE_OFFLINE_WARNING = "Device is offline";
export const ALL_DEVICE_OFFLINE_WARNING = "All device(s) are Offline";
export const SPACE_NAME_WARNING = "Please select space name";
export const SPACE_IMAGE_WARNING = "Please select space image";
export const DEVICES_ALREADY_ADDED_IN_SPACE_WARNING = "All device(s) are already added in space";
export const SELECT_DEVICES_WARNING = "Please select device(s)";
export const NO_DEVICES_AVAILABLE_IN_SPACE_WARNING = "No device(s) are available in this space";
export const FIRMWARE_SETTINGS_LOADING_INFO = "Updating firmware, please wait a moment";
export const INVALID_MOBILE_NUMBER_WARNING = "Invalid Mobile Number";
export const COUNTRY_CODE_WARNING = "Please select valid country code";
export const USER_ACCOUNT_DELETED_SUCCESSFULLY = "Your account has been successfully deleted";

// Added new label
export const SWITCH_OFF_DELAY_LABEL_DEFAULT = "Switch off delay";
export const SWITCH_OFF_DELAY_LABEL_1 = "Delay";
export const SWITCH_OFF_DELAY_LABEL_2 = "(in seconds)";
export const SWITCH_OFF_DELAY_TEMPERATURE_SENSOR_LABEL_1 = "Reporting interval";
export const SWITCH_OFF_DELAY_TEMPERATURE_SENSOR_LABEL_2 = "(in milli seconds)";

// Fan speed array of objects
export const fanSpeedData = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

// add/edit scheduler device-type drop-down
export const schedulerDeviceTypeOptions = [
  // { value: "Light", label: "Light" },
  { value: "Fan", label: "Fan" },
  { value: "Switch", label: "Switch" },
  { value: "Switch4", label: "Switch4" },
  { value: "Switch2", label: "Switch2" },
  // { value: "Bed", label: "Bed" },
  { value: "LW", label: "LW" },
  { value: "LWCT", label: "LWCT" },
  { value: "LRGB", label: "LRGB" },
  { value: "LRGBW", label: "LRGBW" },
  { value: "LRGBWCT", label: "LRGBWCT" },
  { value: "TemperatureSensor", label: "TemperatureSensor" },
  { value: "Socket", label: "Socket" },
  // { value: "LRGB_W_W", label: "LRGB_W_W" },
  // { value: "LRGBW_W", label: "LRGBW_W" },
  // { value: "LRGB_WCT", label: "LRGB_WCT" },
];

// add/edit scheduler repeat options drop-down
export const schedulerRepeatOptions = [
  { value: "Once", label: "Once" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
];

// add rules device-type drop-down
export const rulesDeviceType = [
  { value: "Temperature Sensor", label: "Temperature Sensor" },
  { value: "Humidity Sensor", label: "Humidity Sensor" },
];

// sensor based on type : chart drop-down options
export const sensorLogTypeDropDownOptions = [
  { label: "Based On Temperature", value: "Temperature" },
  { label: "Based On Humidity", value: "Humidity", }
]


// sensor logs warning -- Dashboard.jsx
export const sensorLogs = {
  sensorNotSelected: "Please Select Sensor",
  fromDateNotSelected: "From Date filter can not be empty",
  toDateNotSelected: "To Date filter can not be empty",
  invalidDateFilterSelection: "From Date and To Date can't be same",
  dateGreaterThanCurrentDate: "From Date or To Date should not be greater than current date",
  eitherFromOrToTimeNotSelected: "From time or to time can not be empty",
  toTimeGreaterThanFromTime: "To time should be greater than from time",
  noHistoricalDataFound: "No historical data found"
};

// country code drop-down default value
export const countryCodeDefaultValue = {
  label: "India (+91)",
  value: "+91"
};