import _ from "lodash";
import { globalActionTypes as globalActionType } from "../actions/globalAction";
import {
  DEVICE_TYPE_SWITCH_4,
  DEVICE_TYPE_SWITCH_2,
  DEVICE_TYPE_SWITCH,
  DEVICE_TYPE_LIGHT,
  DEVICE_TYPE_FAN,
  DEVICE_TYPE_LIGHT_LWCT,
  DEVICE_TYPE_LIGHT_LW,
  DEVICE_TYPE_LIGHT_LRGB,
  DEVICE_TYPE_LIGHT_LRGBW,
  DEVICE_TYPE_LIGHT_LRGBWCT,
  DEVICE_TYPE_TEMP_SENSOR,
  DEVICE_TYPE_SOCKET
} from "./constants";

class globalHelper {
  store = null;

  /**
   * Take store instance
   */
  setStore = (store) => {
    this.store = store;
  };

  /**
   * Show toast message
   * type: success, error, warning, info
   */
  message = (message = "", type = "success") => {
    if (this.store) {
      this.store.dispatch({
        type: globalActionType.SHOW_SNACKBAR,
        payload: {
          open: true,
          type,
          message,
        },
      });
    }
  };

  /**
   * Set server side errors into formik form
   */
  setServerErrors = (result, setErrors) => {
    if (!result.status && result.data.error) {
      const err = _.mapValues(result.data.error, (element) => {
        return element.message;
      });
      setErrors(err);
    }
  };

  /**
   * Device socket emit event payload
   */
  getDeviceSocketPayload = async (deviceData, deviceExtraPayload, deviceTemperature, deviceRGB) => {
    const deviceMacId = deviceData?.deviceMacId;
    const ledId = deviceData?.deviceMacId.includes("-led") ? deviceData?.deviceMacId.split("-led")[1] : "";
    let deviceStatus;
    if (deviceData?.pageName === "Rules") {
      deviceStatus = deviceData?.requestedDeviceStatus;
    }
    else {
      deviceStatus = (deviceExtraPayload || deviceTemperature || deviceRGB) ? true : deviceData?.instanceId?.caps.get_OnOff.params.on ? false : true;
    }
    const deviceType = deviceData?.deviceType?.name;
    if (deviceType === DEVICE_TYPE_FAN) {
      const fanSpeed = deviceStatus === false ? 0 : deviceExtraPayload ? deviceExtraPayload : deviceData.instanceId.caps.get_FanSpeed.params.speed;
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        fanSpeed: fanSpeed,
        switchId: 0
      }
    }
    else if (deviceType === DEVICE_TYPE_LIGHT_LW) {
      const brightness = deviceStatus === false ? 0 : deviceExtraPayload ? deviceExtraPayload : deviceData.instanceId.caps.get_Brightness.params.brightness ? deviceData.instanceId.caps.get_Brightness.params.brightness : 7;
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        brightness: brightness,
        id: ledId,
      }
    }
    else if (deviceType === DEVICE_TYPE_LIGHT_LWCT) {
      const brightness = deviceStatus === false ? 0 : deviceExtraPayload ? deviceExtraPayload : deviceData.instanceId.caps.get_Brightness.params.brightness ? deviceData.instanceId.caps.get_Brightness.params.brightness : 7;
      const temperature = deviceStatus === false ? 0 : deviceTemperature ? deviceTemperature : deviceData.instanceId.caps.get_ColorTemperature.params.temperature ? deviceData.instanceId.caps.get_ColorTemperature.params.temperature : 4;
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        brightness: brightness,
        temperature: temperature,
        id: ledId,
      }
    }
    else if (deviceType === DEVICE_TYPE_LIGHT_LRGB) {
      const R = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.r : deviceData.instanceId.caps.get_ColorSpectrum.params.R ? deviceData.instanceId.caps.get_ColorSpectrum.params.R : 1;
      const G = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.g : deviceData.instanceId.caps.get_ColorSpectrum.params.G ? deviceData.instanceId.caps.get_ColorSpectrum.params.G : 1;
      const B = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.b : deviceData.instanceId.caps.get_ColorSpectrum.params.B ? deviceData.instanceId.caps.get_ColorSpectrum.params.B : 1;
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        R: R,
        G: G,
        B: B,
        id: ledId,
      }
    }
    else if (deviceType === DEVICE_TYPE_LIGHT_LRGBW) {
      const brightness = deviceStatus === false ? 0 : deviceExtraPayload ? deviceExtraPayload : deviceData.instanceId.caps.get_Brightness.params.brightness ? deviceData.instanceId.caps.get_Brightness.params.brightness : 7;
      const R = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.r : deviceData.instanceId.caps.get_ColorSpectrum.params.R ? deviceData.instanceId.caps.get_ColorSpectrum.params.R : 1;
      const G = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.g : deviceData.instanceId.caps.get_ColorSpectrum.params.G ? deviceData.instanceId.caps.get_ColorSpectrum.params.G : 1;
      const B = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.b : deviceData.instanceId.caps.get_ColorSpectrum.params.B ? deviceData.instanceId.caps.get_ColorSpectrum.params.B : 1;
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        brightness: brightness,
        R: R,
        G: G,
        B: B,
        id: ledId,
      }
    }
    else if (deviceType === DEVICE_TYPE_LIGHT_LRGBWCT) {
      const brightness = deviceStatus === false ? 0 : deviceExtraPayload ? deviceExtraPayload : deviceData.instanceId.caps.get_Brightness.params.brightness ? deviceData.instanceId.caps.get_Brightness.params.brightness : 7;
      const temperature = deviceStatus === false ? 0 : deviceTemperature ? deviceTemperature : deviceData.instanceId.caps.get_ColorTemperature.params.temperature ? deviceData.instanceId.caps.get_ColorTemperature.params.temperature : 4;
      const R = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.r : deviceData.instanceId.caps.get_ColorSpectrum.params.R ? deviceData.instanceId.caps.get_ColorSpectrum.params.R : 1;
      const G = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.g : deviceData.instanceId.caps.get_ColorSpectrum.params.G ? deviceData.instanceId.caps.get_ColorSpectrum.params.G : 1;
      const B = deviceStatus === false ? -1 : deviceRGB ? deviceRGB.b : deviceData.instanceId.caps.get_ColorSpectrum.params.B ? deviceData.instanceId.caps.get_ColorSpectrum.params.B : 1;
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        brightness: brightness,
        temperature: temperature,
        R: R,
        G: G,
        B: B,
        id: ledId,
      }
    }
    else if (deviceType === DEVICE_TYPE_SWITCH || deviceType === DEVICE_TYPE_TEMP_SENSOR || deviceType === DEVICE_TYPE_SOCKET) {
      return {
        deviceMacId: deviceMacId,
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        id: ledId,
        switchId: ""
      }
    }
    else if (deviceType === DEVICE_TYPE_SWITCH_4 || deviceType === DEVICE_TYPE_SWITCH_2) {
      return {
        deviceMacId: deviceMacId.split("-sw")[0],
        deviceStatus: deviceStatus,
        deviceType: deviceType,
        id: ledId,
        switchId: Number(deviceMacId.split("-sw")[1])
      }
    }
  };
}

export default new globalHelper();
