import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { 
    DEVICE_TYPE_TEMP_SENSOR, 
    SWITCH_OFF_DELAY_LABEL_1,
    SWITCH_OFF_DELAY_LABEL_2,
    SWITCH_OFF_DELAY_LABEL_DEFAULT,
    SWITCH_OFF_DELAY_TEMPERATURE_SENSOR_LABEL_1,
    SWITCH_OFF_DELAY_TEMPERATURE_SENSOR_LABEL_2,
} from "../../helper/constants";

const DevicesFirmwareSettingsUpdateModal = ({ 
    showFirmwareSettingsModal, 
    closeFirmwareSettingsModal,
    showSwitchOffDelayField, 
    showActivateMemoryField,
    firmwareSettingsData, 
    handleFirmwareSettingsOnChange,
    handleFirmwareSettingsSubmit, 
    switchOffDelayFieldLabel,
    showSubmitButtonLoader,
    disableSubmitBtn
}) => {
    return (
        <>
            <Modal
                show={showFirmwareSettingsModal}
                onHide={closeFirmwareSettingsModal}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Firmware Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleFirmwareSettingsSubmit}
                    >
                        {showActivateMemoryField === true && (
                            <div id="deviceOnOff" className="form-group m-form__group row">
                                <label className="col-lg-4 col-form-label">
                                    Activate memory:
                                </label>
                                <div className="col-lg-6">
                                    <label className="switch">
                                        <input
                                            name="activate_memory"
                                            type="checkbox"
                                            checked={firmwareSettingsData.activate_memory}
                                            onChange={handleFirmwareSettingsOnChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {showSwitchOffDelayField === true && (
                            <div className="form-group m-form__group row">
                                <label className="col-lg-4 col-form-label">
                                    {switchOffDelayFieldLabel === DEVICE_TYPE_TEMP_SENSOR && (
                                        <>
                                            {SWITCH_OFF_DELAY_TEMPERATURE_SENSOR_LABEL_1}
                                            <br />
                                            <small>{SWITCH_OFF_DELAY_TEMPERATURE_SENSOR_LABEL_2}</small>
                                        </>
                                    )}
                                    {switchOffDelayFieldLabel === 'switch' && (
                                        <>
                                            {SWITCH_OFF_DELAY_LABEL_1}
                                            &nbsp;<small>{SWITCH_OFF_DELAY_LABEL_2}</small>
                                        </>
                                    )}
                                    {switchOffDelayFieldLabel === 'all' && (SWITCH_OFF_DELAY_LABEL_DEFAULT)}:
                                </label>
                                <div className="col-lg-6">
                                    <input
                                        name="switch_off_delay"
                                        type="number"
                                        /* maxLength={5}
                                        max={65536} */
                                        min={1}
                                        className="form-control m-input"
                                        value={firmwareSettingsData.switch_off_delay ? firmwareSettingsData.switch_off_delay : ""}
                                        onChange={handleFirmwareSettingsOnChange}
                                        required
                                    />
                                </div>
                            </div>
                        )}
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className={`btn btn-primary ${showSubmitButtonLoader
                                    ? "m-loader m-loader--right m-loader--light"
                                    : ""
                                    }`}
                                disabled={disableSubmitBtn}
                            >
                                Save Settings
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    closeFirmwareSettingsModal();
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DevicesFirmwareSettingsUpdateModal;