import {Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const DimmingSlider = withStyles({
    root: {
      color: '#3880ff',
      height: 2,
      padding: '15px 0',
    },
    thumb: {
      height: 28,
      width: 28,
      backgroundColor: '#d3d3d3',
      marginTop: -14,
      marginLeft: -14,
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 12px)',
      top: -22,
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
    track: {
      height: 5,
      borderRadius: "10px",
    },
    rail: {
      height: 5,
      borderRadius: "10px",
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    }
  })(Slider);

  const ToneSlider = withStyles({
    root: {
      color: '#3880ff',
      height: 2,
      padding: '15px 0',
    },
    thumb: {
      height: 28,
      width: 28,
      backgroundColor: '#d3d3d3',
      marginTop: -5,
      marginLeft: -5,
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 12px)',
      top: -22,
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
    track: {
      height: 15,
      borderRadius: "10px",
      backgroundImage: "linear-gradient(90deg, rgba(255,140,0,1) 0%, rgba(255,255,255,1) 50%, rgba(13,162,255,1) 100%)",
      opacity: 0
    },
    rail: {
      height: 15,
      borderRadius: "10px",
      opacity: 1,
      backgroundImage: "linear-gradient(90deg, rgba(255,140,0,1) 0%, rgba(255,255,255,1) 50%, rgba(13,162,255,1) 100%)"
    }
  })(Slider);

  const SpeedSlider = withStyles({
    root: {
      color: '#3880ff',
      height: 2,
      padding: '15px 0',
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#d3d3d3',
      marginTop: -10,
      marginLeft: -10,
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 12px)',
      top: -22,
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
    track: {
      height: 5,
      borderRadius: "10px",
    },
    rail: {
      height: 5,
      borderRadius: "10px",
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    mark: {
      backgroundColor: '#bfbfbf',
      height: 14,
      width: 2,
      marginTop: -5,
    },
    markActive: {
      opacity: 1,
      width: 2,
      height: 14,
      backgroundColor: 'currentColor',
    },
  })(Slider);

  export {DimmingSlider, SpeedSlider, ToneSlider};
