import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from "react-router-dom";
import {
    Grid,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeSharp";

const CustomBreadCrumb = ({ breadCrumData }) => {
    return (
        <>

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                item
            >
                <Breadcrumbs separator="/" aria-label="breadcrumb">
                    <NavLink to="/devices" className="home_link">
                        <HomeIcon />
                    </NavLink>
                    {breadCrumData.map((items, breadCrumIndex) => {
                        return (
                            <NavLink key={breadCrumIndex} to={items.link} className={`${items.classData ? "active_nav_link" : "home_link"}`}>{items.title}</NavLink>
                        )
                    })}

                </Breadcrumbs>
            </Grid>
        </>
    )
}

export default CustomBreadCrumb;