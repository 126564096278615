import { GROUP_UPDATED } from "../actions/groupAction";

export function groupReducer(state = null, action) {
  switch (action.type) {
    case GROUP_UPDATED:
      return action.group;

    default:
      return state;
  }
}
