import * as yup from "yup";
import { countryCodeDefaultValue } from "../../helper/constants";

export const Validations = yup.object({
    mobile_no: yup.string().required("mobile no is a required field").nullable(),
    countryPrefix: yup.string().required("country code is a required field")
});

export const defaultValue = {
    mobile_no: '',
    countryPrefix: countryCodeDefaultValue
}