import React, { useEffect, useRef } from "react";
import Chart from 'chart.js/auto'

const LineChart = ({ xAxis, yAxis , timeRange}) => {

    const canvasRef = useRef(null);
    useEffect(() => {
        const myChart = new Chart(canvasRef.current.getContext("2d"), {
            type: 'bar',
            data: {
                labels: xAxis,
                datasets: [{
                    label:  timeRange.length? `Temp/Time : ${timeRange} Hrs/Day`:'Temp/Time : 24 Hrs/Day',
                    data: yAxis,
                    backgroundColor: ['rgba(88,103,221,0.5)'],
                    borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 10
                          },
                    },
                }
            }
        });
        return () => {
            myChart.destroy()
        }
    }, [xAxis, yAxis]);

    return (
        <>
            <div>
                <canvas ref={canvasRef} />
            </div>
        </>
    )
}

export default LineChart;