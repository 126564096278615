import BaseLayout from "./containers/layout/BaseLayout";
import UserContainer from "./containers/user/list/UserList";
import TokenContainer from "./containers/token/list/TokenList";
import TokenAddContainer from "./containers/token/add/TokenAdd";
import DeviceContainer from "./containers/device/list/DeviceList";
import DeviceTypeContainer from "./containers/deviceType/list/DeviceTypeList";
import DeviceTypeAddContainer from "./containers/deviceType/add/DeviceTypeAdd";
import DeviceWhitelistContainer from "./containers/deviceWhitelist/list/DeviceList";
import DeviceWhitelistAddContainer from "./containers/deviceWhitelist/add/DeviceAdd";
import DeviceManufacturerContainer from "./containers/deviceManufacturer/index";
import CertLogsContainer from "./containers/certLogs/CertLogsList";
import VersionInfoContainer from "./containers/versionInfo/VersionInfoList";
import ChangePasswordContainer from "./containers/changePassword/NewChangePassword";
import DevicesListContainer from "./containers/devices/DeviceList";
import RulesListContainer from "./containers/rules/RulesList";
import SpacesListContainer from "./containers/spaces/SpacesList";
import CommandContainer from "./containers/commandCenter/list/CommandList";
import SchedulerList from "./containers/scheduler/SchedulerList";
import ScheduleTimerLogs from "./containers/scheduler/ScheduleTimerLogs";
import SharedSpacesUser from "./containers/spaces/SharedSpacesUser";
import SpacesSharedWithMe from "./containers/spaces/SpacesSharedWithMe";
import SpacesDevicesList from "./containers/devices/SpacesDevicesList";
import Dashboard from "./containers/dashboard/Dashboard";

const routes = [
  { path: "/", name: "Home", component: BaseLayout },
  {
    path: "/device-whitelist",
    excat: true,
    icon: "flaticon-settings",
    name: "Device Whitelist",
    component: DeviceWhitelistContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/device-whitelist/add",
    excat: true,
    name: "Add Device To Whitelist",
    component: DeviceWhitelistAddContainer,
    isSidenav: false,
    isAdmin: true,
  },
  {
    path: "/device-type",
    excat: true,
    icon: "flaticon-list-2",
    name: "Device Type",
    component: DeviceTypeContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/device-type/add",
    excat: true,
    name: "Add Device Type",
    component: DeviceTypeAddContainer,
    isSidenav: false,
    isAdmin: true,
  },

  {
    path: "/device",
    excat: true,
    icon: "flaticon-computer",
    name: "Device Owner",
    component: DeviceContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/user",
    excat: true,
    icon: "flaticon-user",
    name: "Users",
    component: UserContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/oauth-token",
    excat: true,
    icon: "flaticon-lock",
    name: "OAuth",
    component: TokenContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/command-center",
    excat: true,
    icon: "flaticon-interface-2",
    name: "Command Center",
    component: CommandContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/oauth-token/add",
    excat: true,
    name: "token-add",
    component: TokenAddContainer,
    isSidenav: false,
    isAdmin: true,
  },
  {
    path: "/oauth-token/:id/edit",
    excat: true,
    name: "token-add",
    component: TokenAddContainer,
    isSidenav: false,
    isAdmin: true,
  },
  {
    path: "/device-manufacturer",
    excat: true,
    icon: "flaticon-truck",
    name: "Device manufacturer",
    component: DeviceManufacturerContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/certificate-logs",
    excat: true,
    icon: "flaticon-open-box",
    name: "Certificate logs",
    component: CertLogsContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/version-info",
    excat: true,
    icon: "flaticon-map",
    name: "Version info",
    component: VersionInfoContainer,
    isSidenav: true,
    isAdmin: true,
  },
  {
    path: "/dashboard",
    excat: true,
    icon: "flaticon-graph",
    name: "Dashboard",
    component: Dashboard,
    isSidenav: true,
    isAdmin: false,
  },
  {
    path: "/devices",
    excat: true,
    icon: "flaticon-imac",
    name: "Devices",
    component: DevicesListContainer,
    isSidenav: true,
    isAdmin: false,
  },
  {
    path: "/spaces",
    excat: true,
    icon: "flaticon-app",
    name: "Spaces",
    component: SpacesListContainer, //SpacesContainer,
    isSubMenu: true,
    isAdmin: false,
  },
  {
    path: "/spaces/spaces-shared-with-me",
    excat: true,
    icon: "flaticon-share",
    name: "Space Share With Me",
    component: SpacesSharedWithMe, //SpacesContainer,
    isAdmin: false,
  },
  {
    path: "/spaces/spaces-shared-with-me/spaces-device/:Id",
    excat: true,
    icon: "flaticon-share",
    name: "Spaces Devices",
    component: SpacesDevicesList, //SpacesContainer,
    isAdmin: false,
  },
  {
    path: "/spaces/spaces-shared-users/:Id",
    excat: true,
    icon: "flaticon-share",
    name: "Shared Spaces",
    component: SharedSpacesUser, //SpacesContainer,
    isSidenav: false,
    isAdmin: false,
  },
  {
    path: "/sensor-rules",
    excat: true,
    icon: "fa fa-thermometer-empty",
    name: "Sensor Rules",
    component: RulesListContainer,
    isSidenav: true,
    isAdmin: false,
  },
  {
    path: "/scheduler-list",
    excat: true,
    icon: "flaticon-clock-1",
    name: "Scheduler",
    component: SchedulerList,
    isSidenav: true,
    isAdmin: false,
  },
  {
    path: "/scheduler-logs",
    excat: true,
    icon: "flaticon-list",
    name: "Scheduler Logs",
    component: ScheduleTimerLogs,
    isSidenav: true,
    isAdmin: false,
  }, 
  {
    path: "/sensor-rules/:id",
    excat: true,
    icon: "fa fa-thermometer-empty",
    name: "Sensor Rules",
    component: RulesListContainer,
    isSidenav: false,
    isAdmin: false,
  },
  {
    path: "/change-password",
    excat: true,
    icon: "flaticon-lock",
    name: "Change Password",
    component: ChangePasswordContainer,
    isSidenav: true,
    isAdmin: false,
  },
];

export default routes;
