import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { clientLoginAction, getCountryCodesAction } from "../../actions/authActions";
import { toast } from "react-toastify";
import { useHistory, Redirect } from "react-router";
import { Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { GetLocalStorage } from "../../services/SMILocalStorage";
import { INVALID_MOBILE_NUMBER_WARNING, countryCodeDefaultValue } from "../../helper/constants";
import phone from "phone";

const ClientLogin = ({ clientLoginAction, getCountryCodesAction }) => {
    const history = useHistory();
    const [forgotPass, setForgotPass] = useState(false);
    const [showPasswordText, setShowPasswordText] = useState(false);
    const [countryPrefixDropdownOptions, setCountryPrefixDropdownOptions] = useState([]);
    const forPass = forgotPass;
    const data = {
        countryPrefix: countryCodeDefaultValue,
        mobileNo: "",
        password: "",
    };

    useEffect(()=> {
        getCountryCodesData();
    }, []);

    useEffect(() => {
    }, [countryPrefixDropdownOptions]);

    const getCountryCodesData = async () => {
        const getCountryCodesRes = await getCountryCodesAction();
        if(getCountryCodesRes.status === 200) {
            let countryCodeArr = [];
            getCountryCodesRes.data.forEach((countryCodeItem)=> {
                countryCodeArr.push({
                    label: `${countryCodeItem.name} (${countryCodeItem.dial_code})`,
                    value: countryCodeItem.dial_code
                });
            })
            setCountryPrefixDropdownOptions(countryCodeArr);
        }
    };

    const isForgotPass = (e) => {
        e.preventDefault();
        setForgotPass(true);
    };

    const handleShowPassword = () => {
        setShowPasswordText(!showPasswordText);
    }

    const validate = Yup.object({
        countryPrefix: Yup.string().required("The country code field is required."),
        mobileNo: Yup.string().required("The mobile no field is required."),
        password: Yup.string().required("The password field is required."),
    });
    const userDetails = GetLocalStorage('smi-user');
    if (userDetails?.access_token) {
        return <Redirect to="/devices" />;
    } else {
        return (
            <>
                <div className="m-grid m-grid--hor m-grid--root m-page">
                    <div
                        className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile 		m-login m-login--1 m-login--singin"
                        id="m_login"
                    >
                        <div className="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
                            <div className="m-stack m-stack--hor m-stack--desktop">
                                <div className="m-stack__item m-stack__item--fluid">
                                    <div className="m-login__wrapper">
                                        <div className="m-login__logo">
                                            <img alt="" src="/images/login-page-logo.png"></img>
                                        </div>
                                        <div className="m-login__signin">
                                            <div className="m-login__head">
                                                <h3 className="m-login__title">Sign In</h3>
                                            </div>
                                            {!forPass ? (
                                                <Formik
                                                    initialValues={data}
                                                    validationSchema={validate}
                                                    onSubmit={(inputs, { setSubmitting }) => {
                                                        const loginReqObj = {
                                                            mobileNo: `${inputs.countryPrefix.value}${inputs.mobileNo}`,
                                                            password: inputs.password
                                                        }
                                                        const validateMobileNo = phone(loginReqObj.mobileNo, {country: null});
                                                        const values = Object.assign({}, loginReqObj);
                                                        if (validateMobileNo.isValid === false) {
                                                            toast.error(INVALID_MOBILE_NUMBER_WARNING, {
                                                                position: "top-center",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        }
                                                        else {
                                                            setSubmitting(true);
                                                            clientLoginAction(values).then((user) => {
                                                                setSubmitting(false);
                                                                if (user.error) {
                                                                    toast.error(user.message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    history.push("/devices");
                                                                    setTimeout(() => {
                                                                        window.location.reload();
                                                                    }, 100);
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        handleChange,
                                                        setFieldValue,
                                                        errors,
                                                        values,
                                                        touched,
                                                    }) => (
                                                        <Form
                                                            noValidate
                                                            className="m-login__form m-form m-login--forget-password"
                                                            onSubmit={handleSubmit}
                                                        >
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6" controlId="countryPrefix">
                                                                    <Select
                                                                        value={values.countryPrefix}
                                                                        onChange={(onChangeCountryPrefix) => {
                                                                            setFieldValue("countryPrefix", onChangeCountryPrefix);
                                                                        }}
                                                                        // defaultValue={[{ label: "India (+91)", value: "+91" }]}
                                                                        options={countryPrefixDropdownOptions}
                                                                        isInvalid={touched.countryPrefix && !!errors.countryPrefix}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {" "}
                                                                        {errors.countryPrefix}{" "}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6" controlId="mobileNo">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="mobileNo"
                                                                        autoComplete="off"
                                                                        placeholder="Mobile no"
                                                                        value={values.mobileNo}
                                                                        onChange={handleChange}
                                                                        isInvalid={touched.mobileNo && !!errors.mobileNo}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {" "}
                                                                        {errors.mobileNo}{" "}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="12" controlId="password" className="m-form_password m-form_password-login">
                                                                    <Form.Control
                                                                        type={showPasswordText ? "text" : "password"}
                                                                        placeholder="Password"
                                                                        name="password"
                                                                        value={values.password}
                                                                        onChange={handleChange}
                                                                        isInvalid={touched.password && !!errors.password}
                                                                    />
                                                                    <Button onClick={() => { handleShowPassword(); }}>
                                                                        <i className={showPasswordText ? 'm-menu__link-icon fa fa-eye-slash' : 'm-menu__link-icon fa fa-eye'}></i>
                                                                    </Button>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {" "}
                                                                        {errors.password}{" "}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <div className="row m-login__form-sub">
                                                                <div className="col m--align-right">
                                                                    <a
                                                                        href="#x"
                                                                        id="m_login_forget_password"
                                                                        className="m-link"
                                                                        onClick={isForgotPass}
                                                                    >
                                                                        Forgot Password ?
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="m-login__form-action">
                                                                <button
                                                                    type="submit"
                                                                    id="m_login_signin_submit"
                                                                    className={`btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air`}
                                                                >
                                                                    Sign In
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            ) : (
                                                <form
                                                    className="form fv-plugins-bootstrap fv-plugins-framework"
                                                >
                                                    <div className="mb-5 alert alert-primary mt-5">
                                                        <div className="alert-text ">
                                                            Please contact support center for Password
                                                            Regeneration.
                                                        </div>
                                                    </div>
                                                    <div className="m-login__form-action text-center">
                                                        <input
                                                            type="button"
                                                            id="m_login_forget_password_cancel"
                                                            className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air"
                                                            onClick={() => {
                                                                setForgotPass(false);
                                                            }}
                                                            value="Cancel"
                                                        />
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="m-stack__item m-stack__item--center"></div>
                            </div>
                        </div>
                        <div
                            className="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content"
                            style={{
                                backgroundImage: "url(../../../assets/app/media/img//bg/bg-4.jpg)",
                            }}
                        >
                            <div className="m-grid__item m-grid__item--middle"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default connect(null, {
    clientLoginAction,
    getCountryCodesAction
})(ClientLogin);