import axios from 'axios'
import apiurl from '../config/api'
import { GetLocalStorage } from "../services/SMILocalStorage";

const userDetails = GetLocalStorage("smi-user");

export function changePasswordAction(data) {
  return axios.post(apiurl.clientChangePasswordUrl, { ...data, userId: userDetails.user._id })
    .then(result => {
      return result;
    }).catch(error => {
      return error.response.data;
    });
}