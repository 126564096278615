import api from "../config/api";

/**
 * Request intercept
 *
 */
const requestIntercept = (axios) => {
  axios.interceptors.request.use(
    (config) => {
      // Fetch access token
      const token = JSON.parse(localStorage.getItem("smi-user"));
      // Embade token with request
      if (token) {
        config.headers.Authorization = `Bearer ${token.access_token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};

/**
 * Response intercept
 */
const responseIntercept = (axios) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        const originalRequest = error.response.config;
        if (originalRequest.url === api.RefreshToken) {
          localStorage.setItem("smi-user", null);
          return Promise.reject(error);
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refresh_token = JSON.parse(
            localStorage.getItem("smi-user")
          ).refresh_token;
          return axios
            .post(api.RefreshToken, {
              refresh_token,
            })
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("smi-user", JSON.stringify(res.data.data));
                axios.defaults.headers.common["Authorization"] =
                  "Bearer " + res.data.data.access_token;
                return axios(originalRequest);
              }
            });
        }
      }
      return Promise.reject(error);
    }
  );
};

/**
 * Add auth token if not expired
 */
export function setupAxios(axios) {
  requestIntercept(axios);
  responseIntercept(axios);
}
