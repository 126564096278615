import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { getUserAction } from "../../../actions/userActions";

class UserList extends Component {
  componentDidMount() {
    this.props.getUsers();
  }

  render() {
    const { users } = this.props;
    const columns = [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        label: "Name",
        name: "name",
        sortable: true
      },
      {
        label: "Mobile No.",
        name: "mobileNo",
        sortable: true
      },
      {
        label: "Email",
        name: "email",
        sortable: true
      },
      {
        label: "Created",
        name: "createdAt",
        sortable: true,
        options: {
          customBodyRender: value => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:s A");
            return localDate;
          }
        }
      }
    ];
    const data = users && users.users && users.users.length ? users.users : [];
    const totalUser = users && users.totalUser ? users.totalUser : 0;
    const thisWeek = users && users.thisWeek ? users.thisWeek : 0;
    const thisMonth = users && users.thisMonth ? users.thisMonth : 0;
    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return (
          <>
            <span className="m--font-bold">
              Total : {totalUser} | Registered last 7 days : {thisWeek} |
              Registered last 30 days : {thisMonth}
            </span>
          </>
        );
      }
    };
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <MUIDataTable
              title={"User List"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  state => ({
    users: state.users
  }),
  dispatch => ({
    dispatch,
    getUsers: () => dispatch(getUserAction())
  })
)(UserList);
