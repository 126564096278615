import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { spaceSharedWithUser, sharedSpaceRevocationAction } from "../../actions/spacesActions";
import {
    Grid,
    Box,
} from "@material-ui/core";
import PersonRemoveIcon from '@material-ui/icons/DeleteForever';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import { useParams, useHistory } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import { SPACE_REVOCATION_SUCCESSFULLY } from "../../helper/constants";
import { toast } from "react-toastify";
import DevicesDeleteModal from "../../components/devicesComponents/DevicesDeleteModal";
import Loader from "../../components/Loader";

const SharedSpacesUser = ({ spaceSharedWithUser, sharedSpaceRevocationAction }) => {
    const [sharedUsersList, setSharedUsersList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [spaceName, setSpaceName] = useState("");
    const localUserData = localStorage.getItem('smi-user');
    const jsonLocalUserData = JSON.parse(localUserData);
    const { Id } = useParams();
    let history = useHistory();
    const user_name = jsonLocalUserData?.user?.name ? jsonLocalUserData?.user?.name : "";

    /* breadCrumb */
    const SharedSpaceBreadCrumb = [
        {
            title: "Spaces",
            link: "/spaces",
            classData: "",
        },
        {
            title: "Spaces Shared With Me",
            link: `/spaces/spaces-shared-users/${Id}`,
            classData: "active_anv_link",
        }
    ];

    useEffect(() => {

        shareSpacesUsers();

    }, []);

    /* get spaces shared user list */
    const shareSpacesUsers = async () => {
        setLoading(true);
        const spaceUser = await spaceSharedWithUser(Id);
        if (spaceUser.status != 200) {
            history.push("/");
        }
        setSharedUsersList(spaceUser.data);
        spaceUser?.data?.length && spaceUser.data.forEach((spaceSharedWithUserData) => {
            if (spaceSharedWithUserData?.spaceId?._id === Id) {
                setSpaceName(spaceSharedWithUserData.spaceId.name);
            }
        })
        setLoading(false);
        setIsFetching(false);
    }

    /* handle delete revocation modal close */
    const onDeleteUserModelClose = () => {
        setDeleteConfirmModal(false);
    };
    /* handle delete revocation confirm */
    const onDeleteUserConfirm = async () => {
        handleSpaceRevocation();
        onDeleteUserModelClose();
    };

    const handleSpaceRevocation = async () => {
        let IdArray = [];
        let spaceId;
        if (selectedRows.length > 0) {
            sharedUsersList.map(async (sharedUserData) => {
                if (selectedRows.includes(sharedUserData._id)) {
                    IdArray.push(sharedUserData.sharedWithUserId._id)
                    spaceId = sharedUserData.spaceId;
                }
            });
            const revocationSpace = await sharedSpaceRevocationAction({ userIds: IdArray, spaceId, Id, user_name });
            if (revocationSpace.status === 200) {
                toast.success(SPACE_REVOCATION_SUCCESSFULLY, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                refreshComponent();
                setSelectedRows([]);
            }
        }
    }

    /* refresh after delete device(s) */
    const refreshComponent = () => {
        setLoading(true);
        setIsFetching(true);
        shareSpacesUsers();
        setLoading(false);
        setIsFetching(false);
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    item
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    {selectedRows.length > 0 ? (
                        <>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Revoke space(s)
                                    </Tooltip>
                                }
                            >
                            <PersonRemoveIcon
                                onClick={() => {
                                    setDeleteConfirmModal(true)
                                }}
                                style={{ fontSize: 40, color: "#fff", background: "#868aa8", padding: "8", margin: "0 5", cursor: "pointer" }}
                            />
                            </OverlayTrigger>
                        </>
                    ) : (
                        ""
                    )}
                </Grid>
            </GridToolbarContainer>
        );
    };

    /* space shared by you columns */
    const columns = [
        {
            field: "name",
            headerName: "Name",
            editable: false,
            filterable: false,
            sortable: false,
            width: 140,
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return params.row.sharedWithUserId.name ? params.row.sharedWithUserId.name : '-';
            },
        },
        {
            field: "mobileNo",
            headerName: "Mobile No",
            editable: false,
            filterable: false,
            sortable: false,
            width: 140,
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return params.row.sharedWithUserId.mobileNo ? params.row.sharedWithUserId.mobileNo : '-';
            },
        },
    ];
    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            <DevicesDeleteModal
                modalShown={deleteConfirmModal}
                onModelClose={onDeleteUserModelClose}
                onDeleteConfirm={onDeleteUserConfirm}
                headerTitle="Revoke Spaces"
                message={`Are you sure you want to stop sharing space with this user?`}
            />
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                    <div className="m-portlet m-portlet--mobile">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                    <h2>{`${spaceName} -`} Space Access User's List</h2>
                                    <CustomBreadCrumb breadCrumData={SharedSpaceBreadCrumb} />
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="row spacesListDetails">
                                <Grid xs={12}
                                    style={{
                                        display: "flex",
                                        flexDirection: "initial",
                                        flexWrap: "nowrap",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        margin: "0 0 10px"
                                    }}>
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={process.env.PUBLIC_URL + "images/groupicn.png"}
                                    />
                                    <h3 style={{ padding: "0 3vh", margin: "0" }}>{jsonLocalUserData?.user?.name ? `${jsonLocalUserData?.user?.name}_group` : ""}</h3>
                                </Grid>
                                <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>
                                    <DataGrid
                                        getRowId={(row) => row._id}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        rows={sharedUsersList}
                                        rowHeight={40}
                                        columns={columns}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        onSelectionModelChange={(newSelection) => {
                                            setSelectedRows(newSelection);
                                        }}
                                        selectionModel={selectedRows}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect('', {
    spaceSharedWithUser,
    sharedSpaceRevocationAction,
})(SharedSpacesUser);