import React from "react";
import { Modal, Button } from "react-bootstrap";

const DevicesDeleteModal = ({
  modalShown,
  onModelClose,
  onDeleteConfirm,
  headerTitle = "Delete record(s)",
  message,
  hideButton
}) => {
  return (
    <>
      <Modal
        show={modalShown}
        onHide={onModelClose}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message
            ? message
            : "Are you sure you want to delete this record(s)?"}
        </Modal.Body>
        <Modal.Footer>
         {hideButton=== true ?
         (<><Button
            size="sm"
            variant="btn btn-secondary px-3 py-2"
            onClick={onModelClose}
          >
            Ok
          </Button>
          </> )
          :
          (<>
            <Button
            size="sm"
            variant="btn btn-primary px-3 py-2"
            onClick={onDeleteConfirm}
          >
            Yes
          </Button>
          <Button
            size="sm"
            variant="btn btn-secondary px-3 py-2"
            onClick={onModelClose}
          >
            No
          </Button>
          </> )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DevicesDeleteModal;
