import {
  SPACES_FETCHED,
  SPACE_DEVICES_FETCHED,
  SHARED_SPACE_DEVICES_FETCHED,
  SPACE_CREATED,
  SPACE_DEVICES_ADDED,
  SPACE_DELETED,
  SPACE_DEVICE_DELETED,
} from "../actions/spacesActions";

export function spacesReducer(state = [], action) {
  switch (action.type) {
    case SPACES_FETCHED:
      return action.spaces;

    default:
      return state;
  }
}

export function getDevicesInSpaceReducer(state = [], action) {
  switch (action.type) {
    case SPACE_DEVICES_FETCHED:
      return action.data;

    default:
      return state;
  }
}

export function createSpaceReducer(state = [], action) {
  switch (action.type) {
    case SPACE_CREATED:
      return action.spaces;

    default:
      return state;
  }
}

export function addDevicesToSpaceReducer(state = [], action) {
  switch (action.type) {
    case SPACE_DEVICES_ADDED:
      return action.spaces;

    default:
      return state;
  }
}

export function deleteDeviceInSpaceReducer(state = [], action) {
  switch (action.type) {
    case SPACE_DEVICE_DELETED:
      return action.spaces;

    default:
      return state;
  }
}

export function deleteSpaceReduer(state = [], action) {
  switch (action.type) {
    case SPACE_DELETED:
      return action.spaces;

    default:
      return state;
  }
}

export function sharedSpaceDevicesReducer(state = [], action) {
  switch (action.type) {
    case SHARED_SPACE_DEVICES_FETCHED:
      return action.shared_space_devices;

    default:
      return state;
  }
}
