import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Validations } from "./SpaceSharingValidation";
import { Formik } from "formik";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { toast } from "react-toastify";
import { Modal, Form, Col } from "react-bootstrap";
import phone from "phone";
import {
    createSpaceShareDeviceAction,
    getSpacesAction,
    getSpaceSharedContactListAction
} from "../../actions/spacesActions";
import { getCountryCodesAction } from "../../actions/authActions";
import {
    SPACE_SHARED_SUCCESSFULLY,
    SPACE_IMAGE_WARNING,
    countryCodeDefaultValue,
    INVALID_MOBILE_NUMBER_WARNING,
    COUNTRY_CODE_WARNING
} from "../../helper/constants";

const SpaceSharingDevices = ({ spaces,
    createSpaceShareDeviceAction,
    getSpaceSharedContactListAction,
    getCountryCodesAction,
    show, onHide, devicesOptions,
    onClose, refreshComponent,
    loading, selectedDevicesDropdownValue,
    devicesList }) => {

    const fileInput = useRef(null);
    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState([]);
    const [contactNoOptions, setContactNoOptions] = useState([]);
    const [countryPrefixDropdownOptions, setCountryPrefixDropdownOptions] = useState([]);

    useEffect(() => {
        callUseEffect();
    }, []);

    const callUseEffect = async () => {
        const getSpaceSharedContactRes = await getSpaceSharedContactListAction(devicesList?.ownerDevice?.user?._id);
        if (getSpaceSharedContactRes.status === 200) {
            let contactNoArr = [];
            getSpaceSharedContactRes.data.map((spaceSharedDetail) => {
                contactNoArr.push({
                    label: `${spaceSharedDetail.mobileNo} - ${spaceSharedDetail.name}`,
                    value: spaceSharedDetail.mobileNo
                })
            })
            setContactNoOptions(contactNoArr);
        }
        const getCountryCodesRes = await getCountryCodesAction();
        if(getCountryCodesRes.status === 200) {
            let countryCodeArr = [];
            getCountryCodesRes.data.forEach((countryCodeItem)=> {
                countryCodeArr.push({
                    label: `${countryCodeItem.name} (${countryCodeItem.dial_code})`,
                    value: countryCodeItem.dial_code
                });
            })
            setCountryPrefixDropdownOptions(countryCodeArr);
        }
    }

    const onAddImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0])
            setImageSelected(true)

        }
    }

    const getSpaceImgSrc = (image) => {
        if (spaces.length == 0) {
            return URL.createObjectURL(image);
        } else {
            if (typeof image === "string") {
                var resultArr = image.split("/");
                var result = resultArr[1] + "/" + resultArr[2] + "/" + resultArr[3]
                return result;
            } else {
                return URL.createObjectURL(image);
            }
        }
    }

    const devicesCustomStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            maxHeight: '80px',
            overflowY: state.hasValue ? 'scroll' : 'hidden',
        }),
    }

    /* handle submit */
    const handleSubmit = async (evt, { resetForm }) => {
        const data = new FormData();
        let deviceMacIdsValue = [];
        let checkMobileNoValidation;
        data.set("name", evt.name);
        data.append("image", image);
        if(evt.userMobileNo.includes("+")) {
            data.set("userMobileNo", evt.userMobileNo);
            checkMobileNoValidation = phone(evt.userMobileNo, {country: null});
        }
        else {
            const mobileNumber = `${evt.countryPrefix.value}${evt.userMobileNo}`;
            data.set("userMobileNo", mobileNumber);
            checkMobileNoValidation = phone(mobileNumber, {country: null});
        }
        evt.deviceMacIds.forEach((items) => {
            deviceMacIdsValue.push(items.value)
        })
        data.append("deviceMacIds", deviceMacIdsValue)
        if(checkMobileNoValidation.isValid === false) {
            toast.error(INVALID_MOBILE_NUMBER_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(evt.userMobileNo.includes("+") && !(evt.userMobileNo.includes(evt.countryPrefix.value))) {
            toast.error(COUNTRY_CODE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (
            image.length !== 0
            ) {
            const saveSharedSuccess = await createSpaceShareDeviceAction(data);
            if (saveSharedSuccess.status === 200) {
                toast.success(SPACE_SHARED_SUCCESSFULLY, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                resetForm({ name: '', userMobileNo: '', image: '', deviceMacIds: '' })
                onClose();
                refreshComponent();
            } else {
                toast.error(saveSharedSuccess.response.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error(SPACE_IMAGE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                validationSchema={Validations}
                onSubmit={handleSubmit}
                initialValues={{ name: '', userMobileNo: '', image: '', deviceMacIds: selectedDevicesDropdownValue, countryPrefix: countryCodeDefaultValue }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldTouched,
                    errors,
                    resetForm,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <Modal
                show={show}
                        backdrop="static"
                        onShow={() => (show ? resetForm() : false)} 
            >
                <Modal.Header closeButton
                    onClick={onHide}
                >
                    <Modal.Title>Space</Modal.Title>
                </Modal.Header>
                        <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="name">
                                        <Form.Label>Space Name <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Space Name"
                                            name="name"
                                            autoComplete='off'
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={touched.name && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.name}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="countryPrefix">
                                    <Form.Label>Country Code <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Select
                                            value={values.countryPrefix}
                                            onChange={(onChangeCountryPrefix) => {
                                                setFieldValue("countryPrefix", onChangeCountryPrefix);
                                            }}
                                            options={countryPrefixDropdownOptions}
                                            isInvalid={touched.countryPrefix && !!errors.countryPrefix}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.countryPrefix}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="name">
                                        <Form.Label>Mobile No <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <CreatableSelect
                                            name="userMobileNo"
                                            isClearable
                                            onChange={(mobileNoOnChange) => {
                                                setFieldValue("userMobileNo", mobileNoOnChange ? mobileNoOnChange.value : mobileNoOnChange);
                                            }}
                                            options={contactNoOptions}
                                            isInvalid={touched.userMobileNo && !!errors.userMobileNo}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.userMobileNo}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="Devices">
                                        <Form.Label>Devices <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Select
                                            value={values.deviceMacIds}
                                            onChange={(value) => {
                                                setFieldValue("deviceMacIds", value);
                                            }}
                                            name="deviceMacIds"
                                            options={devicesOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            styles={devicesCustomStyles}
                                            isInvalid={touched.deviceMacIds && !!errors.deviceMacIds}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.deviceMacIds}{" "}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="image">
                                        <Form.Label>Space Image <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <div
                                            style={{
                                                margin: "2vh auto",
                                                border: "2px dashed gray",
                                                padding: "1vh",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {imageSelected ? (<img onClick={() => { fileInput.current.click() }} style={{ height: "320px", width: "320px" }} src={getSpaceImgSrc(image)} />

                                            ) : (
                                                <div onClick={() => {
                                                    fileInput.current.click()
                                                }} style={{ height: "220px", width: "430px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                    <CameraAltOutlinedIcon style={{ fontSize: 100, color: "gray" }} />
                                                    <p style={{ fontSize: 20 }}>Take a picture of the Space.</p>
                                                </div>
                                            )}
                                        </div>

                                        <Form.Control
                                            type="file"
                                            accept="image/*"
                                            onChange={onAddImageChange}
                                            ref={fileInput}
                                            style={{ display: "none" }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="submit"
                                    className={`btn btn-primary ${loading
                                        ? "m-loader m-loader--right m-loader--light"
                                        : ""
                                        }`}
                                >
                                    Create & Share
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={onHide}
                                >
                                    Close
                                </button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = (state) => ({
    spaces: state.spaces
});

export default connect(mapStateToProps, {
    createSpaceShareDeviceAction,
    getSpaceSharedContactListAction,
    getSpacesAction,
    getCountryCodesAction
})(SpaceSharingDevices);