import React, { useState, useEffect } from "react";
import { store } from "../../store";
import { connect } from "react-redux";
import {
    Grid,
    Box,
    Button
} from "@material-ui/core";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Loader from "../../components/Loader";
import {
    getDevicesAction,
    updateDeviceStatusAction,
} from "../../actions/devicesActions";
import {
    deleteDeviceInSpaceAction,
    deleteSpaceAction,
    getSpacesAction,
    shareSpaceDeviceAction,
} from "../../actions/spacesActions";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { toast } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { NavLink } from "react-router-dom";
import globalHelper from "../../helper/globalHelper";
import socket from "../../helper/socket";
import DeviceInfo from "../../components/devicesComponents/DeviceInfo";
import {
    DEVICE_TYPE_SWITCH_4,
    DEVICE_TYPE_SWITCH_2,
    DEVICE_TYPE_SWITCH,
    DEVICE_TYPE_FAN,
    DEVICE_TYPE_LIGHT_LWCT,
    DEVICE_TYPE_LIGHT_LW,
    DEVICE_TYPE_LIGHT_LRGB,
    DEVICE_TYPE_LIGHT_LRGBW,
    DEVICE_TYPE_LIGHT_LRGBWCT,
    DELETE_SPACES_WARNING,
    DELETE_DEVICES_FROM_SPACES_WARNING,
    DELETE_SPACE_WARNING,
    SPACE_SHARED_SUCCESSFULLY,
    DEVICE_TYPE_TEMP_SENSOR,
    DEVICE_TYPE_SOCKET,
    DEVICE_OFFLINE_WARNING,
    ALL_DEVICE_OFFLINE_WARNING,
    NO_DEVICES_AVAILABLE_IN_SPACE_WARNING,
    DEVICES_ALREADY_ADDED_IN_SPACE_WARNING,
    INVALID_MOBILE_NUMBER_WARNING,
    COUNTRY_CODE_WARNING
} from "../../helper/constants";
import AddSpaces from "../../components/devicesComponents/AddSpaces";
import EditSpaces from "../../components/devicesComponents/EditSpaces";
import DevicesDeleteModal from "../../components/devicesComponents/DevicesDeleteModal";
import EditGroupName from "../../components/devicesComponents/EditGroupName";
import AddDevicesToSpace from "../../components/devicesComponents/AddDevicesToSpace";
import EditDeviceName from "../../components/devicesComponents/EditDeviceName";
import DevicesShareModal from "../../components/devicesComponents/DevicesShareModal";
import SpaceDevicesMultipleDeviceControl from "../../components/devicesComponents/SpaceDevicesMultipleDeviceControl";
import { defaultValue, Validations } from "./SpaceDevicesMultipleDeviceControlValidation";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import phone from "phone";

const SpacesList = (
    { spaces, devices, getSpacesAction,
        getDevicesAction, updateDeviceStatusAction,
        deleteDeviceInSpaceAction,
        deleteSpaceAction, shareSpaceDeviceAction }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedDevicesRows, setSelectedDevicesRows] = useState([]);
    const [deviceInfoList, setdeviceInfoList] = useState([]);
    const [devicesList, setDevicesList] = useState([]);
    const [showDeviceList, setShowDeviceList] = useState(false);
    const [showSpaceList, setShowSpaceList] = useState(false);
    const [showDeviceInfo, setShowDeviceInfo] = useState(false);
    const [deleteSingleDeviceModalShown, setDeleteSingleDeviceModalShown] = useState(false);
    const [showEditDevice, setShowEditDevice] = useState(false);
    const [showAddSpaces, setShowAddSpaces] = useState(false);
    const [multipleSpacesdeleteModalShown, setMultipleSpacesdeleteModalShown] = useState(false);
    const [showEditSpaces, setShowEditSpaces] = useState(false);
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [displaySpaces, setDisplaySpaces] = useState(false);
    const [deleteDeviceInSpaceModalShown, setDeleteDeviceInSpaceModalShown] = useState(false);
    const [deleteSpaceModalShown, setDeleteSpaceModalShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [sharedShowHide, setSharedShowHide] = useState(false);
    const [showMultipleDeviceControlModal, setShowMultipleDeviceControlModal] = useState(false);
    const [editParamsRowData, setEditParamsRowData] = useState();
    const [socketResponseDateString, setSocketResponseDateString] = useState();
    const [spaceIdCondition, setSpaceIdCondition] = useState("");
    const [groupName, setGroupName] = useState("");
    const [spaceName, setSpaceName] = useState("");
    const [editParams, setEditParams] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const localUserData = localStorage.getItem('smi-user');
    const jsonLocalUserData = JSON.parse(localUserData);

    /* breadCrumb */
    const SpaceBreadCrumb = [
        {
            title: "Spaces",
            link: "/spaces",
            classData: "active_anv_link",
        }
    ];

    useEffect(() => {
        setLoading(true);
        getSpacesAction();
        getDevicesAction().then((response) => {
            setDevicesList(response);
            if (response && response.ownerDevice.devices.length) {
                response.ownerDevice.devices.forEach((device, index) => {
                    socket.io.on(`deviceDetail${device.deviceMacId}`, (socketData) => {
                        const socketListenedData = JSON.parse(socketData);
                        changeDeviceStatus(socketListenedData);
                    });
                });
            }
            setIsFetching(false);
            setLoading(false);
            setGroupName(response.ownerDevice.name);
        });
    }, []);

    /* on/off status changes for owner devices */
    function changeDeviceStatus(socketListenedData) {
        const response = store.getState().devices;
        const updatedList =
            response &&
            response.map((device) => {
                if (socketListenedData.UUID === device.deviceMacId) {
                    const SocketDataObjkeys = Object.keys(socketListenedData.get_OnOff);
                    SocketDataObjkeys.forEach((key, index) => {
                        if (
                            key === DEVICE_TYPE_SWITCH_4 ||
                            DEVICE_TYPE_SWITCH_2 
                        ) {
                            if (key === device.deviceMacId.split("-")[1]) {
                                device.on = socketListenedData.get_OnOff[key];
                                device.instanceId.caps.get_OnOff.params.on =
                                    socketListenedData.get_OnOff[key];
                                device.ownerDeviceOnOff =
                                    socketListenedData.get_OnOff[key];
                                device.instanceId.online = socketListenedData.online;
                                device.ownerDeviceOnline = socketListenedData.online;
                            }
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN
                            || String(device.deviceType.name) === DEVICE_TYPE_SWITCH
                            || String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR
                            || String(device.deviceType.name) === DEVICE_TYPE_SOCKET
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW
                            || String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.online = socketListenedData.online;
                            device.ownerDeviceOnline = socketListenedData.online;
                            device.on = socketListenedData.get_OnOff.on;
                            device.instanceId.caps.get_OnOff.params.on =
                                socketListenedData.get_OnOff.on;
                            device.ownerDeviceOnOff =
                                socketListenedData.get_OnOff.on;
                            device.instanceId.updatedAt = socketListenedData.updatedAt;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_FAN) {
                            device.instanceId.caps.get_FanSpeed.params.speed =
                                socketListenedData.get_FanSpeed.speed;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_Brightness.params.brightness =
                                socketListenedData.get_Brightness.brightness;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_LIGHT_LWCT ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorTemperature.params.temperature =
                                socketListenedData.get_ColorTemperature.temperature;
                        }
                        if (String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGB ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBW ||
                            String(device.deviceType.name) ===
                            DEVICE_TYPE_LIGHT_LRGBWCT) {
                            device.instanceId.caps.get_ColorSpectrum.params.R = socketListenedData.get_ColorSpectrum.R;
                            device.instanceId.caps.get_ColorSpectrum.params.G = socketListenedData.get_ColorSpectrum.G;
                            device.instanceId.caps.get_ColorSpectrum.params.B = socketListenedData.get_ColorSpectrum.B;
                        }
                        if (String(device.deviceType.name) === DEVICE_TYPE_TEMP_SENSOR) {
                            device.instanceId.caps.get_TemperatureControl.params = socketListenedData?.get_TemperatureControl;
                            device.instanceId.caps.get_HumidityControl.params = socketListenedData?.get_HumidityControl;

                            const generateSocketResDate = new Date();
                            setSocketResponseDateString(generateSocketResDate);
                        }
                    });
                    return device;
                } else {
                    return device;
                }
            });
        updateDeviceStatusAction(updatedList);
    }
    /* shareModalOpen event */
    const shareModalOpen = () => {
        setSharedShowHide(true);
    };
    /* shareModalClose event */
    const shareModalClose = () => {
        setSharedShowHide(false);
    };
    /* handle device info event */
    const handleShowDeviceInfo = () => {
        setShowDeviceInfo(false);
    }
    /* send socket event */
    const sendSocketEventData = async (deviceData, deviceExtraPayload, deviceTemperature, deviceRGB) => {
        if (deviceData.instanceId.online) {

            const payload = await globalHelper.getDeviceSocketPayload(deviceData, deviceExtraPayload, deviceTemperature, deviceRGB);
            socket.io.emit("deviceDetail-v1", payload);
        }
        else {
            toast.error(DEVICE_OFFLINE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    /* handleMove Multiple Spaces Delete modal event */
    const multipleSpacesDeleteModelClose = () => {
        setMultipleSpacesdeleteModalShown(false);
    };
    /* delete confirmation event */
    const multipleSpacesDeleteConfirm = async () => {
        handleMultipleSpacesDeviceDelete();
        multipleSpacesDeleteModelClose();
    };
    /* delete multiple devices from spaces event */
    const handleMultipleSpacesDeviceDelete = () => {
        if (selectedRows.length > 0) {
            spaces.map(async (spacesData) => {
                if (selectedRows.includes(spacesData._id)) {
                    const deleteSpaces = await deleteSpaceAction(spacesData._id);
                    if (deleteSpaces.status === 200) {
                        refreshComponent();
                        setSelectedRows([]);
                        toast.success(deleteSpaces.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }
    };

    /* handle Space Devices Delete modal event */
    const onDeleteDeviceInSpaceModelClose = () => {
        setDeleteDeviceInSpaceModalShown(false);
    };
    /* delete confirmation event */
    const onDeleteDeviceInSpaceConfirm = async () => {
        handleDevicesSpaceDelete();
        onDeleteDeviceInSpaceModelClose();
    };
    /*  delete devices from space event */
    const handleDevicesSpaceDelete = () => {
        devicesList.ownerDevice.devices.forEach((devicesData) => {
            if (selectedDevicesRows.includes(devicesData._id)) {
                deleteDeviceInSpaceAction({ deviceId: devicesData.deviceMacId }).then((data) => {
                    if (data.status === 200) {
                        toast.success(data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setSelectedDevicesRows([]);
                        refreshComponent();

                    }
                })
            }
        });
    };

    /* handle Space Delete modal event */
    const onDeleteSpaceModelClose = () => {
        setDeleteSpaceModalShown(false);
    };
    const onDeleteSpaceConfirm = async () => {
        handleSpaceDelete();
        onDeleteSpaceModelClose();
    };
    const handleSpaceDelete = () => {
        deleteSpaceAction(spaceIdCondition).then((data) => {
            if (data.status === 200) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setShowDeviceList(false);
                setSelectedDevicesRows([]);
                refreshComponent();

            }

        });
    };
    /* single device delete event */
    const onDeleteSingleDeviceModelClose = () => {
        setDeleteSingleDeviceModalShown(false);
    };
    const onDeleteSingleDeviceConfirm = () => {
        handleSingleDeviceDelete();
        onDeleteSingleDeviceModelClose();
    };
    const handleSingleDeviceDelete = () => {
        deleteDeviceInSpaceAction({ deviceId: deviceInfoList.deviceMacId }).then((data) => {
            if (data.status === 200) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setShowDeviceInfo(false);
                setSelectedDevicesRows([]);
                refreshComponent();

            }
        })

    };
    const handleChangeUsers = (e) => {
        setMobileNo(e);
    };
    /* shareModal submit event */
    const handleSubmitSharedDevices = async (e) => {
        setLoading(true);
        let sharedSpaceDevicesArray = [];
        let checkMobileNoValidation;
        if (selectedRows.length > 0) {
            if (mobileNo.includes("+") && !(mobileNo.includes(e.countryPrefix.value))) {
                toast.error(COUNTRY_CODE_WARNING, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
            }
            else {
                spaces.forEach((shareSpaceItem) => {
                    let newObj = {};
                    newObj.sharedByUserId = jsonLocalUserData?.user?._id;
                    if (mobileNo.includes("+")) {
                        newObj.sharedByUserMobileNo = mobileNo;
                        checkMobileNoValidation = phone(mobileNo, {country: null});
                    }
                    else {
                        const mobileNumber = `${e.countryPrefix.value}${mobileNo}`;
                        newObj.sharedByUserMobileNo = mobileNumber;
                        checkMobileNoValidation = phone(mobileNumber, {country: null});
                    }
                    newObj.user_name = jsonLocalUserData?.user?.name;
                    if (selectedRows.includes(shareSpaceItem._id)) {
                        newObj.spaceId = shareSpaceItem._id;
                        newObj.isShared = true;
                        let data = Object.assign(newObj);
                        sharedSpaceDevicesArray.push(data);
                    }
                })
                if(checkMobileNoValidation.isValid === false) {
                    toast.error(INVALID_MOBILE_NUMBER_WARNING, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false);
                }
                else {
                    /* save shared devices action call */
                    const saveSharedSuccess = await shareSpaceDeviceAction(sharedSpaceDevicesArray);
                    setLoading(false);
                    if (saveSharedSuccess.status === 200) {
                        toast.success(SPACE_SHARED_SUCCESSFULLY, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        refreshComponent();
                        setSelectedRows([]);
                        shareModalClose();
                    } else {
                        toast.error(saveSharedSuccess.response.data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            }
        }
    };

    const handleDisplayAddDevicesToSpace = () => {
        let checkDevicesWithoutSpace = [];
        devicesList.ownerDevice &&
            devicesList.ownerDevice.devices.length &&
            devicesList.ownerDevice.devices.map((item) => {
                if (!item.spaceId) {
                    checkDevicesWithoutSpace.push(item.deviceMacId);
                }
            })
        if (checkDevicesWithoutSpace.length <= 0) {
            setDisplaySpaces(false);
            toast.error(DEVICES_ALREADY_ADDED_IN_SPACE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            setDisplaySpaces(true);
        }
    }

    /* handle multiple device control modal open */
    const handleMulitpleDeviceControlModalOpen = () => {
        let spaceDevicesList = devices.filter((item) => item?.spaceId?._id === spaceIdCondition);
        let offlineDevicesArr = [];
        devices.map((item) => {
            if (item?.instanceId?.online && spaceIdCondition === item?.spaceId?._id) {
                setShowMultipleDeviceControlModal(true);
            }
            else if (!item?.instanceId?.online && spaceIdCondition === item?.spaceId?._id) {
                offlineDevicesArr.push(item);
            }
        });
        if (spaceDevicesList.length === 0) {
            toast.error(NO_DEVICES_AVAILABLE_IN_SPACE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (offlineDevicesArr.length === spaceDevicesList.length) {
            toast.error(ALL_DEVICE_OFFLINE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    /* handle multiple device control modal close */
    const handleMulitpleDeviceControlModalClose = () => {
        setShowMultipleDeviceControlModal(false);
    };

    /* refresh component */
    const refreshComponent = () => {
        setLoading(true);
        setIsFetching(true);
        getSpacesAction();
        getDevicesAction().then((response) => {
            setDevicesList(response);
            setGroupName(response.ownerDevice.name);
            setIsFetching(false);
            setLoading(false);
        })
    };

    /* custom tool bar for spaces */
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarFilterButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    {selectedRows.length && selectedRows.length > 0 ? (
                        <>  
                            <div>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>
                                    Delete space(s)
                                </Tooltip>
                            }
                        >
                                    <div>
                        <DeleteOutlinedIcon
                            onClick={() => {
                                setMultipleSpacesdeleteModalShown(true);
                            }}
                                            style={{
                                                fontSize: 40, color: "#fff", background: "#868aa8",
                                                padding: "8", margin: "0 5", cursor: "pointer"
                                            }}
                        />
                                    </div>
                        </OverlayTrigger>
                            </div>
                            <div>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Share space(s)
                                        </Tooltip>
                                    }
                                >
                                    <div>
                            <ShareIcon
                                onClick={shareModalOpen}
                                style={{ fontSize: 30, marginRight: "20px", marginTop: "5px", cursor: "pointer" }}
                            />{" "}
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </>
                    ) : (
                        <>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Add space
                                    </Tooltip>
                                }
                            >
                        <AddIcon
                            onClick={() => {
                                setShowAddSpaces(true);
                            }}
                            style={{
                                fontSize: "40px",
                                padding: "8px",
                                color: "#fff",
                                background: "#868aa8",
                                cursor: "pointer",
                            }}
                        />
                            </OverlayTrigger>
                            </>
                    )}
                </Grid>
            </GridToolbarContainer>
        );
    };
    /* custom toolbar for devices */
    const DevicesCustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarFilterButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    item
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >

                    {selectedDevicesRows.length > 0 ? (
                        <>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Delete device(s) from this space
                                    </Tooltip>
                                }
                            >
                            <DeleteOutlinedIcon
                                onClick={() => {
                                    setDeleteDeviceInSpaceModalShown(true);
                                }}
                                    style={{
                                        fontSize: "40px", color: "#fff",
                                        background: "#868aa8", padding: "8px",
                                        cursor: "pointer"
                                    }}
                            />
                            </OverlayTrigger>
                        </>
                    ) : (
                            <>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Add device(s) in this space
                                    </Tooltip>
                                }
                            >
                        <AddIcon
                            onClick={() => {
                                            handleDisplayAddDevicesToSpace();
                            }}
                            style={{
                                fontSize: "40px",
                                padding: "8px",
                                color: "#fff",
                                background: "#868aa8",
                                cursor: "pointer"
                            }}
                        />
                                </OverlayTrigger>
                                <div className="mr-2">

                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                fontSize: 12, marginTop: "4px",
                                                marginRight: "15px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                handleMulitpleDeviceControlModalOpen();
                                            }}
                                        >
                                            Multiple Device Control
                                        </Button>
                                    </>
                    </div>
                        </>
                    )}

                </Grid>
            </GridToolbarContainer>
        );
    };
    const addDefaultSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_PUBLIC_URL}public/images/space_default/missing_images.jpg`
    }
    /* space list columns  */
    const columns = [
        {
            field: "image",
            headerName: "Image",
            editable: false,
            filterable: false,
            sortable: false,
            width: 110,
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div class="spaceDetailListImage">
                        <img onError={addDefaultSrc}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${params.value
                                }`} alt="no Images"
                        />
                    </div>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            renderCell: (params) => {
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                Edit space
                            </Tooltip>
                        }
                    >
                        <span
                            onClick={
                                () => {
                                    setShowEditSpaces(true);
                                    setEditParams(params.row);
                                }}
                            style={{ color: "#3f51b5", cursor: "pointer" }}
                        >
                            {params.row.name}
                        </span>
                    </OverlayTrigger>
                );
            },
        },
        {
            field: "spaceInfo",
            headerName: "Info",
            width: 110,
            editable: false,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                Space device(s)
                            </Tooltip>
                        }
                    >
                    <ChevronRightIcon
                            onClick={() => {
                            setShowDeviceList(true);
                            setShowSpaceList(false);
                            setSpaceName(params.row.name);
                            setSpaceIdCondition(params.id)
                        }}
                        style={{ fontSize: 25, cursor: "pointer" }}
                    />
                    </OverlayTrigger>
                );
            },
        },
        {
            field: "is_Shared",
            headerName: "Space Shared",
            width: 150,
            editable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.is_Shared ?
                            <> <CheckCircleSharpIcon style={{ fontSize: 25, color: "#3f51b5" }} /> </>
                            :
                            <>  <CancelSharpIcon style={{ fontSize: 25, color: "#3f51b5" }} /> </>
                        }
                    </>
                )
            }
        },
        {
            field: "userSpaceInfo",
            headerName: "Space Shared Users",
            width: 170,
            editable: false,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                let isSharedSpace = params.row.is_Shared;
                return (<>
                    {isSharedSpace === true ? (<>

                        <NavLink to={`/spaces/spaces-shared-users/${params.id}`} style={{ marginTop: "10px" }}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Space shared user(s)
                                    </Tooltip>
                                }
                            >
                                <ChevronRightIcon
                                style={{ fontSize: 25, cursor: "pointer" }}
                            />
                            </OverlayTrigger>
                        </NavLink>
                    </>) : '-'}

                </>);
            },
        },
    ];
    /* spaces devices list columns */
    const devicesColumns = [
        {
            field: "deviceType",
            headerName: "Image",
            type: "singleSelect",
            editable: false,
            sortable: false,
            filterable: false,
            width: 110,
            valueOptions: ["light", "switch", "fan"],
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <img
                            alt={params.value.icon}
                            style={{ height: "50px", width: "50px" }}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${params.value.icon}`}
                        />
                    </div>
                );
            },
        },
        {
            field: "ownerName",
            headerName: "Name",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            filterable: true,
            sortable: true,
            renderCell: (params) => {
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                Edit device name
                            </Tooltip>
                        }
                    >
                        <span
                            onClick={
                                () => {
                                    setEditParamsRowData(params.row);
                                    setShowEditDevice(true);
                                }}
                            style={{ color: "#3f51b5", cursor: "pointer" }}
                        >
                            {params.row.ownerName}
                        </span>
                    </OverlayTrigger>
                );
            },
        },
        {
            field: "ownerDeviceOnline",
            headerName: "Online",
            type: "boolean",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: true,
            filterable: true,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        justifyContent="center"
                    >
                        <p
                            style={{
                                fontSize: 10,
                                marginTop: "13px",
                                marginRight: "10px",
                            }}
                        >
                            {params.row.ownerDeviceOnline ? "online" : "offline"}
                        </p>
                        <img
                            style={{ width: "20px", height: "13px" }}
                            src={`${process.env.PUBLIC_URL}images/${params.row.ownerDeviceOnline ? "cloudact.png" : "cloud.png"
                                }`}
                        />
                    </Grid>
                );
            },
        },
        {
            field: "on",
            headerName: "On",
            type: "boolean",
            width: 110,
            editable: false,
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.deviceType.name === DEVICE_TYPE_TEMP_SENSOR) {
                    return (<Grid
                        container
                        justifyContent="center"
                        style={{ alignItems: "center", cursor: "pointer" }}
                    >
                        <img src="images/temperature-icon.png" width="20px" height="27px" className="pr-2" />
                        {(params.row.instanceId?.caps?.get_TemperatureControl?.params?.currentTemperature).toFixed(2)}</Grid>
                    );
                }
                else {
                    const imgPath =
                        params.row.instanceId.online &&
                            params.row.instanceId.caps.get_OnOff.params.on
                            ? "images/onbtn.png"
                            : "images/offbtn.png";
                    return (
                        <Grid
                            container
                            justifyContent="center"
                            style={{ alignItems: "center", cursor: "pointer" }}
                        >
                            <img
                                alt={params.row._id}
                                onClick={() => {
                                    sendSocketEventData(params.row);
                                }}
                                style={{ width: "35px", height: "35px" }}
                                src={process.env.PUBLIC_URL + imgPath}
                            />
                        </Grid>
                    );
                }
            },
        },
        {
            field: "deviceInfo",
            headerName: "Info",
            width: 110,
            editable: false,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                Device info
                            </Tooltip>
                        }
                    >
                    <ChevronRightIcon
                        onClick={() => {
                            setShowDeviceInfo(true);
                            setdeviceInfoList(params.row);
                        }}
                        style={{ fontSize: 25, cursor: "pointer" }}
                    />
                    </OverlayTrigger>
                );
            },
        }
    ];
    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            {sharedShowHide && 
                <DevicesShareModal
                ShowHide={sharedShowHide}
                shareModalOpen={shareModalOpen}
                shareModalClose={shareModalClose}
                handleSubmit={handleSubmitSharedDevices}
                handleChangeUsers={handleChangeUsers}
                loading={loading}
                    devicesList={devicesList}
                />}
            {showMultipleDeviceControlModal && (
                <SpaceDevicesMultipleDeviceControl
                    show={showMultipleDeviceControlModal}
                    onHide={handleMulitpleDeviceControlModalClose}
                    spaceIdCondition={spaceIdCondition}
                    defaultValue={defaultValue}
                    validations={Validations}
                    refreshComponent={refreshComponent}
                />)}
            {showDeviceInfo ? (<>
                <DeviceInfo
                    deviceInfoList={deviceInfoList}
                    handleShowDeviceInfo={handleShowDeviceInfo}
                    sendSocketEventData={sendSocketEventData}
                    sharedCondition={true}
                    setDeleteSingleDeviceModalShown={setDeleteSingleDeviceModalShown}
                    setShowEditDevice={setShowEditDevice}
                    setEditParamsRowData={setEditParamsRowData}
                    setShowDeviceInfo={setShowDeviceInfo}
                    socketResponseDateString={socketResponseDateString}
                />
                <DevicesDeleteModal
                    modalShown={deleteSingleDeviceModalShown}
                    onModelClose={onDeleteSingleDeviceModelClose}
                    onDeleteConfirm={onDeleteSingleDeviceConfirm}
                    headerTitle="Delete Device"
                    message={`Are you sure you want to deleted this device?`}
                />

            </>)
                : showEditSpaces ? (
                    <>
                        <EditSpaces
                            setShowEditSpaces={setShowEditSpaces}
                            editParams={editParams}
                            refreshComponent={refreshComponent}
                        />
                    </>
                )
                    :
                    showAddSpaces ? (
                        <>
                            <AddSpaces
                                setShowAddSpaces={setShowAddSpaces}
                                refreshComponent={refreshComponent}
                            />
                        </>
                    )
                        : showEditGroup ? (
                            <>
                                <EditGroupName
                                    setShowEditGroup={setShowEditGroup}
                                    devicesList={devicesList}
                                    refreshComponent={refreshComponent}
                                />
                            </>
                        )
                            : displaySpaces ? (
                                <>
                                    <AddDevicesToSpace
                                        setDisplaySpaces={setDisplaySpaces}
                                        spaceIdCondition={spaceIdCondition}
                                        refreshComponent={refreshComponent}
                                        spaceName={spaceName}
                                        devicesList={devicesList}
                                    />
                                </>
                            ) : showEditDevice ? (
                                <>
                                    <EditDeviceName
                                        setShowEditDevice={setShowEditDevice}
                                        editParamsRowData={editParamsRowData}
                                        refreshComponent={refreshComponent}
                                    />
                                </>
                            ) : (<>
                                {showDeviceList && !showSpaceList ? (
                                    <>
                                        <div className="m-grid__item m-grid__item--fluid m-wrapper">
                                            <div className="m-content">
                                                <div className="m-portlet m-portlet--mobile">
                                                    <div className="m-portlet__head">
                                                        <div className="m-portlet__head-caption">
                                                            <div className="row justify-content-between align-items-center GroupDetails-Block">
                                                                <div className="m-portlet__head-title col-lg-9">
                                                                    <h2>{spaceName ? spaceName : ""}</h2>
                                                                </div>
                                                                <div>
                                                                <OverlayTrigger
                                                                                placement='bottom'
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        Back
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                    <ChevronRightIcon
                                                                        onClick={() => {
                                                                            setShowSpaceList(true)
                                                                        }}
                                                                        style={{ fontSize: 40, cursor: "pointer" }}
                                                                    />
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-portlet__body">
                                                        <div className="column pb-3">
                                                            <div className="row">
                                                                <Grid container xs={12} justify="flex-end">
                                                                <OverlayTrigger
                                                                                placement='bottom'
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        Delete space
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                    <DeleteOutlinedIcon
                                                                        onClick={() => {
                                                                            setDeleteSpaceModalShown(true);
                                                                        }}
                                                                        style={{
                                                                            fontSize: "40px",
                                                                            padding: "8px",
                                                                            color: "#fff",
                                                                            background: "#868aa8",
                                                                            cursor: "pointer",
                                                                            margin: "5px"
                                                                        }}
                                                                    />
                                                                            </OverlayTrigger>
                                                                </Grid>
                                                            </div>
                                                            <div className="row spacesListDetails">
                                                                <div style={{ height: "85vh", width: "100%", margin: "0 auto" }}>
                                                                    {devices && devices.length ? (
                                                                        <DataGrid
                                                                            getRowId={(row) => row._id}
                                                                            checkboxSelection
                                                                            disableSelectionOnClick
                                                                            rows={devices.filter((item) => item?.spaceId?._id === spaceIdCondition)}
                                                                            rowHeight={40}
                                                                                    columns={devicesColumns}
                                                                            components={{
                                                                                Toolbar: DevicesCustomToolbar,
                                                                            }}
                                                                            onSelectionModelChange={(newSelection) => {
                                                                                setSelectedDevicesRows(newSelection);
                                                                            }}
                                                                            selectionModel={selectedDevicesRows}
                                                                        />
                                                                    ) : (
                                                                                    <>
                                                                                        <b style={{ color: "black" }}>No Space Devices Found</b>
                                                                                    </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>) : (<>
                                        <div className="m-grid__item m-grid__item--fluid m-wrapper">
                                            <div className="m-content">
                                                <div className="m-portlet m-portlet--mobile">
                                                    <div className="m-portlet__head">
                                                        <div className="m-portlet__head-caption">
                                                                    <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                                                        <h2>Spaces</h2>
                                                                <CustomBreadCrumb breadCrumData={SpaceBreadCrumb} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="m-portlet__body">
                                                        <div className="row spacesListDetails">
                                                            <Grid xs={12}
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "initial",
                                                                    flexWrap: "nowrap",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    margin: "0 0 10px"
                                                                }}>
                                                                <img
                                                                    style={{ width: "30px", height: "30px" }}
                                                                    src={process.env.PUBLIC_URL + "images/groupicn.png"}
                                                                />
                                                                <h3 style={{ padding: "0 3vh", margin: "0" }}>{groupName ? groupName : ""}</h3>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-end"
                                                                    alignItems="center"
                                                                    xs={12}
                                                                >
                                                                    <OverlayTrigger
                                                                                placement='bottom'
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        Edit group name
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                    <EditIcon
                                                                        onClick={() => {
                                                                            setShowEditGroup(true);
                                                                        }}
                                                                        style={{
                                                                            fontSize: "40px",
                                                                            padding: "8px",
                                                                            color: "#fff",
                                                                            background: "#868aa8",
                                                                            cursor: "pointer",
                                                                            flexDirection: "flex-end",
                                                                        }}
                                                                    />
                                                                    </OverlayTrigger>
                                                                </Grid>

                                                            </Grid>
                                                            <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>
                                                                <DataGrid
                                                                    getRowId={(row) => row._id}
                                                                    checkboxSelection
                                                                    disableSelectionOnClick
                                                                    rows={spaces}
                                                                    rowHeight={40}
                                                                            columns={columns}
                                                                    components={{
                                                                        Toolbar: CustomToolbar,
                                                                    }}
                                                                    onSelectionModelChange={(newSelection) => {
                                                                        setSelectedRows(newSelection);
                                                                    }}
                                                                    selectionModel={selectedRows}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)}
                                <DevicesDeleteModal
                                    modalShown={multipleSpacesdeleteModalShown}
                                    onModelClose={multipleSpacesDeleteModelClose}
                                    onDeleteConfirm={multipleSpacesDeleteConfirm}
                                    headerTitle="Delete Spaces"
                                    message={DELETE_SPACES_WARNING}
                                />
                                <DevicesDeleteModal
                                    modalShown={deleteDeviceInSpaceModalShown}
                                    onModelClose={onDeleteDeviceInSpaceModelClose}
                                    onDeleteConfirm={onDeleteDeviceInSpaceConfirm}
                                    headerTitle="Delete Devices"
                                    message={DELETE_DEVICES_FROM_SPACES_WARNING}
                                />
                                <DevicesDeleteModal
                                    modalShown={deleteSpaceModalShown}
                                    onModelClose={onDeleteSpaceModelClose}
                                    onDeleteConfirm={onDeleteSpaceConfirm}
                                    headerTitle="Delete Space"
                                    message={DELETE_SPACE_WARNING}
                                />
                            </>)}
        </>
    );
}

const mapStateToProps = (state) => ({
    spaces: state.spaces,
    users: state.users,
    devices: state.devices,
});

export default connect(mapStateToProps, {
    getDevicesAction,
    getSpacesAction,
    updateDeviceStatusAction,
    deleteSpaceAction,
    deleteDeviceInSpaceAction,
    shareSpaceDeviceAction,
})(SpacesList);
