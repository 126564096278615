import socketIOClient from "socket.io-client";

class SocketHelper {
  io = null;

  /**
   * Configure socket
   */
  setupSocket = (token) => {
    if (!this.io && token) {
      this.io = socketIOClient(
        `${process.env.REACT_APP_SOCKET_CONNECTION_URL}?Token=${token}`,
        {
          reconnection: true,
        }
      );
      this.socketOnlineVerification();
    }
  };

  /**
   * disconnect socket
   */
  socketDisconnect = () => {
    if (this.io) {
      this.io.disconnect();
      this.io = null;
    }
  };

  /**
   * Send Online status
   */
  socketOnline = () => {
    if (this.io) {
      this.io.emit("socketOnline");
    }
  };

  /**
   * Socket online verification if multiple
   */
  socketOnlineVerification = () => {
    if (this.io) {
      this.io.on("socketOnlineVerify", this.socketOnline);
    }
  };
}

export default new SocketHelper();
