import axios from "axios";
import { result } from "lodash";
import apiurl from "../config/api";
import globalHelper from "../helper/globalHelper";

export function getSpaces() {
  return axios
    .get(apiurl.clientSpacesUrl)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response && error.response.data) {
        globalHelper.message(error.response.data.message.message, "error");
      }
      return error.response.data;
    });
}

export function getDevicesInSpace(spaceInfo) {
  return axios
    .post(apiurl.clientDevicesInSpaceUrl, spaceInfo)
    .then((result) => {
      return result.data.data;
    });
}

export function getShareSpacesDevice(spaceInfo) {
  return axios
    .post(`${apiurl.sharedSpacesDevicesListUrl + "/" + spaceInfo}`)
    .then((result) => {
      return result.data;
    }).catch((error) => {

      return error.response.data;
    });
}

export function getSpaceSharedContactList(contactHint) {
  return axios
    .get(`${apiurl.getSpaceSharedContactHintUrl + "/" + contactHint}`)
    .then((result) => {
      return result.data;
    }).catch((error) => {

      return error.response.data;
    });
}

export function createSpace(spaceInfo) {
  return axios.post(apiurl.clientCreateSpaceUrl, spaceInfo).then((result) => {
    return result.data;
  });
}

export function addDevicesToSpace(spaceInfo) {
  return axios
    .post(apiurl.clientAddDevicesToSpaceUrl, spaceInfo)
    .then((result) => {
      return result.data;
    });
}

export function deleteDeviceInSpace(spaceInfo) {
  return axios
    .patch(apiurl.clientDeleteDeviceInSpaceUrl, spaceInfo)
    .then((result) => {
      return result.data;
    });
}

export function deleteSpace(spaceInfo) {
  return axios
    .delete(apiurl.clientDeleteSpaceUrl + "/" + spaceInfo)
    .then((result) => {
      return result.data;
    });
}

export function sharesSpaceDevices(spaceInfo) {
  return axios
    .post(apiurl.shareSpaceDevicesUrl, spaceInfo)
    .then((result) => {
      return result.data;
    });
}

export function createSpaceShareDevice(spaceInfo) {
  return axios
    .post(apiurl.createSpaceShareDeviceUrl, spaceInfo).then((result) => {
      return result.data;
    })
}

export function spaceSharedWithUser(spaceInfo) {
  return axios
    .get(`${apiurl.clientSharedSpaceWithUserUrl + "/" + spaceInfo}`).then((result) => {
      return result.data;
    })
}

export function sharedSpaceRevocation(spaceInfo) {
  return axios
    .post(apiurl.sharedSpacesRevocationUrl, spaceInfo).then((result) => {
      return result.data;
    })
}

export function spaceSharedWithMe(spaceInfo) {
  return axios
    .get(apiurl.sharedSpaceWithMeUrl, spaceInfo).then((result) => {
      return result.data;
    })
}
