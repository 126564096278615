import axios from "axios";
import apiurl from "../config/api";

export function sendCommand(credentials) {
  return axios.post(apiurl.sendCommands, credentials).then((result) => {
    return result.data.data;
  });
}

export function getCommands() {
  return axios.get(apiurl.getCommands).then((result) => {
    return result.data.data;
  });
}

export function getDeviceTypes() {
  return axios.get(apiurl.getDeviceTypes).then((result) => {
    return result.data.data;
  });
}

export function getDevices() {
  return axios.get(apiurl.getDevices).then((result) => {
    return result.data.data;
  });
}

