import * as settingsService from '../services/SettingsService';
export const SETTINGS_LOADED = 'SETTINGS_LOADED'

export const getSettingAction = () => {
  return (dispatch) => {
    return settingsService.getSettings()
      .then((settings) => {
        dispatch({
          type: SETTINGS_LOADED,
          settings
        })
        return settings;
      })
  }
}
export const updateSettingAction = (data) => {
  return (dispatch) => {
    return settingsService.updateSettings(data)
      .then((settings) => {
        dispatch({
          type: SETTINGS_LOADED,
          settings
        })
        return settings;
      });
  }
}