import * as groupService from "../services/GroupService";
export const GROUP_UPDATED = "GROUP_UPDATED";

export const editGroupAction = (groupInfo) => async dispatch => {
  try {
    const group = await groupService.editGroup(groupInfo);
    dispatch({
        type: GROUP_UPDATED,
        group,
      });
    return group;
  } catch (error) {
    return error;
  }
};

