import { STATUS_LOADED } from "../actions/statusActions";

export function statusReducer(state = null, action) {
  switch (action.type) {
    case STATUS_LOADED:
      return action.status;

    default:
      return state;
  }
}
