import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { spaceShareWithMeAction } from "../../actions/spacesActions";
import {
    Grid,
    Box,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import { NavLink } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import Loader from "../../components/Loader";

const SpacesSharedWithMe = ({ spaceShareWithMeAction }) => {
    const [spaceList, setSpaceList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const localUserData = localStorage.getItem('smi-user');
    const jsonLocalUserData = JSON.parse(localUserData);

    useEffect(() => {
        spaceShareWithMeAction().then((result) => {
            setSpaceList(result.data);
            setIsFetching(false);
        })
    }, [])

    /* breadCrumb */
    const shareSpaceBreadCrumb = [
        {
            title: "Spaces",
            link: "/spaces",
            classData: "",
        },
        {
            title: "Shared Spaces",
            link: "/spaces/spaces-shared-with-me",
            classData: "active_anv_link",
        },
    ];

    /* custom toolbar for spaces shared with me */
    const SpacesSharedWithMeCustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
            </GridToolbarContainer>
        );
    };

    /* space shared by you columns */
    const columns = [
        {
            field: "image",
            headerName: "Image",
            editable: false,
            filterable: false,
            sortable: false,
            width: 110,
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div class="spaceDetailListImage">
                        <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}${params.row.spaceId.image
                                }`}
                        />
                    </div>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            filterable: false,
            renderCell: (params) => {
                return params.row.spaceId.name ? params.row.spaceId.name : "-";
            },
        },
        {
            field: "spaceOwner",
            headerName: "Space Owner",
            width: 150,
            align: "center",
            headerAlign: "center",
            editable: false,
            filterable: false,
            renderCell: (params) => {
                return params.row.spaceOwner ? params.row.spaceOwner : "-";
            },
        },
        {
            field: "spaceInfo",
            headerName: "Info",
            width: 110,
            editable: false,
            align: "center",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <NavLink to={`/spaces/spaces-shared-with-me/spaces-device/${params.row.spaceId._id}`} style={{ marginTop: "10px" }}>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>
                                    Space device(s)
                                </Tooltip>
                            }
                        >
                        <ChevronRightIcon
                            style={{ fontSize: 25, cursor: "pointer" }}
                        />
                        </OverlayTrigger>
                    </NavLink>
                );
            },
        },
    ];
    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                    <div className="m-portlet m-portlet--mobile">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                    <h2>Spaces Shared With Me</h2>
                                    <CustomBreadCrumb breadCrumData={shareSpaceBreadCrumb} />
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="row spacesListDetails">
                                <Grid xs={12}
                                    style={{
                                        display: "flex",
                                        flexDirection: "initial",
                                        flexWrap: "nowrap",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        margin: "0 0 10px"
                                    }}>
                                    <img
                                        style={{ width: "30px", height: "30px" }}
                                        src={process.env.PUBLIC_URL + "images/groupicn.png"}
                                    />
                                    <h3 style={{ padding: "0 3vh", margin: "0" }}>{jsonLocalUserData?.user?.name ? `${jsonLocalUserData?.user?.name}_group` : ""}</h3>
                                </Grid>
                                <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>
                                    <DataGrid
                                        getRowId={(row) => row._id}
                                        disableSelectionOnClick={true}
                                        rows={spaceList}
                                        rowHeight={40}
                                        columns={columns}
                                        components={{
                                            Toolbar: SpacesSharedWithMeCustomToolbar,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect('', {
    spaceShareWithMeAction
})(SpacesSharedWithMe);