import * as yup from "yup";

export const Validations = yup.object({
    deviceType: yup.string().when("basedOn", {
        is: (value) => value ? value == "type" : "",
        then: yup.string().required('device type is a required field'),
    }),
    devices: yup.array().ensure().required(),
});

export const defaultValue = {
    basedOn: 'type',
    deviceType: '',
    devices: [],
    spacesSelectedValue: [],
    deviceStatus: true,
    fanSpeed: 3,
    brightness: 7,
    temperature: 6,
    r: 1,
    g: 1,
    b: 1,
};