import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
    Card,
    Grid,
    List,
    Divider,
    ListItem,
    ListItemText,
    Typography,
    ListItemSecondaryAction,
    Button,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/Edit";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { SketchPicker } from "react-color";
import { DimmingSlider, ToneSlider, SpeedSlider } from "../../containers/devices/Sliders";
import {
    deviceOffline,
    DEVICE_TYPE_FAN,
    DEVICE_TYPE_LIGHT_LWCT,
    DEVICE_TYPE_LIGHT_LW,
    DEVICE_TYPE_LIGHT_LRGB,
    DEVICE_TYPE_LIGHT_LRGBW,
    DEVICE_TYPE_LIGHT_LRGBWCT,
    fanSpeedData,
    FIRMWARE_SETTING_UPDATED_SUCCESSFULLY,
    DEVICE_TYPE_TEMP_SENSOR,
    RESTART_DEVICE_WARNING,
    DEVICE_OFFLINE_WARNING,
    DEVICE_TYPE_SWITCH,
    DEVICE_TYPE_SWITCH_2,
    DEVICE_TYPE_SWITCH_4,
    FIRMWARE_SETTINGS_LOADING_INFO
} from "../../helper/constants";
import {
    updateDeviceFirmwareAction,
    rebootDeviceAction
} from "../../actions/devicesActions";
import DevicesFirmwareSettingsUpdateModal from "../../components/devicesComponents/DeviceFirmwareSettingsUpdate";
import RebootDeviceModal from "../../components/confirm-popup/DeleteConfirm";

const DeviceInfo = ({ handleShowDeviceInfo,
    deviceInfoList, updateDeviceFirmwareAction,
    sendSocketEventData, sharedCondition,
    setDeleteSingleDeviceModalShown,
    setShowEditDevice,
    setEditParamsRowData,
    setShowDeviceInfo,
    updateFirmwareSettingState,
    refreshComponent
}) => {
    const [showFirmwareSettingsModal, setShowFirmwareSettingsModal] = useState(false);
    const [showSwitchOffDelayField, setShowSwitchOffDelayField] = useState(false);
    const [showActivateMemoryField, setShowActivateMemoryField] = useState(false);
    const [firmwareSettingsData, setFirmwareSettingsData] = useState({
        activate_memory: "",
        switch_off_delay: "",
        deviceMac: "",
    });
    const [showBrightnessControl, setShowBrightnessControl] = useState(false);
    const [showTemperatureControl, setShowTemperatureControl] = useState(false);
    const [showRGBControl, setShowRGBControl] = useState(false);
    const [showSpeedControl, setShowSpeedControl] = useState(false);
    const [showRebootDeviceModal, setShowRebootDeviceModal] = useState(false);
    const [showSubmitButtonLoader, setShowSubmitButtonLoader] = useState(false);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
    const [brightnessValue, setBrightnessValue] = useState(7);
    const [temperatureValue, setTemperatureValue] = useState(4);
    const [rgbColor, setRgbColor] = useState();
    const [speedValue, setSpeedValue] = useState();
    const [switchOffDelayFieldLabel, setSwitchOffDelayFieldLabel] = useState('all'); // Send device type check for settings popup

    useEffect(() => {
        if (
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_FAN
        ) {
            setSpeedValue(deviceInfoList?.instanceId?.caps?.get_FanSpeed?.params?.speed ? deviceInfoList?.instanceId?.caps?.get_FanSpeed?.params?.speed : 1);
            setShowSpeedControl(true);
        } else {
            setShowSpeedControl(false);
        }
        if (
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LW ||
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LWCT ||
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LRGBW ||
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LRGBWCT
        ) {
            setBrightnessValue(deviceInfoList?.instanceId?.caps?.get_Brightness?.params?.brightness ? deviceInfoList?.instanceId?.caps?.get_Brightness?.params?.brightness : 7);
            setShowBrightnessControl(true);
        }
        else {
            setShowBrightnessControl(false);
        }
        if (
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LWCT ||
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LRGBWCT
        ) {
            setTemperatureValue(deviceInfoList?.instanceId?.caps?.get_ColorTemperature?.params?.temperature ? deviceInfoList?.instanceId?.caps?.get_ColorTemperature?.params?.temperature : 4)
            setShowTemperatureControl(true);
        } else {
            setShowTemperatureControl(false);
        }
        if (
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LRGB ||
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LRGBW ||
            deviceInfoList?.deviceType.name ===
            DEVICE_TYPE_LIGHT_LRGBWCT
        ) {
            setShowRGBControl(true);
        } else {
            setShowRGBControl(false);
        }
    });

    /* Device Online/Offline Status with Image */
    const isOnline = (deviceStatus) => {
        return (
            <div
                style={{ display: "flex", flexDirection: "row", float: "right" }}
            >
                <img
                    alt={"cloud img"}
                    style={{ width: "35px", height: "27px" }}
                    src={deviceStatus ? `${process.env.PUBLIC_URL}images/cloudact.png` : `${process.env.PUBLIC_URL}images/cloud.png`}
                />
                <h6 style={{ margin: "auto 2vh" }}>{deviceStatus ? "online" : "offline"}</h6>
            </div>
        );
    };

    /* Device On/Off Image */
    const deviceOnOffButton = (deviceStatus) => {
        let deviceOnOffImg;
        if (deviceStatus?.online && deviceStatus?.caps?.get_OnOff?.params?.on) {
            deviceOnOffImg = "images/onbtn.png";
        }
        else {
            deviceOnOffImg = "images/offbtn.png";
        }
        return (
            <>
            <Grid container justifyContent="center" style={{ alignItems: "center", cursor: "pointer" }}
            >
                <img
                    style={deviceInfoList.deviceType.name === DEVICE_TYPE_TEMP_SENSOR ? { width: "50px", height: "50px" } : { width: "200px", height: "200px" }}
                    alt="On/Off"
                    src={process.env.PUBLIC_URL + deviceOnOffImg}
                    onClick={() => {
                        sendSocketEventData(deviceInfoList);
                    }}
                />
            </Grid>
                <Grid container justifyContent="center" style={deviceInfoList.deviceType.name === DEVICE_TYPE_TEMP_SENSOR ? { alignItems: "center", display: "none" } : { alignItems: "center" }}>  {deviceStatus?.online && deviceStatus?.caps?.get_OnOff?.params?.on ? 'Turn OFF' : 'Turn ON'}</Grid>
            </>

        );
    };

    /* Display updated time of sensor value */
    const updatedSensorValueTime = () => {
        let dateText;
        if (deviceInfoList?.instanceId?.updatedAt !== undefined && deviceInfoList?.instanceId?.updatedAt !== "" && deviceInfoList?.instanceId?.updatedAt !== null) {
            let oldTime = deviceInfoList?.instanceId?.updatedAt;
            let currentDate = (new Date()).toString();
            if (oldTime != null) {
                let diffinSec = moment(currentDate).diff(moment(oldTime.toString()), "seconds")
                let diffinMin = moment(currentDate).diff(moment(oldTime.toString()), "minutes")
                let diffinHrs = moment(currentDate).diff(moment(oldTime.toString()), "hours")
                let diffindays = moment(currentDate).diff(moment(oldTime.toString()), "days")
                if (diffindays > 0) {
                    dateText = `${diffindays} day(s) ago`;
                }
                if (diffinHrs > 0 && diffindays <= 0) {
                    dateText = `${diffinHrs} hour(s) ago`;
                }
                if (diffinMin > 0 && diffinHrs <= 0 && diffindays <= 0) {
                    dateText = `${diffinMin} minute(s) ago`;
                }
                if (diffinSec > 0 && diffinMin <= 0 && diffinHrs <= 0 && diffindays <= 0) {
                    dateText = `${diffinSec} second(s) ago`;
                }
                if (diffinSec <= 0 && diffinMin <= 0 && diffinHrs <= 0 && diffindays <= 0) {
                    dateText = "Just now";
                }
            } else {
                dateText = "Just now";
            }
        }
        else {
            dateText = "While ago";
        }
        return <>{dateText}</>
    };

    /* Display Temperature sensor value and image */
    const temperatureSensorValue = (temperatureDeviceData) => {
        return (
            <>
                <Grid container justifyContent="center" style={{ alignItems: "center", fontSize: "35px", fontWeight: "bold" }}>
                    <img src="images/temperature-icon.png" width="47px" height="70px" className="pr-2" />
                    {(temperatureDeviceData.instanceId?.caps?.get_TemperatureControl?.params?.currentTemperature).toFixed(2)}
                    <sup>℃</sup>
                </Grid>
                {
                    temperatureDeviceData.instanceId?.caps?.get_HumidityControl?.params?.currentHumidity !== 0 &&
                    <Grid container justifyContent="center" style={{ alignItems: "center", marginTop: "10px" }}>
                            <p>with {temperatureDeviceData.instanceId?.caps?.get_HumidityControl?.params?.currentHumidity}% humidity</p>
                    </Grid>}
                <Grid container justifyContent="center" style={{ alignItems: "center", marginTop: "10px" }}>
                    <p>{updatedSensorValueTime()}</p>
                </Grid>
            </>
        )
    };

    /* Handle firmware settings button */
    const handleFirmwareSettingsButton = (deviceInfoData) => {
        if (deviceInfoData.instanceId.online === true) {
            if (
                Object.keys(
                    deviceInfoData.instanceId.caps
                ).includes("activate_memory") &&
                Object.keys(
                    deviceInfoData.instanceId.caps
                ).includes("switch_off_delay")
            ) {
                setShowFirmwareSettingsModal(true);
                setShowSwitchOffDelayField(true);
                setShowActivateMemoryField(true);
                setFirmwareSettingsData({
                    activate_memory: deviceInfoData.instanceId.caps.activate_memory,
                    switch_off_delay: deviceInfoData.instanceId.caps.switch_off_delay,
                    deviceMac: deviceInfoData.deviceMacId
                });

                // Set label content
                setSwitchOffDelayFieldLabelContent(deviceInfoData);
            }
            else if (
                Object.keys(
                    deviceInfoData.instanceId.caps
                ).includes("activate_memory")
            ) {
                setShowFirmwareSettingsModal(true);
                setShowSwitchOffDelayField(false);
                setShowActivateMemoryField(true);
                setFirmwareSettingsData({
                    activate_memory: deviceInfoData.instanceId.caps.activate_memory,
                    deviceMac: deviceInfoData.deviceMacId
                });

                // Set label content
                setSwitchOffDelayFieldLabel('all');
            }
            else if (
                Object.keys(
                    deviceInfoData.instanceId.caps
                ).includes("switch_off_delay")
            ) {
                setShowFirmwareSettingsModal(true);
                setShowSwitchOffDelayField(true);
                setShowActivateMemoryField(false);
                setFirmwareSettingsData({
                    switch_off_delay: deviceInfoData.instanceId.caps.switch_off_delay,
                    deviceMac: deviceInfoData.deviceMacId
                });

                // Set label content
                setSwitchOffDelayFieldLabelContent(deviceInfoData);
            }
        }
        else {
            toast.error(deviceOffline, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Common function to set label content
    const setSwitchOffDelayFieldLabelContent = (deviceInfoData) => {
        let deviceType = 'all';
        if (deviceInfoData?.deviceType?.name === DEVICE_TYPE_TEMP_SENSOR) {
            deviceType = DEVICE_TYPE_TEMP_SENSOR;
        } else if (deviceInfoData?.deviceType?.name === DEVICE_TYPE_SWITCH || deviceInfoData?.deviceType?.name === DEVICE_TYPE_SWITCH_2 || deviceInfoData?.deviceType?.name === DEVICE_TYPE_SWITCH_4) {
            deviceType = 'switch';
        }
        setSwitchOffDelayFieldLabel(deviceType);
    };

    /* Handle firmware settings onChange */
    const handleFirmwareSettingsOnChange = (e) => {
        if (e.target.type === "checkbox") {
            setFirmwareSettingsData({
                ...firmwareSettingsData,
                activate_memory: e.target.checked,
            })
        } else {
            setFirmwareSettingsData({
                ...firmwareSettingsData,
                switch_off_delay: e.target.value ? Number(e.target.value) : ""
            })
        }
    };

    /* firmware settings api call */
    const updateFirmwareSettingsApiCall = async (updateFirmwareSettingsReqObj) => {
        setDisableSubmitBtn(true);
        const updateFirmwareSettingRes = await updateDeviceFirmwareAction(updateFirmwareSettingsReqObj);
        if (updateFirmwareSettingRes.status === 200) {
            toast.info(FIRMWARE_SETTINGS_LOADING_INFO, {
                position: "top-center",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                closeButton: false
            });
            setTimeout(() => {
                updateFirmwareSettingState();
                setShowSubmitButtonLoader(false);
                closeFirmwareSettingsModal();
                setDisableSubmitBtn(false);
            }, 10000);
        }
    };

    /* Handle firmware settings submit */
    const handleFirmwareSettingsSubmit = async (e) => {
        setShowSubmitButtonLoader(true);
        e.preventDefault();
        let updateFirmwareSettingsReqObj;
        if (showActivateMemoryField && (deviceInfoList.instanceId.caps.activate_memory === firmwareSettingsData.activate_memory)
            && showSwitchOffDelayField && (deviceInfoList.instanceId.caps.switch_off_delay === firmwareSettingsData.switch_off_delay)) {
            closeFirmwareSettingsModal();
            setShowSubmitButtonLoader(false);
        }
        else if (showActivateMemoryField && (deviceInfoList.instanceId.caps.activate_memory !== firmwareSettingsData.activate_memory)
            && showSwitchOffDelayField && (deviceInfoList.instanceId.caps.switch_off_delay === firmwareSettingsData.switch_off_delay)) {
            updateFirmwareSettingsReqObj = {
                deviceMac: firmwareSettingsData.deviceMac,
                activate_memory: firmwareSettingsData.activate_memory
            };
            updateFirmwareSettingsApiCall(updateFirmwareSettingsReqObj);
        }
        else if (showActivateMemoryField && (deviceInfoList.instanceId.caps.activate_memory === firmwareSettingsData.activate_memory)
            && showSwitchOffDelayField && (deviceInfoList.instanceId.caps.switch_off_delay !== firmwareSettingsData.switch_off_delay)) {
            updateFirmwareSettingsReqObj = {
                deviceMac: firmwareSettingsData.deviceMac,
                switch_off_delay: firmwareSettingsData.switch_off_delay
            };
            updateFirmwareSettingsApiCall(updateFirmwareSettingsReqObj);
        }
        else if (showActivateMemoryField && (deviceInfoList.instanceId.caps.activate_memory !== firmwareSettingsData.activate_memory)
            && showSwitchOffDelayField && (deviceInfoList.instanceId.caps.switch_off_delay !== firmwareSettingsData.switch_off_delay)) {
            updateFirmwareSettingsReqObj = {
                deviceMac: firmwareSettingsData.deviceMac,
                activate_memory: firmwareSettingsData.activate_memory,
                switch_off_delay: firmwareSettingsData.switch_off_delay
            };
            updateFirmwareSettingsApiCall(updateFirmwareSettingsReqObj);
        }
        else if (showActivateMemoryField && (deviceInfoList.instanceId.caps.activate_memory === firmwareSettingsData.activate_memory) &&
            !showSwitchOffDelayField) {
            closeFirmwareSettingsModal();
            setShowSubmitButtonLoader(false);
        }
        else if (!showActivateMemoryField &&
            showSwitchOffDelayField && (deviceInfoList.instanceId.caps.switch_off_delay === firmwareSettingsData.switch_off_delay)) {
            closeFirmwareSettingsModal();
            setShowSubmitButtonLoader(false);
        }
        else if (showActivateMemoryField && (deviceInfoList.instanceId.caps.activate_memory !== firmwareSettingsData.activate_memory) &&
            !showSwitchOffDelayField) {
            updateFirmwareSettingsReqObj = {
                deviceMac: firmwareSettingsData.deviceMac,
                activate_memory: firmwareSettingsData.activate_memory,
            };
            updateFirmwareSettingsApiCall(updateFirmwareSettingsReqObj);
        }
        else if (!showActivateMemoryField &&
            showSwitchOffDelayField && (deviceInfoList.instanceId.caps.switch_off_delay !== firmwareSettingsData.switch_off_delay)) {
            updateFirmwareSettingsReqObj = {
                deviceMac: firmwareSettingsData.deviceMac,
                switch_off_delay: firmwareSettingsData.switch_off_delay
            };
            updateFirmwareSettingsApiCall(updateFirmwareSettingsReqObj);
        }
    };

    /* Handle firmware settings modal close */
    const closeFirmwareSettingsModal = () => {
        if (!showSubmitButtonLoader) {
            setShowFirmwareSettingsModal(false);
        }
    };

    /* handle edit device name */
    const handleEdit = () => {
        setEditParamsRowData(deviceInfoList);
        setShowDeviceInfo(false);
        setShowEditDevice(true)

    };

    const handleRebootDeviceModalClose = () => {
        setShowRebootDeviceModal(false);
    };

    const handleRebootDeviceModal = () => {
        if (deviceInfoList?.instanceId?.online === false) {
            toast.error(DEVICE_OFFLINE_WARNING, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            setShowRebootDeviceModal(true);
        }
    };

    const handleRebootDeviceConfirm = async () => {
        const rebootDeviceReqPayloadObj = {
            deviceMac: deviceInfoList?.deviceMacId
        };
        const rebootDeviceRes = await rebootDeviceAction(rebootDeviceReqPayloadObj);
        if (rebootDeviceRes.status === 200) {
            toast.success(rebootDeviceRes.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        handleRebootDeviceModalClose();
        setShowDeviceInfo(false);
        refreshComponent();
    };

    return (
        <>
            <RebootDeviceModal
                modalShown={showRebootDeviceModal}
                onModelClose={handleRebootDeviceModalClose}
                onDeleteConfirm={handleRebootDeviceConfirm}
                headerTitle="Restart Device"
                message={RESTART_DEVICE_WARNING}
            />
            <DevicesFirmwareSettingsUpdateModal
                showFirmwareSettingsModal={showFirmwareSettingsModal}
                showSwitchOffDelayField={showSwitchOffDelayField}
                showActivateMemoryField={showActivateMemoryField}
                firmwareSettingsData={firmwareSettingsData}
                handleFirmwareSettingsOnChange={handleFirmwareSettingsOnChange}
                handleFirmwareSettingsSubmit={handleFirmwareSettingsSubmit}
                closeFirmwareSettingsModal={closeFirmwareSettingsModal}
                switchOffDelayFieldLabel={switchOffDelayFieldLabel}
                showSubmitButtonLoader={showSubmitButtonLoader}
                disableSubmitBtn={disableSubmitBtn}
            />
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                    <div className="m-portlet m-portlet--mobile">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="row justify-content-between align-items-center GroupDetails-Block">
                                    <div className="m-portlet__head-title col-lg-9">
                                        <div
                                            xs={12}
                                            style={{ display: "flex", flexDirection: "row" }}
                                        >
                                            <h2>
                                                {deviceInfoList?.instanceId?.name}
                                            </h2>
                                        </div>
                                    </div>
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Back
                                            </Tooltip>
                                        }
                                    >
                                    <ChevronRightIcon
                                        onClick={handleShowDeviceInfo}
                                        style={{ fontSize: 40, cursor: "pointer" /* marginLeft: "160px" */ }}
                                    />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body"
                            style={{ maxHeight: "65vh", overflow: "auto" }}
                        >
                            <Grid
                                xs={12}
                                container
                                direction="row"
                                justifyContent="flex-end"
                            >

                                {sharedCondition && <><OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Delete device
                                        </Tooltip>
                                    }
                                ><DeleteOutlinedIcon
                                    onClick={() => {
                                        setDeleteSingleDeviceModalShown(true)
                                    }}
                                    style={{ fontSize: 40, color: "#fff", background: "#868aa8", padding: "8", margin: "0 5", cursor: "pointer" }}
                                    /></OverlayTrigger>
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Edit device name
                                            </Tooltip>
                                        }
                                    >
                                    <EditIcon
                                        onClick={handleEdit}
                                        style={{ fontSize: 40, color: "#fff", background: "#868aa8", padding: "8", cursor: "pointer" }}
                                        />
                                    </OverlayTrigger></>}
                            </Grid>
                            <Grid>
                                {deviceInfoList?.deviceType?.name !== DEVICE_TYPE_TEMP_SENSOR ? deviceOnOffButton(deviceInfoList?.instanceId)
                                    : temperatureSensorValue(deviceInfoList)}
                            </Grid>
                            <Grid
                                style={{ margin: "5vh auto" }}
                                xs={12}
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {showSpeedControl === true && (
                                    <> <h5 style={{ marginBottom: "2vh" }}>Speed</h5>
                                        <Card
                                            style={{
                                                width: "80%",
                                                height: "12vh",
                                                elevation: 3,
                                                borderRadius: "90px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "4vh auto",
                                            }}
                                        >
                                            <Typography style={{ width: "75%" }}>
                                                <SpeedSlider
                                                    min={1}
                                                    max={5}
                                                    value={speedValue}
                                                    defaultValue={deviceInfoList?.instanceId?.caps?.get_FanSpeed?.params?.speed}
                                                    marks={fanSpeedData}
                                                    onChangeCommitted={(event, speed) => {
                                                        sendSocketEventData(deviceInfoList, speed);
                                                    }}
                                                />
                                            </Typography>
                                        </Card>
                                    </>)
                                }
                                {showBrightnessControl === true && (
                                    <>
                                        <h5 style={{ marginBottom: "2vh" }}>
                                            Dimming: {deviceInfoList?.instanceId?.caps?.get_Brightness?.params?.brightness}%
                                        </h5>
                                        <Card
                                            style={{
                                                width: "80%",
                                                height: "12vh",
                                                elevation: 3,
                                                borderRadius: "90px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "4vh auto",
                                            }}
                                        >
                                            <Typography style={{ width: "75%" }}>
                                                <DimmingSlider
                                                    onChangeCommitted={(event, brightness) => {
                                                        setBrightnessValue(brightness);
                                                        sendSocketEventData(deviceInfoList, brightness, deviceInfoList?.instanceId?.caps?.get_ColorTemperature?.params?.temperature, rgbColor);
                                                    }}
                                                    min={1}
                                                    max={100}
                                                    value={deviceInfoList?.instanceId?.caps?.get_Brightness?.params?.brightness}
                                                />
                                            </Typography>
                                        </Card>
                                    </>
                                )}
                                {showTemperatureControl === true && (
                                    <>
                                        <h5 style={{ margin: "2vh auto" }}>Tone</h5>
                                        <Card
                                            style={{
                                                width: "80%",
                                                height: "12vh",
                                                elevation: 3,
                                                borderRadius: "90px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "4vh auto",
                                            }}
                                        >
                                            <Typography style={{ width: "75%" }}>
                                                <ToneSlider
                                                    min={1}
                                                    max={100}
                                                    value={deviceInfoList?.instanceId?.caps?.get_ColorTemperature?.params?.temperature}
                                                    onChangeCommitted={(event, value) => {
                                                        setTemperatureValue(value);
                                                        sendSocketEventData(deviceInfoList, deviceInfoList?.instanceId?.caps?.get_Brightness?.params?.brightness, value, rgbColor);
                                                    }}
                                                />
                                            </Typography>
                                        </Card>{" "}
                                    </>
                                )}
                                {showRGBControl === true && (
                                    <>
                                        <h5 style={{ margin: "3vh auto" }}>Color Picker</h5>
                                        <Typography style={{ width: "24%" }}>
                                            <SketchPicker
                                                color={{ r: deviceInfoList?.instanceId?.caps?.get_ColorSpectrum?.params?.R, g: deviceInfoList?.instanceId?.caps?.get_ColorSpectrum?.params?.G, b: deviceInfoList?.instanceId?.caps?.get_ColorSpectrum?.params?.B }}
                                                defaultValue={{ r: deviceInfoList?.instanceId?.caps?.get_ColorSpectrum?.params?.R, g: deviceInfoList?.instanceId?.caps?.get_ColorSpectrum?.params?.G, b: deviceInfoList?.instanceId?.caps?.get_ColorSpectrum?.params?.B }}
                                                disableAlpha={true}
                                                onChangeComplete={(e) => {
                                                    setRgbColor(e.rgb);
                                                    sendSocketEventData(deviceInfoList, brightnessValue, temperatureValue, e.rgb);
                                                }}
                                            />
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                            <List style={{ width: "100%", maxWidth: 1000 }}>
                                {deviceInfoList?.deviceType?.name === DEVICE_TYPE_TEMP_SENSOR && (
                                    <>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                        Sensor Switch
                                                    </Typography>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <div>{deviceOnOffButton(deviceInfoList?.instanceId)}</div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </>
                                )}
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                Device Status
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <div>{isOnline(deviceInfoList?.instanceId?.online)}</div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                Device ID
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography style={{ fontSize: 15 }}>
                                            {deviceInfoList?.deviceMacId}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                Manufacturer
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography style={{ fontSize: 15 }}>
                                            {deviceInfoList?.manufacturer?.name}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                Helpline
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography style={{ fontSize: 15 }}>
                                            {deviceInfoList?.manufacturer?.supportEmail}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />

                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                Restart Device
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <div>
                                            <Button variant="contained" color="primary" onClick={() => { handleRebootDeviceModal(); }}>
                                                Restart
                                            </Button>
                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {(Object.keys(deviceInfoList.instanceId.caps).includes("activate_memory")
                                    || Object.keys(deviceInfoList.instanceId.caps).includes("switch_off_delay"))
                                    && (
                                        <>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                Update Firmware Settings
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    handleFirmwareSettingsButton(deviceInfoList);
                                                }}
                                            >
                                                Settings
                                            </Button>
                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                        </>
                                    )
                                }
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                                                View Sensor Rule
                                            </Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component={Link}
                                                to={`/sensor-rules/${deviceInfoList._id}`}
                                                className="removeLinkHover"
                                            >
                                                View
                                            </Button>

                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                            <Divider />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect("", {
    updateDeviceFirmwareAction,
    rebootDeviceAction
})(DeviceInfo);
