import * as appUserService from '../services/AppUserService';
import * as userService from '../services/UserService';
export const USER_LOADED = 'USER_LOADED';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';

export const getUserAction = () => {
  return (dispatch) => {
    return appUserService.getUsers().then((users) => {
        dispatch({
          type: USER_LOADED,
          users
        });
        return users;
      })
  }
};

export const getUserDetailsAction = (userId) => {
  return (dispatch) => {
    return appUserService.getUserDetails(userId).then((users) => {
        dispatch({
          type: GET_USER_DETAIL,
          users
        });
        return users;
      })
  }
};

export const deleteUserAccountAction = (userId) => {
  return (dispatch) => {
    return userService.deleteUserAccount(userId).then((users) => {
        dispatch({
          type: DELETE_USER_ACCOUNT,
          users
        });
        return users;
      })
  }
};