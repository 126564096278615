import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { Formik } from "formik";
import { Modal, Form, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getSpaceSharedContactListAction
} from "../../actions/spacesActions";
import { getCountryCodesAction } from "../../actions/authActions";
import { Validations, defaultValue } from "./DevicesShareValidation";

const DevicesShareModal =
  ({ ShowHide, shareModalClose, handleChangeUsers,
    handleSubmit, loading, devicesList,
    getSpaceSharedContactListAction,
    getCountryCodesAction }) => {

    const [contactNoOptions, setContactNoOptions] = useState([]);
    const [countryPrefixDropdownOptions, setCountryPrefixDropdownOptions] = useState([]);

    useEffect(() => {
      callUseEffect();
    }, []);

    const callUseEffect = async () => {
      const getSpaceSharedContactRes = await getSpaceSharedContactListAction(devicesList?.ownerDevice?.user?._id);
      if (getSpaceSharedContactRes.status === 200) {
        let contactNoArr = [];
        getSpaceSharedContactRes.data.map((spaceSharedDetail) => {
          contactNoArr.push({
            label: `${spaceSharedDetail.mobileNo} - ${spaceSharedDetail.name}`,
            value: spaceSharedDetail.mobileNo
          })
        })
        setContactNoOptions(contactNoArr);
      }
      const getCountryCodesRes = await getCountryCodesAction();
        if(getCountryCodesRes.status === 200) {
            let countryCodeArr = [];
            getCountryCodesRes.data.forEach((countryCodeItem)=> {
                countryCodeArr.push({
                    label: `${countryCodeItem.name} (${countryCodeItem.dial_code})`,
                    value: countryCodeItem.dial_code
                });
            })
            setCountryPrefixDropdownOptions(countryCodeArr);
        }
    }

    return (
      <>

        <Formik
          enableReinitialize={true}
          validationSchema={Validations}
          onSubmit={handleSubmit}
          initialValues={defaultValue}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            setFieldTouched,
            errors,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => (
            <Modal
              show={ShowHide}
              backdrop="static"
              onShow={() => (ShowHide ? resetForm() : false)}
            >
              <Modal.Header closeButton
                onClick={shareModalClose}
              >
                <Modal.Title>User's</Modal.Title>
              </Modal.Header>
              <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Row>
                  <Form.Group as={Col} md="6" controlId="countryPrefix">
                      <Form.Label>Country Code <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Select
                        value={values.countryPrefix}
                        onChange={(onChangeCountryPrefix) => {
                          setFieldValue("countryPrefix", onChangeCountryPrefix);
                        }}
                        options={countryPrefixDropdownOptions}
                        isInvalid={touched.countryPrefix && !!errors.countryPrefix}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        {errors.countryPrefix}{" "}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="name">
                      <Form.Label>Mobile No <span style={{ color: 'red' }}>*</span></Form.Label>
                      <CreatableSelect
                        name="mobile_no"
                        isClearable
                        onChange={(mobileNoOnChange) => {
                          setFieldValue("mobile_no", mobileNoOnChange ? mobileNoOnChange.value : mobileNoOnChange);
                          handleChangeUsers(mobileNoOnChange ? mobileNoOnChange.value : mobileNoOnChange);
                        }}
                        options={contactNoOptions}
                        isInvalid={touched.mobile_no && !!errors.mobile_no}
                      />
                      <Form.Control.Feedback type="invalid">
                        {" "}
                        {errors.mobile_no}{" "}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className={`btn btn-primary ${loading
                      ? "m-loader m-loader--right m-loader--light"
                      : ""
                      }`}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={shareModalClose}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>
      </>
    );
}

export default connect('', {
  getSpaceSharedContactListAction,
  getCountryCodesAction
})(DevicesShareModal);