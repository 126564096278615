import React, { useState } from "react";
import { connect } from "react-redux";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Grid,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { editGroupAction } from "../../actions/groupAction";
import { toast } from "react-toastify";
import { GROUP_DETAIL_UPDATED_SUCCESSFULLY, DEVICES_GROUP_UPDATE_WARNING } from "../../helper/constants";

const EditGroupName = ({ editGroupAction, setShowEditGroup, devicesList, refreshComponent }) => {
  const [editGroupName, setEditGroupName] = useState({ groupId: "", name: "" });

  /* editGroup name */
  const handleChangeGroup = (e) => {
    setEditGroupName({
      name: e.target.value,
      groupId: String(devicesList?.ownerDevice?._id)
    });
  };
  const handleSubmitEditGroup = async () => {
    if (editGroupName.groupId === "" || editGroupName.name === "" || devicesList.ownerDevice.name === "") {
      toast.error(DEVICES_GROUP_UPDATE_WARNING, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      const editGroupNameRes = await editGroupAction(editGroupName);
      if (editGroupNameRes) {
        toast.success(GROUP_DETAIL_UPDATED_SUCCESSFULLY, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }
  return (
    <>
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="row">
                  <div className="m-portlet__head-title col-lg-9">
                    <div
                      xs={12}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <h2>Group</h2>
                    </div>
                  </div>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        Back
                      </Tooltip>
                    }
                  >
                  <ChevronRightIcon
                    onClick={
                      () => {
                        setShowEditGroup(false);
                        refreshComponent();
                      }}
                    style={{ fontSize: 40, position: "absolute", right: 40, cursor: "pointer" }}
                  />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  onChange={handleChangeGroup}
                  name="name"
                  label="Group Name"
                  defaultValue={devicesList?.ownerDevice?.name}
                />
                <Button
                  onClick={handleSubmitEditGroup}
                  variant="contained"
                  color="primary"
                  style={{ margin: "3vh auto" }}
                >
                  Update
                </Button>
              </Grid>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect("", {
  editGroupAction
})(EditGroupName);
