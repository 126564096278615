import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import { toast } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LineChart from "../../components/dashboardComponents/LineChart";
import { getDevicesAction } from "../../actions/devicesActions";
import { getLineChartAction } from "../../actions/dashboardAction";
import {
  sensorLogs,
  sensorLogTypeDropDownOptions,
  DEVICE_TYPE_TEMP_SENSOR
} from "../../helper/constants";
import Loader from "../../components/Loader";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";

const Dashboard = ({ getDevicesAction, getLineChartAction }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [shouldFetchOnce, setShouldFetchOnce] = useState(true);
  const [dropDownList, setDropDownList] = useState([]);
  const [logTime, setLogTime] = useState([]);
  const [temperatureTrend, setTemperatureTrend] = useState([]);
  const [sensorLogsFromDate, setSensorLogsFromDate] = useState(addDays(new Date(), -7)); // Set current date minus 7 day
  const [sensorLogsToDate, setSensorLogsToDate] = useState(new Date()); // Set current date
  const [sensorSelect, setSensorSelect] = useState("");
  const [diffInHrs, setDiffInHrs] = useState("");
  const [sensorLogTypeSelect, setSensorLogTypeSelect] = useState(
    [{ label: "Based On Temperature", value: "Temperature" }]
  );

  // Added new state for select default value of sensor input
  const [sensorDefaultValue, setSensorDefaultValue] = useState([]);

  // Added new state for from and to time
  const [sensorLogsFromTime, setSensorLogsFromTime] = useState("");
  const [sensorLogsToTime, setSensorLogsToTime] = useState("");

  /* breadCrumb */
  const DashboardBreadCrumb = [
    {
      title: "Dashboard",
      link: "/dashboard",
      classData: "active_anv_link",
    }
  ];

  useEffect(() => {
    dropDownDevices();
  }, []);

  const dropDownDevices = () => {
    getDevicesAction().then((response) => {
      if (response.ownerDevice && response.ownerDevice.devices.length) {
        let dropDownArr = [];
        response.ownerDevice.devices.forEach((deviceDropDownItem) => {
          if (deviceDropDownItem.deviceType.name === DEVICE_TYPE_TEMP_SENSOR) {
            dropDownArr.push({ label: deviceDropDownItem.instanceId.name, value: deviceDropDownItem.deviceMacId });
          }
        })

        // Set default value of sensor input
        if (dropDownArr.length > 0) {
          setDropDownList(dropDownArr);
          setSensorDefaultValue(dropDownArr[0]);
          setSensorSelect(dropDownArr[0].value);
        }
      }
      setIsFetching(false);
    })
  }

  useEffect(() => {
    if (isFetching === false && shouldFetchOnce) {
      setShouldFetchOnce(false);
      getHistoricalData();
    }
  }, [isFetching]);

  const handleDropDownListChange = async (e) => {
    if (e.value) {
      setSensorSelect(e.value);
      setSensorDefaultValue(e);
    }
  }

  const handleLogTypeChange = async (e) => {
    if (e.value) {
      setSensorLogTypeSelect([{ label: e.label, value: e.value }]);
    }
  }

  /* Sensor logs from date filter */
  const handleSensorLogsFromDateChange = (fromDate) => {
    setSensorLogsFromDate(fromDate)
  };

  /* Sensor logs to date filter */
  const handleSensorLogsToDateChange = (toDate) => {
    setSensorLogsToDate(toDate)
  };

  /* Sensor logs from time filter */
  const handleSensorLogsFromTimeChange = (fromTime) => {
    setSensorLogsFromTime(fromTime);
  }

  /* Sensor logs to time filter */
  const handleSensorLogsToTimeChange = (toTime) => {
    setSensorLogsToTime(toTime);
  }

  // check difference in hrs for time selection
  const getDifferenceInHrs = (fTime, tTime) => {
    if (sensorLogsFromTime !== "" && sensorLogsFromTime !== null && sensorLogsToTime !== "" && sensorLogsToTime !== null) {
      const start = moment(sensorLogsFromTime);
      const end = moment(sensorLogsToTime)
      const difference = end.diff(start, 'hours', true);
      setDiffInHrs(difference.toFixed(0));
    } else {
      setDiffInHrs('');
    }
  }

  const getHistoricalData = async () => {
    if (sensorSelect === "") {
      commonToasterError(sensorLogs.sensorNotSelected);
    }
    else if (sensorLogsFromDate === "") {
      commonToasterError(sensorLogs.fromDateNotSelected);
    }
    else if (sensorLogsToDate === "") {
      commonToasterError(sensorLogs.toDateNotSelected);
    }
    else if (moment(sensorLogsFromDate).format('YYYY-MM-DD') >= moment(sensorLogsToDate).format('YYYY-MM-DD')) {
      commonToasterError(sensorLogs.invalidDateFilterSelection);
    }
    else if ((moment(sensorLogsFromDate).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) || (moment(sensorLogsToDate).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD'))) {
      commonToasterError(sensorLogs.dateGreaterThanCurrentDate);
    }

    // Validation for from and to time if found any one of blank or not properly selected
    else if ((sensorLogsFromTime !== "" && sensorLogsFromTime !== null) && (sensorLogsToTime === "" || sensorLogsToTime === null)) {
      commonToasterError(sensorLogs.eitherFromOrToTimeNotSelected);
    }
    else if ((sensorLogsToTime !== "" && sensorLogsToTime !== null) && (sensorLogsFromTime === "" || sensorLogsFromTime === null)) {
      commonToasterError(sensorLogs.eitherFromOrToTimeNotSelected);
    }
    else if ((sensorLogsFromTime !== "" && sensorLogsFromTime !== null) && (sensorLogsToTime !== "" && sensorLogsToTime !== null && Date.parse(sensorLogsToTime) <= Date.parse(sensorLogsFromTime))) {
      commonToasterError(sensorLogs.toTimeGreaterThanFromTime);
    }
    else {
      setIsFetching(true);
      let dateArray = [];
      let tempArray = [];
      let reqObj = {
        sensor: sensorSelect,
        logType: sensorLogTypeSelect,
        fromDate: moment(new Date(sensorLogsFromDate)).format('YYYY-MM-DD'),
        toDate: moment(new Date(sensorLogsToDate)).format('YYYY-MM-DD'),
      };

      // Added fromTime and toTime if they found
      if (sensorLogsFromTime !== "" && sensorLogsFromTime !== null) {
        reqObj.fromTime = moment(sensorLogsFromTime).utc().format("HH:mm:ss");
      }
      if (sensorLogsToTime !== "" && sensorLogsToTime !== null) {
        reqObj.toTime = moment(sensorLogsToTime).utc().format("HH:mm:ss");
      }
      getDifferenceInHrs(sensorLogsFromTime, sensorLogsToTime)
      const chartResponse = await getLineChartAction(reqObj);
      if ((chartResponse.status === 200) && (chartResponse.data.length)) {
        chartResponse.data.forEach((ChartData, index) => {
          dateArray.push(ChartData?.dayDate)
          tempArray.push(ChartData.sensorData.toFixed(2))
        })
        setLogTime(dateArray);
        setTemperatureTrend(tempArray);
        setIsFetching(false);
      }
      else {
        commonToasterError(sensorLogs.noHistoricalDataFound);
        setLogTime([]);
        setTemperatureTrend([]);
        setIsFetching(false);
      }
    }
  }

  // Common function for toaster message
  const commonToasterError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      {isFetching && <Loader loading={isFetching} />}
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                  <h2>Dashboard</h2>
                  <CustomBreadCrumb breadCrumData={DashboardBreadCrumb} />
                </div>
              </div>
            </div>
            <div className="ml-3 dashboard-sensor--select">
              {isFetching === false && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="3" style={{ marginTop: "20px" }} controlId="temperatureSensorId">
                      <Form.Label>Sensor:</Form.Label>
                      <Select placeholder="Select Sensor" defaultValue={sensorDefaultValue} value={sensorDefaultValue} options={dropDownList} onChange={handleDropDownListChange} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" style={{ marginTop: "20px" }} controlId="temperatureSensorId">
                      <Form.Label>Show Data Based On:</Form.Label>
                      <Select placeholder="Select Type" defaultValue={sensorLogTypeSelect} options={sensorLogTypeDropDownOptions} onChange={handleLogTypeChange} />
                    </Form.Group>
                    {/* setting filter */}
                    <Form.Group as={Col} md="2" style={{ marginTop: "20px" }} controlId="sensorLogsFromDate">
                      <Form.Label>From Date:</Form.Label>
                      <DatePicker
                        className="form-control m-input"
                        selected={sensorLogsFromDate !== "" ? new Date(sensorLogsFromDate) : ""}
                        onChange={handleSensorLogsFromDateChange}
                        placeholderText="From Date"
                        dateFormat="MM/dd/yyyy"
                        // minDate={addDays(new Date(), -30)}
                        minDate={addDays(new Date(sensorLogsToDate), -30)}
                        maxDate={new Date(sensorLogsToDate)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="2" style={{ marginTop: "20px" }} controlId="sensorLogsToDate">
                      <Form.Label>To Date:</Form.Label>
                      <DatePicker
                        className="form-control m-input"
                        selected={sensorLogsToDate !== "" ? new Date(sensorLogsToDate) : ""}
                        onChange={handleSensorLogsToDateChange}
                        placeholderText="To Date"
                        dateFormat="MM/dd/yyyy"
                        minDate={sensorLogsFromDate}
                        maxDate={addDays(new Date(sensorLogsFromDate), 30)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="1" style={{ marginTop: "20px" }} controlId="sensorLogsFromTime">
                      <Form.Label>From Time:</Form.Label>
                      <DatePicker
                        className="form-control m-input"
                        selected={sensorLogsFromTime !== "" ? sensorLogsFromTime : ""}
                        onChange={handleSensorLogsFromTimeChange}
                        placeholderText="From Time"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        //onKeyDown={(e) => e.preventDefault()}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Form.Group>
                    {/*  </Form.Row>
                  <Form.Row> */}
                    <Form.Group as={Col} md="1" style={{ marginTop: "20px" }} controlId="sensorLogsToTime">
                      <Form.Label>To Time:</Form.Label>
                      <DatePicker
                        className="form-control m-input"
                        selected={sensorLogsToTime !== "" ? sensorLogsToTime : ""}
                        onChange={handleSensorLogsToTimeChange}
                        placeholderText="To Time"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        //onKeyDown={(e) => e.preventDefault()}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Form.Group>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>
                          View historical chart
                        </Tooltip>
                      }
                    >
                      <button
                        type="submit"
                        className="btn btn-primary px-10"
                        style={{ maxHeight: "37px" }}
                        onClick={getHistoricalData}
                      >
                        Submit
                      </button>
                    </OverlayTrigger>
                  </Form.Row>
                  {/* <Form.Row>
                    
                  </Form.Row> */}
                </>
              )}
            </div>
            <hr />
            <div className="m-content" style={{ marginTop: "-30px" }}>
              <div className="row" style={{ backgroundColor: "white" }}>
                <div
                  style={{
                    height: "85vh",
                    width: "100%",
                    margin: "0 auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {logTime && logTime.length ? (
                    <div style={{ height: "85vh", width: "100%", margin: "0 auto" }}>
                      <LineChart xAxis={logTime} yAxis={temperatureTrend} timeRange={diffInHrs} />
                    </div>
                  ) : (
                    <>
                      <b style={{ color: "black" }}>No Historical Found</b>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getDevicesAction,
  getLineChartAction
})(Dashboard);