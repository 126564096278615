import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addDevicesToSpaceAction } from "../../actions/spacesActions";
import { SELECT_DEVICES_WARNING } from "../../helper/constants";

const AddDevicesToSpace = ({
  setDisplaySpaces,
  spaceName,
  devicesList,
  spaceIdCondition,
  addDevicesToSpaceAction,
  refreshComponent
}) => {
  const [checkedValues, setCheckedValues] = useState([]);
  /* checked devices handle event */
  const handleCheck = (macId) => {
    let checkedData = checkedValues.includes(macId)
      ? checkedValues.filter((c) => c !== macId)
      : [...checkedValues, macId];
    setCheckedValues(checkedData);
  };

  /* add devices to space submit event */
  const handleUpdate = () => {
    if (checkedValues.length > 0) {
      addDevicesToSpaceAction({
        spaceId: spaceIdCondition,
        deviceMacIds: '"' + checkedValues + '"',

      }).then((data) => {
        if (data.status === 200) {
          toast.success(data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisplaySpaces(false);
          refreshComponent();
        }
      });
    }
    else {
      toast.error(SELECT_DEVICES_WARNING, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="row">
                  <div className="m-portlet__head-title col-lg-9">
                    <h2>To {spaceName}</h2>
                  </div>
                  <div className="col-lg-3">
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>
                          Back
                        </Tooltip>
                      }
                    >
                    <ChevronRightIcon
                      onClick={() => {
                        setDisplaySpaces(false);
                      }}
                      style={{ fontSize: 40, float: "right", cursor: "pointer" }}
                    />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <div className="column pb-3">
                {devicesList.ownerDevice &&
                  devicesList.ownerDevice.devices.length &&
                  devicesList.ownerDevice.devices.map((item) => {
                    if (!item.spaceId) {
                      return (
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              color="primary"
                              onChange={() => handleCheck(item.deviceMacId)}
                              checked={checkedValues.includes(item.deviceMacId)}
                            />
                          }
                          label={item.instanceId.name}
                          labelPlacement="start"
                        />
                      );
                    }
                  })}
                <Button
                  style={{ margin: "5vh" }}
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  addDevicesToSpaceAction
})(AddDevicesToSpace);

