import axios from "axios";
import apiurl from "../config/api";

export function getLineChart(reqObj) {
    return axios
      .post(`${apiurl.clientLineChartUrl}`, reqObj)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return error;
      });
  }