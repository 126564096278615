import * as yup from "yup";
import moment from "moment"; // Added new import
import { RULE_DEVICE_TYPE_DEFAULT_VALUE } from "../../helper/constants";

export const Validations = yup.object({
    ruleName: yup.string().required(),
    temperatureSensorId: yup.string().required(),
    //set_rule_from: yup.string().required("range is a required field").matches(/^(\d{1,2})(?:\.\d{1,2})?$/, "range must be less than or equal to 2 digits before and after decimal point"),
    //set_rule_to: yup.string().required("range is a required field").matches(/^(\d{1,2})(?:\.\d{1,2})?$/, "range must be less than or equal to 2 digits before and after decimal point"),
    set_rule_from: yup.string().required("range is a required field").matches(/^(\d{1,2})(?:\.\d{1,2})?$/, "range must be less than or equal to 2 digits"),
    set_rule_to: yup.string().required("range is a required field").matches(/^(\d{1,2})(?:\.\d{1,2})?$/, "range must be less than or equal to 2 digits"),
    ruleDelay: yup.number().min(1, "execution delay must be greater than or equal to 1").max(60, "execution delay must be less than or equal to 60").required("execution delay is a required field"),
    devices: yup.mixed().when("sendNotification", {
        is: (value) => value ? value === false : true,
        then: yup.array().ensure().required(),
    })
});

export const defaultValue = {
    ruleName: '',
    temperatureSensorId: [],
    rule: RULE_DEVICE_TYPE_DEFAULT_VALUE,
    set_rule_from: 1,
    set_rule_to: 99,
    ruleDelay: 10,
    devices: [],
    sendNotification: false,
    deviceStatus: false,
    fanSpeed: 0,
    from_time: '',
    to_time: '',
    days: []
};