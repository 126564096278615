import React from 'react';
import { FadeLoader } from 'react-spinners';
import './Loader.css';

const Loader = ({ loading }) => {

    return (
        <div className='spin-loader'>
            <FadeLoader
                css={
                    `position: relative;
                    display: block;
                    margin: 0 auto;
                    top: 40vh;`
                }
                sizeUnit={"px"}
                size={60}
                color={'#123abc'}
                loading={loading}
            />
        </div>
    );
};

export default Loader;