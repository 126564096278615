import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
    Grid,
    Box,
} from "@material-ui/core";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import {
    DeleteOutlineOutlined as Delete,
} from "@material-ui/icons";
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import RepeatIcon from '@material-ui/icons/Repeat';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
    getDevicesAction,
    getScheduledDevicesAction,
    getOneSchedulerAction,
    deleteScheduledDeviceAction,
    ActivateSchedulerAction
} from "../../actions/devicesActions";
import {
    getSpacesAction,
} from "../../actions/spacesActions";
import Loader from "../../components/Loader";
import {
    DELETE_SCHEDULER_WARNING
} from "../../helper/constants";
import DeleteConfirm from "../../components/confirm-popup/DeleteConfirm";
import AddEditSchedulerModal from "../../components/devicesComponents/AddEditSchedulerModal";
import { defaultValue, Validations } from "./SchedulerFormValidations";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import { utcToLocalDateFormat } from '../../helper/dateFormat';

const SchedulerList = ({ getDevicesAction, getSpacesAction, getScheduledDevicesAction, getOneSchedulerAction, ActivateSchedulerAction, deleteScheduledDeviceAction }) => {


    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [deleteSchedulerModalShow, setDeleteSchedulerModalShow] = useState(false);
    const [showAddSchedulerModal, setShowAddSchedulerModal] = useState(false);
    const [showEditSchedulerModal, setShowEditSchedulerModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [schedulerListData, setSchedulerListData] = useState([]);
    const [editSchedulerData, setEditSchedulerDataObj] = useState(defaultValue);
    const [schedulerId, setSchedulerId] = useState("");

    /* breadCrumb */
    const Scheduler = [
        {
            title: "Scheduler List",
            link: "/scheduler-list",
            classData: "active_anv_link",
        }
    ];

    useEffect(() => {
        setLoading(true);
        getDevicesAction();
        getSpacesAction();
        getScheduledDevicesAction().then((schedulerListRes) => {
            setSchedulerListData(schedulerListRes.data);
            setLoading(false);
            setIsFetching(false);
        });
    }, []);

    /* handle refresh scheduler list component */
    const refreshSchedulerList = () => {
        setIsFetching(true);
        setLoading(true);
        getDevicesAction();
        getSpacesAction();
        getScheduledDevicesAction().then((schedulerListRes) => {
            setSchedulerListData(schedulerListRes.data);
            setLoading(false);
            setIsFetching(false);
        });
    }

    /* handle delete scheduler modal close */
    const handleDeleteSchedulerModalClose = () => {
        setDeleteSchedulerModalShow(false);
    }

    /* handle delete scheduler confirm payload */
    const handleDeleteSchedulerConfirm = async () => {
        var schedulerDeviceIdArray = [];
        schedulerListData.forEach(async (schedulerDataItem) => {
            if (selectedRows.includes(schedulerDataItem._id)) {
                schedulerDeviceIdArray.push(
                    schedulerDataItem._id
                );
            }
        });
        const deleteReqPayloadObj = {
            scheduledDeviceId: schedulerDeviceIdArray,
        };
        const deleteSchedulerResponse =
            await deleteScheduledDeviceAction(deleteReqPayloadObj);
        if (deleteSchedulerResponse.status === 200) {
            refreshSchedulerList();
            toast.success(deleteSchedulerResponse.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSelectedRows([]);
        }
    }

    /* handle on delete scheduler confirm */
    const onDeleteSchedulerConfirm = () => {
        handleDeleteSchedulerConfirm();
        handleDeleteSchedulerModalClose();
    }

    /* handle add scheduler modal close */
    const handleAddSchedulerModalClose = () => {
        setShowAddSchedulerModal(false);
        toast.dismiss();
    }

    const handleEditSchedulerModalOpen = async (paramsRow) => {
        const getSchedulerData = await getOneSchedulerAction(paramsRow._id);
        setSchedulerId(paramsRow._id);
        if (getSchedulerData.status === 200) {
            let timeToDateString = getSchedulerData.data.schedule_time;
            let convertUTCToISTFromDate = moment.utc(getSchedulerData.data.scheduleUTC).toDate();
            convertUTCToISTFromDate = moment(convertUTCToISTFromDate).format('YYYY-MM-DD');
            const newDateString = new Date();
            if ((getSchedulerData.data.scheduler_repeat_options === "Daily") || (getSchedulerData.data.scheduler_repeat_options === "Weekly")) {
                newDateString.setHours(timeToDateString.split(":")[0], timeToDateString.split(":")[1], timeToDateString.split(":")[2]);
            }
            let editSchedulerData = {
                schedulerName: getSchedulerData.data.schedulerName,
                schedulerType: getSchedulerData.data.schedulerType,
                deviceDetailArr: getSchedulerData.data.deviceDetailArr,
                deviceType: getSchedulerData.data.schedulerType === "type" ?
                    { label: getSchedulerData.data.deviceType, value: getSchedulerData.data.deviceType } : defaultValue.deviceType,
                deviceMacId: getSchedulerData.data.deviceMacId,
                spacesSelectedValue: getSchedulerData.data.spacesSelectedValue,
                scheduler_repeat_options: {
                    label: getSchedulerData.data.scheduler_repeat_options,
                    value: getSchedulerData.data.scheduler_repeat_options
                },
                last_execution_on: getSchedulerData.data.last_execution_on,
                schedule_date: getSchedulerData.data.scheduler_repeat_options === "Once" ?
                    new Date(getSchedulerData.data.schedule_date) : defaultValue.schedule_date,
                schedule_fromDate: getSchedulerData.data.scheduler_repeat_options === "Once" ?
                    defaultValue.schedule_date : new Date(convertUTCToISTFromDate),
                schedule_time: getSchedulerData.data.scheduler_repeat_options === "Once" ?
                    defaultValue.schedule_time : newDateString,
                end_repeat: getSchedulerData.data.scheduler_repeat_options === "Once" ?
                    defaultValue.end_repeat : getSchedulerData.data.end_repeat,
                end_repeat_occurrence: getSchedulerData.data.end_repeat === "occurrence" ?
                    getSchedulerData.data.end_repeat_occurrence : defaultValue.end_repeat_occurrence,
                end_repeat_date: getSchedulerData.data.end_repeat === "after" ?
                    new Date(getSchedulerData.data.end_repeat_date) : defaultValue.end_repeat_date,
                repeat_in: getSchedulerData.data.scheduler_repeat_options === "Once" ?
                    defaultValue.repeat_in : getSchedulerData.data.repeat_in,
                daysOfWeekValue: getSchedulerData.data.scheduler_repeat_options === "Weekly" ?
                    getSchedulerData.data.daysOfWeekValue : defaultValue.daysOfWeek,
                deviceStatus: getSchedulerData.data.deviceStatus,
                temperature: getSchedulerData.data.temperature === "N/A" ?
                    defaultValue.temperature : getSchedulerData.data.temperature,
                fanSpeed: getSchedulerData.data.fanSpeed === "N/A" ?
                    defaultValue.fanSpeed : getSchedulerData.data.fanSpeed,
                brightness: getSchedulerData.data.brightness === "N/A" ?
                    defaultValue.brightness : getSchedulerData.data.brightness,
                R: getSchedulerData.data.R === "N/A" ?
                    defaultValue.R : getSchedulerData.data.R,
                G: getSchedulerData.data.G === "N/A" ?
                    defaultValue.G : getSchedulerData.data.G,
                B: getSchedulerData.data.B === "N/A" ?
                    defaultValue.B : getSchedulerData.data.B,

            };
            setEditSchedulerDataObj(editSchedulerData);
        }
        setShowEditSchedulerModal(true);
    }

    /* handle edit scheduler modal close */
    const handleEditSchedulerModalClose = () => {
        setShowEditSchedulerModal(false);
    }

    /* handle enable/disable scheduler */
    const handleActivateScheduler = async (e, row) => {
        const payloadObj = {
            activation: e.target.checked
        };
        const activateSchedulerObj = {
            id: row._id, data: payloadObj
        };
        const activateSchedulerResponse = await ActivateSchedulerAction(activateSchedulerObj);
        if (activateSchedulerResponse.status === 200) {
            toast.success(activateSchedulerResponse.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refreshSchedulerList();
        }
    }

    const handleLastExecutedOnColumn = (params) => {
        if (params.row.last_execution_on === undefined ? "-" : params.row.last_execution_on === "N/A")
        return "-";
        else {
            const convertDateFormat = new Date(params.row.last_execution_on).toISOString();
            return utcToLocalDateFormat(convertDateFormat);
        }
    };

    /* custom toolbar for scheduler */
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarFilterButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    {selectedRows.length > 0 ?
                        (
                            <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                        Delete scheduler(s)
                                    </Tooltip>
                                }
                            >
                                <Delete
                                    onClick={() => {
                                        setDeleteSchedulerModalShow(true);
                                    }}
                                    style={{ fontSize: 40, color: "#fff", background: "#868aa8", padding: "8", margin: "0 5", cursor: "pointer" }}
                                />
                            </OverlayTrigger>) :
                        (<OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>
                                Add scheduler
                                </Tooltip>
                            }
                        >
                        <AddIcon
                                onClick={() => {
                                    setShowAddSchedulerModal(true);
                                }}
                            style={{
                                fontSize: "40px",
                                padding: "8px",
                                color: "#fff",
                                background: "#868aa8",
                                cursor: "pointer",
                            }}
                        />
                        </OverlayTrigger>)
                    }
                </Grid>
            </GridToolbarContainer>
        );
    };
    const columns = [
        {
            field: "schedulerName",
            headerName: "Name",
            width: 250,
            align: "left",
            headerAlign: "center",
            editable: false,
            sortable: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.scheduler_repeat_options === "Once" ?
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Repeat once
                                    </Tooltip>
                                }
                            >
                                <RepeatOneIcon style={{ paddingLeft: "5px", fontSize: 30, cursor: "pointer" }} />
                            </OverlayTrigger> :
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        {(params?.row?.scheduler_repeat_options === "Daily" || params?.row?.scheduler_repeat_options === "Weekly") &&
                                            (params?.row?.repeat_in > 1) ?
                                            `Repeat every ${params?.row?.repeat_in} ${params?.row?.scheduler_repeat_options === "Daily" ? "day(s)" : "week(s)"}`
                                            : `Repeat ${params?.row?.scheduler_repeat_options === "Daily" ? "daily" : "weekly"}`}
                                    </Tooltip>
                                }
                            >
                                <RepeatIcon style={{ paddingLeft: "5px", fontSize: 30, cursor: "pointer" }} />
                            </OverlayTrigger>}
                        &nbsp;&nbsp;
                        {params?.row?.schedulerName}
                    </>
                );
            },
        },
        {
            field: "schedule_date",
            headerName: "Occurrence",
            width: 160,
            editable: false,
            align: "center",
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        {moment(params?.row?.schedule_date).isValid()
                            ? utcToLocalDateFormat(params?.row?.schedule_date) 
                            : params?.row?.schedule_date}
                    </>
                )
            }
        },
        {
            field: "last_execution_on",
            headerName: "Last Executed On",
            width: 170,
            editable: false,
            align: "center",
            headerAlign: "center",
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        {handleLastExecutedOnColumn(params)}
                    </>
                )
            }
        },
        {
            field: "scheduleTimer",
            headerName: "Status",
            width: 150,
            editable: false,
            sortable: true,
            align: "center",
            headerAlign: "center",
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.scheduler_active === false ? "Disabled" :
                                params.row.scheduleTimer === true
                                    ? "In progress"
                                    : "Completed"
                        }
                    </>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 80,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>
                                    Edit scheduler
                                </Tooltip>
                            }
                        >
                            <EditIcon
                                onClick={
                                    () => {
                                        handleEditSchedulerModalOpen(params.row);
                                    }}
                                style={{ fontSize: 20, color: "#3f51b5", cursor: "pointer" }}
                            />
                        </OverlayTrigger>
                    </>
                );
            },
        },
        {
            field: "scheduler_active",
            headerName: "Enable/Disable",
            width: 170,
            align: "center",
            headerAlign: "center",
            editable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        <label className="switch">
                            <input
                                name="deviceStatus"
                                type="checkbox"
                                checked={params.row.scheduler_active}
                                onChange={(e) => {
                                    handleActivateScheduler(e, params.row);
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </>

                )
            },
        },
        {
            field: "created_at",
            headerName: "Created At",
            width: 160,
            editable: false,
            filterable: false,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        {utcToLocalDateFormat(params.row.createdAt)}
                    </>
                )
            }
        },
        {
            field: "updated_at",
            headerName: "Modified At",
            width: 160,
            editable: false,
            filterable: false,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        {utcToLocalDateFormat(params.row.updatedAt)}
                    </>
                )
            }
        },
    ];

    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            {deleteSchedulerModalShow &&
                <DeleteConfirm
                    modalShown={deleteSchedulerModalShow}
                    onModelClose={handleDeleteSchedulerModalClose}
                    onDeleteConfirm={onDeleteSchedulerConfirm}
                    headerTitle="Delete Scheduler(s)"
                    message={DELETE_SCHEDULER_WARNING}
                />}
            {showAddSchedulerModal &&
                <AddEditSchedulerModal
                show={showAddSchedulerModal}
                onHide={handleAddSchedulerModalClose}
                defaultValue={defaultValue}
                validations={Validations}
                refreshComponent={refreshSchedulerList}
                />}
            {showEditSchedulerModal &&
                <AddEditSchedulerModal
                    show={showEditSchedulerModal}
                    onHide={handleEditSchedulerModalClose}
                    defaultValue={editSchedulerData}
                    validations={Validations}
                    refreshComponent={refreshSchedulerList}
                editSchedulerModal={true}
                schedulerId={schedulerId}
                />}
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                    <div className="m-portlet m-portlet--mobile">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                    <h2>Scheduler</h2>
                                <CustomBreadCrumb breadCrumData={Scheduler} />
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="row spacesListDetails">
                                <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>
                                    <DataGrid
                                        getRowId={(row) => row._id}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        rows={schedulerListData}
                                        rowHeight={40}
                                        columns={columns}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        onSelectionModelChange={(newSelection) => {
                                            setSelectedRows(newSelection);
                                        }}
                                        selectionModel={selectedRows}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect('', {
    getDevicesAction,
    getSpacesAction,
    getOneSchedulerAction,
    getScheduledDevicesAction,
    ActivateSchedulerAction,
    deleteScheduledDeviceAction
})(SchedulerList);