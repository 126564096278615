import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class SideNavLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {}
    };
  }

  handleToastHide = () => {
    toast.dismiss();
  }

  render() {
    const { routes } = this.props;



    return (
      <div
        id="m_aside_left"
        className="m-grid__item	m-aside-left  m-aside-left--skin-dark "
      >
        <div
          id="m_ver_menu"
          className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
          data-menu-vertical="true"
          data-menu-scrollable="false"
          data-menu-dropdown-timeout="500"
        >
          <ul className="m-menu__nav">

            {routes.map((route, idx) => {
              let activeLink = "";
              const navArr = this.props.history.location.pathname.split("/");
              const curNavArr = route.path.split("/");
              if (navArr.includes(curNavArr[1])) {
                activeLink = "m-menu__item--open";
              }
              if (route.isSidenav && !route.isSubMenu) {
                return (
                  <li
                    className={`m-menu__item ${activeLink}`}
                    aria-haspopup="true"
                    key={idx}
                  >
                    <Link to={route.path} className="m-menu__link ">
                      <i className={`m-menu__link-icon ${route.icon}`}></i>
                      <span className="m-menu__link-title">
                        <span className="m-menu__link-wrap">
                          <span className="m-menu__link-text">
                            {route.name} {this.handleToastHide()}
                          </span>
                        </span>
                      </span>
                    </Link>
                  </li>
                );
              } else if (route.isSubMenu) {
                return (
                  <li key={idx}
                    className="m-menu__item  m-menu__item--submenu"
                    aria-haspopup="true"
                    data-menu-submenu-toggle="hover">
                    <a href="#" className="m-menu__link m-menu__toggle">
                      <i className="m-menu__link-icon flaticon-layers"></i>
                      <span className="m-menu__link-text">
                        {route.name}
                      </span>
                      <i className="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div className="m-menu__submenu">
                      <span className="m-menu__arrow"></span>
                      <ul className="m-menu__subnav">
                        <li className="m-menu__item  m-menu__item--parent" aria-haspopup="true" >
                          <a href="#" className="m-menu__link ">
                            <span className="m-menu__link-text">
                              {route.name}
                            </span>
                          </a>
                        </li>
                        <li className="m-menu__item " aria-haspopup="true" >
                          <Link to="/spaces" className="m-menu__link ">
                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                              <span></span>
                            </i>
                            <span className="m-menu__link-text">
                              My Spaces
                            </span>
                          </Link>
                        </li>
                        <li className="m-menu__item " aria-haspopup="true" >
                          <Link to="/spaces/spaces-shared-with-me" className="m-menu__link ">
                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                              <span></span>
                            </i>
                            <span className="m-menu__link-text">
                              Spaces Shared With Me
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                );
              } else {
                return "";
              }
            })}
          </ul>
        </div>
      </div>
    );
  }
}
export default SideNavLayout;
