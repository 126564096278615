export const serverUrl = process.env.REACT_APP_SERVER_URL || "";

const baseUrl = process.env.REACT_APP_SERVER_URL || "";

export const clientBaseUrl = process.env.REACT_APP_SERVER_URL || "";

// export const clientBaseV2Url = http://localhost:3005/v1/api/ || "";

export default {
  loginUrl: `${baseUrl}auth/login`,
  getCountryCodes: `${baseUrl}auth/get-country-codes`,
  createClient: `${baseUrl}oAuthClient/create`,
  getClient: `${baseUrl}oAuthClient`,
  getAuthClient: `${baseUrl}oAuthClient/detail`,
  getDevice: `${baseUrl}devices`,
  getUsers: `${baseUrl}users`,
  getTokens: `${baseUrl}oAuthClient/getToken`,
  revokeToken: `${baseUrl}oAuthClient/revokeToken`,
  getDeviceType: `${baseUrl}deviceType`,
  getDefaultDeviceType: `${baseUrl}deviceDefaultType`,
  getDefaultCapabilities: `${baseUrl}deviceDefaultCapabilities`,
  createType: `${baseUrl}deviceType/create`,
  getCommands: `${baseUrl}commands`,
  getDeviceTypes: `${baseUrl}commands/deviceType`,
  getDevices: `${baseUrl}commands/devices`,
  sendCommands: `${baseUrl}commands/send`,
  getWhitelistDevice: `${baseUrl}devices/whitelist-devices`,
  addWhitelistDevice: `${baseUrl}import/whitelist-devices`,
  blockDevice: `${baseUrl}devices/whitelist-devices`,
  sendOtp: `${baseUrl}oAuthClient/request-otp`,
  verifyOtp: `${baseUrl}oAuthClient/verify-otp`,
  forgotPassword: `${baseUrl}auth/send-password-reset-link`,
  RefreshToken: `${baseUrl}auth/token`,
  deviceManufacturer: `${baseUrl}device-manufacturer`,
  certificateLogs: `${baseUrl}cert-logs`,
  versionInfo: `${baseUrl}default/version-info`,
  clientLoginUrl: `${clientBaseUrl}auth/login`,
  clientResetPwdSendOtpUrl: `${clientBaseUrl}auth/send-otp`,
  clientResetPwdVerifyOtpUrl: `${clientBaseUrl}auth/verify-otp`,
  clientResetPasswordUrl: `${clientBaseUrl}auth/reset-password`,
  clientChangePasswordUrl: `${clientBaseUrl}users/change-password`,
  clientSettingsUrl: `${clientBaseUrl}users/settings`,
  clientDevicesUrl: `${clientBaseUrl}device/get-user-devices-client-dashboard`, // client web new response
  // clientDevicesUrl: `${clientBaseUrl}device/get-user-devices-client-dashboard_test`,
  // clientDevicesUrl: `${clientBaseUrl}device/get-user-devices`,
  clientDeleteDevice: `${clientBaseUrl}device/remove-devices`,
  clientEditDeviceUrl: `${clientBaseUrl}device/edit-device`,
  clientEditDeviceFirmware: `${clientBaseUrl}device/device-firmware-update`,
  clientDeviceType: `${clientBaseUrl}device-type/`,
  clientCreateMultipleDeviceScheduler: `${clientBaseUrl}schedule-timer/create`, // create device schedule timer
  clientScheduledDevices: `${clientBaseUrl}schedule-timer/scheduled-device-list`, // scheduled devices list
  clientScheduledDevicesLogs: `${clientBaseUrl}schedule-timer/scheduled-device-logs`, // scheduled devices logs
  clientEditScheduledDevice: `${clientBaseUrl}schedule-timer/edit-scheduler`,
  clientGetOneScheduler: `${clientBaseUrl}schedule-timer/get-device-scheduler-info`, // get single scheduler
  clientEditScheduler: `${clientBaseUrl}schedule-timer/update-scheduler/edit`, // edit scheduler
  clientActivateScheduler: `${clientBaseUrl}schedule-timer/activation/scheduler`, // enable or disable scheduler
  clientDeleteScheduledDevice: `${clientBaseUrl}schedule-timer/remove-scheduler`,
  clientAddDevicesToSpaceUrl: `${clientBaseUrl}device/add-devices-to-space`,
  clientSharedDevices: `${clientBaseUrl}device/save-shared-devices`,
  clientEditGroupUrl: `${clientBaseUrl}group/update-group-info`,
  clientCreateSpaceUrl: `${clientBaseUrl}space/create`,
  clientSpacesUrl: `${clientBaseUrl}space/get-space-list`,
  clientDevicesInSpaceUrl: `${clientBaseUrl}space/get-space-devices`,
  clientDeleteDeviceInSpaceUrl: `${clientBaseUrl}space/remove-device`,
  clientDeleteSpaceUrl: `${clientBaseUrl}space/remove-space`,
  shareSpaceDevicesUrl: `${clientBaseUrl}space/share-space-devices`,
  createSpaceShareDeviceUrl: `${clientBaseUrl}space/create-space-share`,
  clientSharedSpaceWithUserUrl: `${clientBaseUrl}space/shared-space-users`,
  sharedSpacesRevocationUrl: `${clientBaseUrl}space/shared-space-revocation`,
  sharedSpaceWithMeUrl: `${clientBaseUrl}space/shared-spaces`,
  sharedSpacesDevicesListUrl: `${clientBaseUrl}space/get-shared-space-devices`,
  getSpacesSharedWithMeDevicesUrl: `${clientBaseUrl}device/get-sharedspace-devices`,
  getSpaceSharedContactHintUrl: `${clientBaseUrl}space/get-space-share-contact-hint`,
  clientGetRulesUrl: `${clientBaseUrl}device/get-rule/list`,
  clientCreateRulesUrl: `${clientBaseUrl}device/create-rule`,
  clientActivateRuleUrl: `${clientBaseUrl}device/activation/sensor-rule`,
  clientGetOneRuleUrl: `${clientBaseUrl}device/get-rule/device`,
  clientDeleteRulesUrl: `${clientBaseUrl}device/remove-rule`,
  clientRebootDeviceUrl: `${clientBaseUrl}device/reboot-device`,
  clientDeleteRulesUrl: `${clientBaseUrl}device/remove-rule`,
  clientLineChartUrl: `${clientBaseUrl}device/temp-sensor/logs`, // for display LineChart on DashBoard
  getUserDetails: `${baseUrl}auth/get-user-detail`,
  deleteUser: `${baseUrl}auth/delete-user`,
};
