import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
    getSpacesAction,
} from "../../actions/spacesActions";

const MoveDeviceToSpaceModal = ({ spaces, getSpacesAction, show, onHide, handleChangeSpaces, handleSubmitSpace, loading }) => {
    useEffect(() => {
        getSpacesAction();
    }, []);

    return (
        <>
            <Modal
                show={show}
                backdrop="static"
            >
                <Modal.Header closeButton
                    onClick={onHide}
                >
                    <Modal.Title>Space</Modal.Title>
                </Modal.Header>
                <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
                <Modal.Body>
                    <div className="form-group">
                        <label for="space_type">
                            Spaces <span style={{ color: 'red' }}>*</span>
                        </label>
                        <select
                            className="form-control m-input"
                            name="space_type"
                            onChange={handleChangeSpaces}
                            required
                        >
                            <option value="">Select Space</option>
                            {spaces?.map((item, index) => (
                                <option key={index} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        onClick={handleSubmitSpace}
                        className={`btn btn-primary ${loading
                            ? "m-loader m-loader--right m-loader--light"
                            : ""
                            }`}
                    >
                        Move
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={onHide}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    spaces: state.spaces,
})

export default connect(mapStateToProps, {
    getSpacesAction
})(MoveDeviceToSpaceModal);