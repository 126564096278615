import React, { useState, useRef } from 'react';
import { connect } from "react-redux";
import {
  Grid,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { createSpaceAction, getSpacesAction } from "../../actions/spacesActions";
import { toast } from "react-toastify";
import {
  SPACES_CREATED_SUCCESSFULLY,
  SPACE_NAME_WARNING,
  SPACE_IMAGE_WARNING
} from "../../helper/constants";

const AddSpaces = ({ spaces, setShowAddSpaces, createSpaceAction, refreshComponent }) => {
  const fileInput = useRef(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [validImage, setValidImage] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState({});
  const handleAddSpaceChange = (e) => {
    setName(
      e.target.value
    )
  };
  const onAddImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setValidImage(true);
      setImageSelected(true);
    }
  }
  const getSpaceImgSrc = (image) => {
    if (spaces.length == 0) {
      return URL.createObjectURL(image);
    } else {
      if (typeof image === "string") {
        var resultArr = image.split("/");
        var result = resultArr[1] + "/" + resultArr[2] + "/" + resultArr[3]
        return result;
      } else {
        return URL.createObjectURL(image);
      }
    }
  }

  const handleCreate = () => {
    if (name === '') {
      toast.error(SPACE_NAME_WARNING, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (!validImage) {
      toast.error(SPACE_IMAGE_WARNING, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      const data = new FormData();
      data.append("name", name);
      data.append("image", image);
      createSpaceAction(data).then((item) => {
        if (item.status === 200) {
          toast.success(SPACES_CREATED_SUCCESSFULLY, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowAddSpaces(false)
          refreshComponent();
        }
      });
    }
  }
  return (
    <>
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="row">
                  <div className="m-portlet__head-title col-lg-9">
                    <div
                      xs={12}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <h2>Add Spaces</h2>
                    </div>
                  </div>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        Back
                      </Tooltip>
                    }
                  >
                  <ChevronRightIcon
                    onClick={() => {
                      setShowAddSpaces(false)
                    }}
                      style={{ fontSize: 40, position: "absolute", right: 40, cursor: "pointer" }}
                  />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  onChange={handleAddSpaceChange}
                  name="name"
                  label="Space Name"
                />
                <div
                  style={{
                    margin: "2vh auto",
                    border: "2px dashed gray",
                    padding: "1vh",
                    borderRadius: "5px",
                  }}
                >
                  {imageSelected ? (<img onClick={() => { fileInput.current.click() }} style={{ height: "320px", width: "320px" }} src={getSpaceImgSrc(image)} />

                  ) : (
                    <div onClick={() => {
                      fileInput.current.click()
                    }} style={{ height: "320px", width: "320px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <CameraAltOutlinedIcon style={{ fontSize: 100, color: "gray" }} />
                      <p style={{ fontSize: 20 }}>Take a picture of the Space.</p>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={onAddImageChange}
                  ref={fileInput}
                  style={{ display: "none" }}
                />
                <Button
                  onClick={handleCreate}
                  variant="contained"
                  color="primary"
                  style={{ margin: "3vh auto" }}
                >
                  Create
                </Button>
              </Grid>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  spaces: state.spaces,
});

export default connect(mapStateToProps, {
  createSpaceAction,
  getSpacesAction
})(AddSpaces);