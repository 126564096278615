import {
    SHARED_DEVICES_FETCHED,
  } from "../actions/devicesActions";
  
  export function sharedDevicesReducer(state = [], action) {
    switch (action.type) {
      case SHARED_DEVICES_FETCHED:
        return action.shared_devices;
  
      default:
        return state;
    }
  }
  
  