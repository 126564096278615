import * as yup from "yup";
import addDays from "date-fns/addDays";

export const Validations = yup.object({
    schedulerName: yup.string().required(),
    schedulerType: yup.string().required(),
    deviceType: yup.string().when("schedulerType", {
        is: (value) => value ? value === "type" : "",
        then: yup.string().required(),
    }),
    deviceMacId: yup.array().ensure().required(),
    repeat_in: yup.string().when("scheduler_repeat_options", {
        is: (value) => value.value ? value !== "Once" : "",
        then: yup.string().required("repeat every is a required field").matches(/^[1-9]\d*$/, "repeat every must be greater than or equal to 1"),
    }),
    end_repeat_occurrence: yup.string().required("occurrence(s) is a required field").matches(/^[1-9]\d*$/, "occurrence(s) must be greater than or equal to 1"),
});

export const defaultValue = {
    schedulerName: '',
    schedulerType: 'type',
    deviceType: '',
    deviceMacId: [],
    spacesSelectedValue: [],
    scheduler_repeat_options: { label: 'Once', value: 'Once' },
    schedule_date: new Date(),
    schedule_fromDate: new Date(),
    schedule_time: new Date(),
    repeat_in: 1,
    daysOfWeekValue: [new Date().getDay()],
    end_repeat: 'never',
    end_repeat_occurrence: 1,
    end_repeat_date: addDays(new Date(), 1),
    deviceStatus: true,
    fanSpeed: 3,
    brightness: 18,
    temperature: 42,
    R: 1,
    G: 1,
    B: 1,
};