import axios from "axios";
import apiurl from "../config/api";
import globalHelper from "../helper/globalHelper";

export function getRules(deviceId) {
    let url = `${apiurl.clientGetRulesUrl}`;
    return axios
        .get(url)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            console.log(error.response);

            return error.response.data;
        });
}

export function getOneRule(deviceId) {
    return axios
        .get(`${apiurl.clientGetOneRuleUrl + "/" + deviceId}`)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            console.log(error.response);

            return error.response.data;
        });
}

export function createRules(ruleData) {
    return axios
        .post(apiurl.clientCreateRulesUrl, ruleData)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            console.log(error.response);

            return error.response.data;
        });
}

export function activateRule(ruleData) {
    return axios
        .post(apiurl.clientActivateRuleUrl, ruleData)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            console.log(error.response);
            return error.response.data;
        });
}

export function deleteRules(deleteRuleData) {
    return axios
        .post(`${apiurl.clientDeleteRulesUrl + "/" + deleteRuleData.id}`)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            console.log(error.response);
            return error.response.data;
        });
}

