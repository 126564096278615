import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import {
    Grid,
    Box,
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import {
    getDevicesAction,
} from "../../actions/devicesActions";
import { getRulesAction, getOneRuleAction, deleteRulesAction, activateRuleAction } from "../../actions/rulesActions";
import { toast } from "react-toastify";
import {
    RULES_DELETED_SUCCESSFULLY,
    DELETE_RULES_WARNING
} from "../../helper/constants";
import Loader from "../../components/Loader";
import AddIcon from "@material-ui/icons/Add";
import {
    DeleteOutlineOutlined as Delete,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AddEditRulesModal from "../../components/devicesComponents/AddEditRulesModal";
import DevicesDeleteModal from "../../components/devicesComponents/DevicesDeleteModal";
import { defaultValue, Validations } from "./RulesFormValidations";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";

const RulesList = ({
    getDevicesAction,
    getRulesAction,
    getOneRuleAction,
    deleteRulesAction,
    activateRuleAction
}) => {
    const { id } = useParams();
    const [selectedRows, setSelectedRows] = useState([]);
    const [rulesList, setRulesList] = useState([]);
    const [deviceName, setDeviceName] = useState("");
    const [deviceMacId, setDeviceMacId] = useState("");
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [showAddRulesModal, setShowAddRulesModal] = useState(false);
    const [showEditRulesModal, setShowEditRulesModal] = useState(false);
    const [deleteMultipleRulesModalShow, setDeleteMultipleRulesModalShow] = useState(false);
    const [formValue, setFormValue] = useState(defaultValue);
    const [editParamsRowData, setEditParamsRowData] = useState({});

    /* breadCrumb */
    const RulesConfigure = [
        {
            title: "Devices",
            link: "/devices",
            classData: "",
        },
        {
            title: "Sensor Rules",
            link: "/sensor-rules",
            classData: "active_anv_link",
        }
    ];

    /* breadCrumb */
    const TemperatureSensorRules = [
        {
            title: "Sensor Rules",
            link: "/sensor-rules",
            classData: "active_anv_link",
        }
    ]

    useEffect(() => {
        callUseEffect();
    }, []);

    const callUseEffect = async () => {
        setLoading(true);
        // You can await here
        if (id) {
            getDevicesAction().then((response) => {
                response.ownerDevice.devices.forEach(async (deviceData) => {
                if (deviceData._id === id.toString()) {
                    setDeviceName(deviceData.instanceId.name);
                    setDeviceMacId([{ label: deviceData.instanceId.name, value: deviceData.deviceMacId }]);
                    const getRulesData = await getOneRuleAction(deviceData.deviceMacId);
                    setRulesList(getRulesData.data);
                }
            })
            setIsFetching(false);
            setLoading(false);
            });
        }
        else {
            getDevicesAction();
            const getRulesData = await getRulesAction(undefined);
            setRulesList(getRulesData.data);
            setIsFetching(false);
            setLoading(false);
        }
    }

    /* handle multiple rules(s) delete confirm */
    const onDeleteMultipleRulesConfirm = async () => {
        handleMultipleRulesDelete();
        onDeleteMultipleRulesModalClose();
    };

    /* handle multiple rule(s) delete */
    const handleMultipleRulesDelete = async () => {
        if (selectedRows.length > 0) {
            rulesList.map(async (deviceData) => {
                if (selectedRows.includes(deviceData._id)) {
                    const deleteRulesRes = await deleteRulesAction({
                        id: deviceData._id
                    });
                    if (deleteRulesRes.status === 200) {
                        toast.success(RULES_DELETED_SUCCESSFULLY, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        refreshComponent();
                    }
                }
            });
        }
        setSelectedRows([]);
    };

    /* handle add rules modal */
    const handleAddRulesModal = () => {
        setShowAddRulesModal(false);
        toast.dismiss();
    };

    /* handle delete multiple rules modal close */
    const onDeleteMultipleRulesModalClose = () => {
        setDeleteMultipleRulesModalShow(false);
    }

    /* handle enable/disable rule */
    const handleIsEnabled = async (e, row) => {
        row.isEnabled = e.target.checked;
        const IsEnabledRes = await activateRuleAction(row);
        if (IsEnabledRes.status === 200) {
            toast.success(IsEnabledRes.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refreshComponent();
        }
    }

    /* handle edit rules modal open */
    const handleEditRulesModalOpen = (paramsRow) => {
        setShowEditRulesModal(true);
        const paramsRowObj = {
            _id: paramsRow._id,
            ruleName: paramsRow.ruleName,
            rule: paramsRow.rule,
            set_rule_from: Number(paramsRow.set_rule_from),
            set_rule_to: Number(paramsRow.set_rule_to),
            ruleDelay: Number(paramsRow.ruleDelay),
            last_execution_on: paramsRow.last_execution_on,
            devices: paramsRow.devices,
            sendNotification: paramsRow.sendNotification,
            temperatureSensorId: paramsRow.temperatureSensorId,
            deviceStatus: paramsRow.deviceStatus,
            from_time: paramsRow.from_time ? new Date(paramsRow.from_time) : '',
            to_time: paramsRow.to_time ? new Date(paramsRow.to_time) : '',
            days: paramsRow.days.length !== 0 ? paramsRow.days : []
        };
        if (paramsRow.fanSpeed) {
            paramsRowObj.fanSpeed = paramsRow.fanSpeed ? paramsRow.fanSpeed : '';
        }
        setEditParamsRowData(paramsRowObj);
    }

    /* handle edit rules modal close */
    const handleEditRulesModalClose = () => {
        setShowEditRulesModal(false);
        toast.dismiss();
    }

    /* refresh component */
    const refreshComponent = async () => {
        setIsFetching(true);
        setLoading(true);
        // You can await here
        if (id) {
            getDevicesAction().then((response) => {
            response.ownerDevice.devices.forEach(async (deviceData) => {
                if (deviceData._id === id.toString()) {
                    setDeviceName(deviceData.instanceId.name);
                    setDeviceMacId([{ label: deviceData.instanceId.name, value: deviceData.deviceMacId }]);
                    const getRulesData = await getOneRuleAction(deviceData.deviceMacId);
                    setRulesList(getRulesData.data);
                }
            })
            setIsFetching(false);
            setLoading(false);
        });
        }
        else {
            getDevicesAction();
            const getRulesData = await getRulesAction(undefined);
            setRulesList(getRulesData.data);
            setIsFetching(false);
            setLoading(false);
        }
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarColumnsButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarFilterButton />
                <Box style={{ width: "2vh" }}></Box>
                <GridToolbarDensitySelector />
                <Box style={{ width: "2vh" }}></Box>
                <Grid
                    container
                    xs={9}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    {selectedRows.length > 0 ? (
                        <>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Delete rule(s)
                                    </Tooltip>
                                }
                            >
                            <Delete
                                onClick={() => {
                                    setDeleteMultipleRulesModalShow(true);
                                }}
                                style={{ fontSize: 40, color: "#fff", background: "#868aa8", padding: "8", margin: "0 5", cursor: "pointer" }}
                            />
                            </OverlayTrigger>
                        </>
                    ) : (
                        <>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Add rule
                                        </Tooltip>
                                    }
                                ><AddIcon
                                onClick={() => {
                                    setShowAddRulesModal(true);
                                }}
                                style={{
                                    fontSize: "40px",
                                    padding: "8px",
                                    color: "#fff",
                                    background: "#868aa8",
                                    cursor: "pointer",
                                }}
                            />
                                </OverlayTrigger>
                        </>
                    )}
                </Grid>
            </GridToolbarContainer>
        );
    };

    const columns = [
        {
            field: "ruleName",
            headerName: "Rule Name",
            editable: false,
            filterable: true,
            sortable: true,
            width: 220,
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return params.row.ruleName;
            },
        },
        {
            field: "rule",
            headerName: "Criteria",
            editable: false,
            sortable: true,
            filterable: true,
            width: 200,
            headerAlign: "center",
            align: "center",
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return params.row.rule;
            },
        },
        {
            field: "set_rule_from",
            headerName: "Rule Range",
            width: 160,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return `${params.row.set_rule_from} to ${params.row.set_rule_to}`;
            },
        },
        {
            field: "isEnabled",
            headerName: "Enable/Disable",
            width: 170,
            align: "center",
            headerAlign: "center",
            editable: false,
            filterable: false,
            sortable: true,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <label className="switch">
                        <input
                            name="deviceStatus"
                            type="checkbox"
                            checked={params.row.isEnabled}
                            onChange={(e) => {
                                handleIsEnabled(e, params.row);
                            }}
                        />
                        <span className="slider round"></span>
                    </label>)
            },
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 100,
            align: "center",
            headerAlign: "center",
            editable: false,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>
                                    Edit rule
                                </Tooltip>
                            }
                        >
                    <EditIcon
                        onClick={
                            () => {
                                handleEditRulesModalOpen(params.row);
                            }
                        }
                        style={{ fontSize: 20, color: "#3f51b5", cursor: "pointer" }}
                    />
                        </OverlayTrigger>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {isFetching && <Loader loading={isFetching} />}
            {showAddRulesModal &&
                <AddEditRulesModal
                    show={showAddRulesModal}
                    onHide={handleAddRulesModal}
                    defaultValue={formValue}
                    validations={Validations}
                    deviceMacId={deviceMacId}
                deviceName={deviceName}
                    refreshComponent={refreshComponent}
                    paramsId={id} />}
            {showEditRulesModal &&
                <AddEditRulesModal
                    show={showEditRulesModal}
                    onHide={handleEditRulesModalClose}
                    defaultValue={editParamsRowData}
                    validations={Validations}
                    deviceMacId={deviceMacId}
                    refreshComponent={refreshComponent}
                    editParamsRowData={editParamsRowData}
                    paramsId={id} />}
            <DevicesDeleteModal
                modalShown={deleteMultipleRulesModalShow}
                onModelClose={onDeleteMultipleRulesModalClose}
                onDeleteConfirm={onDeleteMultipleRulesConfirm}
                headerTitle="Delete Rules"
                message={DELETE_RULES_WARNING}
            />
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                    <div className="m-portlet m-portlet--mobile">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                                    <h2>Sensor Rules
                                            {deviceName ? ` for ${deviceName}` : ""}
                                    </h2>
                                <CustomBreadCrumb breadCrumData={id ? RulesConfigure : TemperatureSensorRules} />
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="row spacesListDetails">
                                <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>
                                    <DataGrid
                                        getRowId={(row) => row._id}
                                        isRowSelectable={(params) => params.row.shared !== true}
                                        checkboxSelection={true}
                                        disableSelectionOnClick={true}
                                        rows={rulesList}
                                        rowHeight={40}
                                        columns={columns}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        onSelectionModelChange={(newSelection) => {
                                            setSelectedRows(newSelection);
                                        }}
                                        selectionModel={selectedRows}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect("", {
    getDevicesAction,
    getRulesAction,
    getOneRuleAction,
    deleteRulesAction,
    activateRuleAction
})(RulesList);
