import * as devicesService from "../services/DevicesService";
export const DEVICES_FETCHED = "DEVICES_FETCHED";
export const DEVICE_EDITED = "DEVICE_EDITED";
export const DEVICE_DELETED = "DEVICE_DELETED";
export const ADD_SCHEDULE_TIMER = "ADD_SCHEDULE_TIMER";
export const CREATE_MULTIPLE_SCHEDULER = "CREATE_MULTIPLE_SCHEDULER";
export const GET_SCHEDULED_DEVICES = "GET_SCHEDULED_DEVICES";
export const GET_SCHEDULED_DEVICES_LOGS = "GET_SCHEDULED_DEVICES_LOGS";
export const EDIT_SCHEDULED_DEVICE = "EDIT_SCHEDULED_DEVICE";
export const GET_ONE_SCHEDULER = "GET_ONE_SCHEDULER";
export const EDIT_SCHEDULER = "EDIT_SCHEDULER";
export const ACTIVATE_SCHEDULER = "ACTIVATE_SCHEDULER";
export const DELETE_SCHEDULED_DEVICE = "DELETE_SCHEDULED_DEVICE";
export const ADD_SHARED_DEVICES = "ADD_SHARED_DEVICES";
export const UPDATE_DEVICE_FIRMWARE = "UPDATE_DEVICE_FIRMWARE";
export const GET_SPACES_SHARED_WITH_ME_DEVICES = "GET_SPACES_SHARED_WITH_ME_DEVICES";
export const REBOOT_DEVICE = "REBOOT_DEVICE";
export const SHARED_DEVICES_FETCHED = "SHARED_DEVICES_FETCHED";

export const getDevicesAction = async () => {
  return (dispatch) => {
    return devicesService.getDevices().then((devices) => {
      dispatch({
        type: DEVICES_FETCHED,
        devices: devices.data.ownerDevice.devices || [],
      });
      dispatch({
        type: SHARED_DEVICES_FETCHED,
        shared_devices: devices.data.sharedDevices || [],
      });
      return devices.data;
    });
  };
};

export const editDeviceAction = (deviceInfo) => {
  return (dispatch) => {
    return devicesService.editDevice(deviceInfo).then((devices) => {
      dispatch({
        type: DEVICE_EDITED,
        devices,
      });
      return devices;
    });
  };
};

export const updateDeviceFirmwareAction = (deviceFirmware) => {
  return (dispatch) => {
    return devicesService
      .updateDeviceFirmware(deviceFirmware)
      .then((devices) => {
        dispatch({
          type: UPDATE_DEVICE_FIRMWARE,
          devices,
        });
        return devices;
      });
  };
};

export const rebootDeviceAction = async (deviceMacId) => {
  try {
    const rebootDeviceData = await devicesService.rebootDevice(deviceMacId);
    return rebootDeviceData;
  }
  catch (error) {
    return error;
  }
};

export const getSpacesSharedWithMeDevicesAction = () => {
  return (dispatch) => {
    return devicesService
      .getSpacesSharedWithMeDevices()
      .then((getSpacesSharedWithMeData) => {
        dispatch({
          type: GET_SPACES_SHARED_WITH_ME_DEVICES,
          getSpacesSharedWithMeData,
        });
        return getSpacesSharedWithMeData;
      });
  };
};

export const saveSharedDevicesAction = async (sharedDevices) => {
  try {
    const devicesServiceData = await devicesService.saveSharedDevices(sharedDevices);
    return devicesServiceData;
  } catch (error) {
    return error;
  }
};

export const getScheduledDevicesAction = () => {
  return (dispatch) => {
    return devicesService.getScheduledDevices().then((scheduledDevices) => {
      dispatch({
        type: GET_SCHEDULED_DEVICES,
        scheduledDevices,
      });
      return scheduledDevices;
    });
  };
};

export const createMultipleDeviceSchedulerAction = (schedulerData) => {
  return (dispatch) => {
    return devicesService
      .createMultipleDeviceScheduler(schedulerData)
      .then((scheduledDevices) => {
        dispatch({
          type: CREATE_MULTIPLE_SCHEDULER,
          scheduledDevices,
        });
        return scheduledDevices;
      });
  };
};

export const getScheduledDevicesLogsAction = () => {
  return (dispatch) => {
    return devicesService
      .getScheduledDevicesLogs()
      .then((scheduledDevicesLogs) => {
        dispatch({
          type: GET_SCHEDULED_DEVICES_LOGS,
          scheduledDevicesLogs,
        });
        return scheduledDevicesLogs;
      });
  };
};

export const deleteDeviceAction = (deviceInfo) => {
  return (dispatch) => {
    return devicesService.deleteDevice(deviceInfo).then((devices) => {
      dispatch({
        type: DEVICE_DELETED,
        devices,
      });
      return devices;
    });
  };
};

export const editScheduledDeviceAction = (data) => {
  return (dispatch) => {
    return devicesService.editScheduledDevice(data).then((devices) => {
      dispatch({
        type: EDIT_SCHEDULED_DEVICE,
        devices,
      });
      return devices;
    });
  };
};

export const EditSchedulerAction = (data) => {
  return (dispatch) => {
    return devicesService.editScheduler(data).then((schedulerData) => {
      dispatch({
        type: EDIT_SCHEDULER,
        schedulerData,
      });
      return schedulerData;
    });
  };
};

export const ActivateSchedulerAction = (data) => {
  return (dispatch) => {
    return devicesService.activateScheduler(data).then((schedulerData) => {
      dispatch({
        type: ACTIVATE_SCHEDULER,
        schedulerData,
      });
      return schedulerData;
    });
  };
};

export const getOneSchedulerAction = (data) => {
  return (dispatch) => {
    return devicesService.getOneScheduler(data).then((schedulerData) => {
      dispatch({
        type: GET_ONE_SCHEDULER,
        schedulerData,
      });
      return schedulerData;
    });
  };
};

export const deleteScheduledDeviceAction = (Id) => {
  return (dispatch) => {
    return devicesService.deleteScheduledDevice(Id).then((devices) => {
      dispatch({
        type: DELETE_SCHEDULED_DEVICE,
        devices,
      });
      return devices;
    });
  };
};

export const updateDeviceStatusAction = (devices) => {
  return (dispatch) => {
    dispatch({
      type: DEVICES_FETCHED,
      devices: devices,
    });
    return devices;
  };
};

export const updateSharedDeviceStatusAction = (devices) => {
  return (dispatch) => {
    dispatch({
      type: SHARED_DEVICES_FETCHED,
      shared_devices: devices,
    });
    return devices;
  };
};
