import axios from "axios";
import apiurl from "../config/api";
import globalHelper from "../helper/globalHelper";

export function getDevices() {
  return axios
    .get(apiurl.clientDevicesUrl)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response && error.response.data) {
        globalHelper.message(error.response.data.message.message, "error");
      }
      return error.response.data;
    });
}

export function editDevice(deviceInfo) {
  return axios
    .post(apiurl.clientEditDeviceUrl, deviceInfo)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function updateDeviceFirmware(deviceFirmware) {
  return axios
    .post(apiurl.clientEditDeviceFirmware, deviceFirmware)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function rebootDevice(deviceMacId) {
  return axios
    .post(apiurl.clientRebootDeviceUrl, deviceMacId)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function getSpacesSharedWithMeDevices() {
  return axios
    .get(apiurl.getSpacesSharedWithMeDevicesUrl)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function getScheduledDevices() {
  return axios
    .get(apiurl.clientScheduledDevices)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function createMultipleDeviceScheduler(schedulerData) {
  return axios
    .post(apiurl.clientCreateMultipleDeviceScheduler, schedulerData)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function getScheduledDevicesLogs() {
  return axios
    .get(apiurl.clientScheduledDevicesLogs)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
};

export function saveSharedDevices(sharedDevices) {
  return axios
    .post(apiurl.clientSharedDevices, sharedDevices)
    .then((result) => {
      return result.data.data;
    });
}

export function editScheduledDevice(data) {
  return axios
    .post(`${apiurl.clientEditScheduledDevice + "/" + data._id}`, data)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function getOneScheduler(data) {
  return axios
    .get(`${apiurl.clientGetOneScheduler + "/" + data}`)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function editScheduler(data) {
  return axios
    .post(`${apiurl.clientEditScheduler + "/" + data.id}`, data)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function activateScheduler(data) {
  return axios
    .post(`${apiurl.clientActivateScheduler + "/" + data.id}`, data.data)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}

export function deleteScheduledDevice(Id) {
  return axios
    .post(apiurl.clientDeleteScheduledDevice, Id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response.data;
    });
}


export function deleteDevice(deviceInfo) {
  return axios.post(apiurl.clientDeleteDevice, deviceInfo).then((result) => {
    return result.data;
  });
}
