import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {
  createTokenAction,
  getClientAction
} from "../../../actions/tokenActions";

class TokenAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        redirect: "",
        scope: "",
      },
      errors: {}
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      const data = { id: this.props.match.params.id };
      this.props
        .getClient(data)
        .then(client => {
          if (client.error) {
            this.setState({
              data: this.state.data,
              errors: client.error
            });
          } else {
            this.setState({
              data: {
                _id: client._id,
                name: client.name,
                redirect: client.redirect.toString(),
                scope: client.scope
              }
            });
          }
        })
        .catch(err => console.log("Failure", err));
    }
  }

  getInitialState = () => {
    return {
      data: {
        name: "",
        redirect: "",
        scope: ""
      },
      errors: {}
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    const { data } = this.state;
    if (this.validator.allValid()) {
      this.props
        .createToken(data)
        .then(() => {
          this.props.history.push("/oauth-token");
        })
        .catch(err => console.log("Failure", err));
      this.setState(this.getInitialState());
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCheck = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.checked
      }
    });
  };

  render() {

    const { name, redirect, scope } = this.state.data;
    
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">
                    {this.state.data._id ? "Update Client" : "Create Client"}
                  </h3>
                </div>
              </div>
            </div>
            <form
              className="m-form m-form--fit m-form--label-align-right m-form--group-seperator"
              onSubmit={this.handleSubmit}
            >
              <div className="m-portlet__body">
                <div className="m-form__section m-form__section--first">
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">Name:</label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        autoComplete="off"
                        value={name}
                      />
                      {this.validator.message(
                        "name",
                        name,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Redirect URL:
                    </label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        name="redirect"
                        className="form-control m-input"
                        onChange={this.handleChange}
                        value={redirect}
                      />
                      {this.validator.message(
                        "redirect",
                        redirect,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-2 col-form-label">
                      Scope:
                    </label>
                    <div className="col-lg-6">
                      <div className="m-checkbox-inline">
                        <label className="m-checkbox">
                          <input
                            type="checkbox"
                            name="scope"
                            checked={scope}
                            onChange={this.handleCheck}
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-portlet__foot m-portlet__foot--fit">
                <div className="m-form__actions m-form__actions">
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-6">
                      <button type="submit" className="btn btn-primary">
                        {this.state.data._id
                          ? "Update Client"
                          : "Create Client"}
                      </button>
                      &nbsp;
                      <Link to="/oauth-token/" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    client: state.client
  }),
  dispatch => ({
    dispatch,
    createToken: credentials => dispatch(createTokenAction(credentials)),
    getClient: credentials => dispatch(getClientAction(credentials))
  })
)(TokenAdd);
