import React, { useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
    Grid,
    Divider,
    Button,
    TextField,   
} from "@material-ui/core";
import { connect } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from "react-toastify";
import { editDeviceAction} from "../../actions/devicesActions";
import { DEVICE_EDIT_UPDATE_WARNING } from "../../helper/constants";

const EditDeviceName = ({ editDeviceAction, setShowEditDevice, editParamsRowData, refreshComponent }) => {
  const [editDeviceName, setEditDeviceName] = useState({ deviceMacId: "", name: "", spaceId: "" });

  /* handle edit Device name */
 const handleDeviceChange = (e) => {
    setEditDeviceName({
      deviceMacId:editParamsRowData.deviceMacId,
      name: e.target.value,
      spaceId: editParamsRowData?.spaceId?._id
    });
  };

   const handleSubmitEditDevice = async (editDeviceName) => {
    const {deviceMacId,name} = editDeviceName;
    if(deviceMacId === "" || name === "" ){
      toast.error(DEVICE_EDIT_UPDATE_WARNING, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      const editDeviceNameRes = await editDeviceAction(editDeviceName);
      if(editDeviceNameRes.status === 200){
        toast.success(editDeviceNameRes.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
   }
    return (
        <>
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="row">
                  <div className="m-portlet__head-title col-lg-9">
                    <div
                      xs={12}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <h2>Edit Device</h2>
                    </div>
                  </div>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>
                          Back
                        </Tooltip>
                      }
                    >
                  <ChevronRightIcon
                    onClick={() => {
                        setShowEditDevice(false);
                        refreshComponent();
                    }}
                      style={{ fontSize: 40, position: "absolute", right: 40, cursor: "pointer" }}
                  />
                    </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  onChange={handleDeviceChange}
                  name="name"
                  label="Device Name"
                  defaultValue={editParamsRowData.instanceId.name}
                />
                <p style={{ marginTop: "2vh" }}>
                  Example: A user friendly name like 'Kitchen Light',
                </p>
                <Button
                  onClick={ (e)=>{handleSubmitEditDevice(editDeviceName)}}
                  variant="contained"
                  color="primary"
                  style={{ margin: "3vh auto" }}
                >
                  Update
                </Button>
              </Grid>
              <Divider />
            </div>
          </div>
        </div>
      </div>
      </>
    )
}

export default connect(null, {
    editDeviceAction
})(EditDeviceName);

