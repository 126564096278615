import * as rulesService from "../services/RulesService";
export const RULES_FETCHED = "RULES_FETCHED";
export const CREATE_RULES = "CREATE_RULES";
export const ACTIVATE_RULE = "ACTIVATE_RULE";
export const DELETE_RULES = "DELETE_RULES";

export const getRulesAction = (deviceId) => {
    return (dispatch) => {
        return rulesService.getRules(deviceId).then((rulesData) => {
            dispatch({
                type: RULES_FETCHED,
                rulesData,
            });
            return rulesData;
        });
    };
};

export const getOneRuleAction = (deviceId) => {
    return (dispatch) => {
        return rulesService.getOneRule(deviceId).then((ruleData) => {
            dispatch({
                type: RULES_FETCHED,
                ruleData,
            });
            return ruleData;
        });
    };
};

export const createRulesAction = (createRule) => {
    return (dispatch) => {
        return rulesService.createRules(createRule).then((rulesData) => {
            dispatch({
                type: CREATE_RULES,
                rulesData,
            });
            return rulesData;
        });
    };
};

export const activateRuleAction = (activateRule) => {
    return (dispatch) => {
        return rulesService.activateRule(activateRule).then((rulesData) => {
            dispatch({
                type: ACTIVATE_RULE,
                rulesData,
            });
            return rulesData;
        });
    };
};

export const deleteRulesAction = (deleteRule) => {
    return (dispatch) => {
        return rulesService.deleteRules(deleteRule).then((rulesData) => {
            dispatch({
                type: DELETE_RULES,
                rulesData,
            });
            return rulesData;
        });
    };
};