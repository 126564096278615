import React, { useState } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    DataGrid,
  } from "@material-ui/data-grid";
  import ChevronRightIcon from "@material-ui/icons/ChevronRight";
  import ShareIcon from "@material-ui/icons/Share";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import socket from "../../helper/socket";
import globalHelper from "../../helper/globalHelper";
import DeviceInfo from "../devicesComponents/DeviceInfo";
import { DEVICE_TYPE_TEMP_SENSOR } from "../../helper/constants";

const SharedDevicesAccordion = ({ sharedItemsData }) => {
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const [deviceInfoList, setDeviceInfoList] = useState([]);
  /* send socket event  */
  const sendSocketEventData = async (deviceData, deviceExtraPayload, deviceTemperature, deviceRGB) => {
    const payload = await globalHelper.getDeviceSocketPayload(deviceData, deviceExtraPayload, deviceTemperature, deviceRGB);
    socket.io.emit("deviceDetail-v1", payload);
  };
  /* handle device info event */
  const handleShowDeviceInfo = () => {
    setShowDeviceInfo(false);
  }

const Columns = [
    {
      field: "image",
      headerName: "Image",
      type: "singleSelect",
      editable: false,
      sortable: false,
      width: 110,
      valueOptions: ["light", "switch", "fan"],
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div>
            <img
              style={{ height: "50px", width: "50px" }}
              src={`${process.env.REACT_APP_PUBLIC_URL}${params.row.deviceType.icon}`}
            />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => {
        let sharedDevicesName = params?.row?.instanceId?.name ? params.row.instanceId.name : "-";
         return sharedDevicesName;
      }
    },
    {
      field: "online",
      headerName: "Online",
      type: "boolean",
      width: 150,
      align: "center",
      headerAlign: "center",
      editable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if (params?.row?.instanceId?.online) {
          return (
            <Grid
              container
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              justifyContent="center"
            >
              <p
                style={{
                  fontSize: 10,
                  marginTop: "13px",
                  marginRight: "10px",
                }}
              >
                online
              </p>
              <img
                style={{ width: "20px", height: "13px" }}
                src={process.env.PUBLIC_URL + "images/cloudact.png"}
              />
            </Grid>
          );
        } else {
          return (
            <Grid
              container
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              justifyContent="center"
            >
              <p
                style={{
                  fontSize: 10,
                  marginTop: "13px",
                  marginRight: "10px",
                }}
              >
                offline
              </p>
              <img
                style={{ width: "20px", height: "13px" }}
                src={process.env.PUBLIC_URL + "images/cloud.png"}
              />
            </Grid>
          );
        }
      },
    },
    {
      field: "on",
      headerName: "On",
      type: "boolean",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if (params.row.deviceType.name === DEVICE_TYPE_TEMP_SENSOR) {
          return (
            <Grid
              container
              justifyContent="center"
              style={{ alignItems: "center", cursor: "pointer" }}
            >
              <img src="images/temperature-icon.png" width="20px" height="27px" className="pr-2" />
              {(params?.row?.instanceId?.caps?.get_TemperatureControl?.params?.currentTemperature).toFixed(2)}</Grid>
          );
        }
        else {
          const imgPath =
            params?.row?.instanceId?.online &&
              params?.row?.instanceId?.caps?.get_OnOff?.params.on
              ? "images/onbtn.png"
              : "images/offbtn.png";
          return (
            <Grid
              container
              justifyContent="center"
              style={{ alignItems: "center", cursor: "pointer" }}
            >
              <img
                alt={params.row._id}
                onClick={() => {
                  sendSocketEventData(params.row);
                }}
                style={{ width: "35px", height: "35px" }}
                src={process.env.PUBLIC_URL + imgPath}
              />
            </Grid>
          );
        }
      },
    },
    {
      field: "deviceInfo",
      headerName: "Info",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                Device info
              </Tooltip>
            }
          >
          <ChevronRightIcon
            onClick={() => {
              setShowDeviceInfo(true);
              setDeviceInfoList(params.row);
            }}
              style={{ fontSize: 20, cursor: "pointer" }}
          />
          </OverlayTrigger>
        );
      },
    },
  ];
    return (
      <>
        {showDeviceInfo ? (<>
          <DeviceInfo
            deviceInfoList={deviceInfoList}
            handleShowDeviceInfo={handleShowDeviceInfo}
            sendSocketEventData={sendSocketEventData}
            sharedCondition={false}
          />
        </>) : (
          <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
            <ShareIcon  style={{fontSize:"1rem"}} /><b>{" "}{sharedItemsData[0].user_name}_Group:</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
                  <div style={{ height: "60vh", width: "100%", margin: "2vh auto" }}>
              <DataGrid
                getRowId={(row) => row._id}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                rows={sharedItemsData}
                rowHeight={40}
                      columns={Columns}
                filterModel={{
                  items: [
                    { id: 1, columnField: "image", operatorValue: "is" },
                    { id: 2, columnField: "name", operatorValue: "contains" },
                  ],
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </>
        )
        }
      </>
    );

}

export default SharedDevicesAccordion;