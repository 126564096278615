import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import {
  Box,
} from "@material-ui/core";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from "react-bootstrap";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import { getScheduledDevicesLogsAction } from "../../actions/devicesActions";
import Loader from "../../components/Loader";
import { utcToLocalDateFormat } from "../../helper/dateFormat";


class ScheduleTimerLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      scheduledDevicesLogs: {},
      isFetching: true,
      showSchedulerInfoModal: false,
      schedulerInfoParams: ''
    };
  }

  componentDidMount() {
    this.props.getScheduledDevicesLogs().then((res) => {
      this.setState({
        isFetching: false,
        scheduledDevicesLogs: res,
        showSchedulerInfoModal: false,
        schedulerInfoParams: ''
      });
    });
  }

  handleSchedulerModalClose() {
    this.setState({
      showSchedulerInfoModal: false
    })
  }

  /* custom toolbar for spaces shared with me */
  SchedulerLogsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box style={{ width: "2vh" }}></Box>
        <GridToolbarColumnsButton />
        <Box style={{ width: "2vh" }}></Box>
        <GridToolbarDensitySelector />
        <Box style={{ width: "2vh" }}></Box>
      </GridToolbarContainer>
    );
  };

  renderList() {
    const { scheduledDevicesLogs } = this.state;

    /* DataGrid columns */
    const columns = [
      {
        field: "id",
        headerName: "Sr. No.",
        width: 110,
        align: "center",
        headerAlign: "center",
        editable: false,
        filterable: false,
        sortable: false,
      },
      {
        field: "scheduler_name",
        headerName: "Name",
        width: 240,
        align: "center",
        headerAlign: "center",
        editable: false,
      },
      {
        field: "scheduler_date_time",
        headerName: "Date",
        width: 220,
        align: "center",
        headerAlign: "center",
        editable: false,
      },
      {
        field: "scheduler_action",
        headerName: "Action",
        width: 220,
        align: "center",
        headerAlign: "center",
        editable: false,
        sortable: false,
      },
      {
        field: "scheduled_devices_info",
        headerName: "Info",
        width: 150,
        align: "center",
        headerAlign: "center",
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    View scheduler info
                  </Tooltip>
                }
              >
              <InfoOutlined 
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    showSchedulerInfoModal: true,
                    schedulerInfoParams: params.row
                  });
                }} />
              </OverlayTrigger>
            </>
          );
        }
      },
    ];

    const rows = [];
    for (var i = 0; i < scheduledDevicesLogs.data?.length; i++) {
      rows.push({
        id: i + 1,
        scheduler_date_time: scheduledDevicesLogs.data[i]?.schedule_Date ? utcToLocalDateFormat(scheduledDevicesLogs.data[i]?.schedule_Date) : "-",
        scheduler_name: scheduledDevicesLogs.data[i]?.schedulerName ? scheduledDevicesLogs.data[i]?.schedulerName : "-",
        scheduler_status: scheduledDevicesLogs.data[i]?.scheduler_Status,
        scheduler_action: scheduledDevicesLogs.data[i]?.scheduler_Action,
        scheduled_devices_info: scheduledDevicesLogs.data[i]?.scheduler_Info,
        scheduler_Failure_DeviceId: scheduledDevicesLogs.data[i]?.scheduler_Failure_DeviceId,
        scheduler_Success_DeviceId: scheduledDevicesLogs.data[i]?.scheduler_Success_DeviceId,
      });
    }

    return (
      <div style={{ height: "85vh", width: "100%", margin: "2vh auto" }}>

        <DataGrid
          disableSelectionOnClick
          rows={rows}
          rowHeight={40}
          columns={columns}
          components={{
            Toolbar: this.SchedulerLogsToolbar,
          }}
        />
      </div>
    );
  }

  render() {
    /* breadCrumb */
    const SchedulerTimerLogs = [
      {
        title: "Scheduler Logs",
        link: "/scheduler-logs",
        classData: "active_anv_link",
      }
    ];
    return (
      <>
        {this.state.isFetching && <Loader loading={this.state.isFetching} />}
        <Modal
          show={this.state.showSchedulerInfoModal}
          backdrop="static">
          <Modal.Header
            closeButton
            onClick={() => { this.handleSchedulerModalClose() }}
          >
            <Modal.Title>Scheduler Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.schedulerInfoParams?.scheduler_Failure_DeviceId?.length > 0 &&
              <>
              <b>Failure Devices:</b><hr /> <ul>{this.state.schedulerInfoParams?.scheduler_Failure_DeviceId?.map((failureItem, failureIndex) => (
                <li key={failureIndex}>{failureItem.deviceName} :  {failureItem.reason}</li>
                ))}</ul>
              </>}
            <br />
            {this.state.schedulerInfoParams?.scheduler_Success_DeviceId?.length > 0 &&
              <>
                <b>Success Devices:</b><hr /> <ul>{this.state.schedulerInfoParams?.scheduler_Success_DeviceId?.map((successItem, successIndex) => (
                  <li key={successIndex}>{successItem}</li>
                ))}</ul>
              </>}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => { this.handleSchedulerModalClose() }}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-content">
            <div className="m-portlet m-portlet--mobile">
              <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                  <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">
                    <h2 className="m-portlet__head-title col-lg-9">
                      Scheduler Logs
                    </h2>
                  <CustomBreadCrumb breadCrumData={SchedulerTimerLogs} />
                  </div>
                </div>
              </div>
              <div className="m-portlet__body">
                <div className="row spacesListDetails">{this.renderList()}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  "",
  (dispatch) => ({
    dispatch,
    getScheduledDevicesLogs: () => dispatch(getScheduledDevicesLogsAction()),
  })
)(ScheduleTimerLogs);
