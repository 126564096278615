import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import {
  authClientReducer,
  authSendOtpReducer,
  authVerifyOtpReducer,
} from "./authClientReducer";
import { authTokenReducer } from "./authTokenReducer";
import {
  devicesReducer,
  editDevicesReducer,
  updateDeviceFirmwareReducer,
  deleteDeviceReducer,
  scheduledDevicesReducer,
  createMultipleDeviceSchedulerReducer,
  scheduledDevicesLogsReducer,
} from "./devicesReducer";
import {
  sharedDevicesReducer
} from  "./sharedDevicesReducer";
import {
  spacesReducer,
  sharedSpaceDevicesReducer,
  getDevicesInSpaceReducer,
  addDevicesToSpaceReducer,
  deleteDeviceInSpaceReducer,
} from "./spacesReducers";
import { userReducer } from "./userReducer";
import {
  deviceTypesReducer,
  deviceDefaultTypesReducer,
  deviceDefaultCapatibilityReducer,
  createTypeReducer,
} from "./deviceTypeReducer";
import {
  getDevicesReducer,
  getCommandsReducer,
  deviceAdminTypesReducer,
} from "./commandReducer";
import {
  createWhitelistReducer,
  getWhitelistDeviceReducer,
  blockDeviceReducer,
} from "./deviceWhitelistReducer";
import { forgotPassReducer } from "./forgotPassReducer";
import { deviceManufacturerReducer } from "./deviceManufacturerReducer";
import { certLogsReducer } from "./cerLogsReducer";
import { versionInfoReducer } from "./versionInfoReducer";
import { statusReducer } from "./statusReducer";
import { settingsReducer } from "./settingsReducer";
import { groupReducer } from "./groupReducer";
import global from "./globalReducer";

export default combineReducers({
  user: authReducer,
  clients: authClientReducer,
  devices: devicesReducer,
  shared_devices: sharedDevicesReducer,
  scheduledDevices: scheduledDevicesReducer,
  createMultipleDeviceScheduler: createMultipleDeviceSchedulerReducer,
  scheduledDevicesLogs: scheduledDevicesLogsReducer,
  editDevice: editDevicesReducer,
  updateDeviceFirmware: updateDeviceFirmwareReducer,
  deleteDevice: deleteDeviceReducer,
  spaces: spacesReducer,
  shared_space_devices: sharedSpaceDevicesReducer,
  getDevicesInSpace: getDevicesInSpaceReducer,
  addDevicesToSpace: addDevicesToSpaceReducer,
  deleteDevicesInSpace: deleteDeviceInSpaceReducer,
  group: groupReducer,
  users: userReducer,
  tokens: authTokenReducer,
  deviceTypes: deviceTypesReducer,
  defaultDeviceType: deviceDefaultTypesReducer,
  defaultCapatibility: deviceDefaultCapatibilityReducer,
  deviceType: createTypeReducer,
  deviceAdminType: deviceAdminTypesReducer,
  commands: getCommandsReducer,
  deviceslist: getDevicesReducer,
  whitelistDevices: getWhitelistDeviceReducer,
  addedWhitelistDevices: createWhitelistReducer,
  blockedDevice: blockDeviceReducer,
  authUser: authSendOtpReducer,
  otpData: authVerifyOtpReducer,
  forgotPass: forgotPassReducer,
  deviceManufacturers: deviceManufacturerReducer,
  certLogs: certLogsReducer,
  versionInfo: versionInfoReducer,
  statusInfo: statusReducer,
  settingsInfo: settingsReducer,
  global,
});
