import React from "react";
import { Redirect } from "react-router";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import routes from "../../routes";
import Footer from "./Footer";
import Header from "./Header";
import SideNav from "./SideNav";
import { GetLocalStorage } from "../../services/SMILocalStorage";
import socket from "../../helper/socket";

const BaseLayout = (props) => {
  /**
   * check if logged in
   */
  const isAuthenticate = () => {
    return JSON.parse(localStorage.getItem("smi-user"));
  };


  const userDetails = GetLocalStorage("smi-user");

  if (isAuthenticate()) {
    // Socket configuration
    const tokenSocket = isAuthenticate();
    socket.setupSocket(tokenSocket.access_token);
    const CustomRoute = routes.filter(
      (r) => r.isAdmin === get(userDetails, "isAdmin", false)
    );
    return (
      <div
        style={{ height: "100%" }}
        className="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
      >
        <div className="m-grid m-grid--hor m-grid--root m-page">
          <Header {...props} />
          <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
            <SideNav {...props} routes={CustomRoute}></SideNav>
            <Switch>
              {CustomRoute.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={Math.random()}
                    path={route.path}
                    exact={route.excat}
                    render={(props) => {
                      return <route.component {...props} />;
                    }}
                  />
                ) : undefined;
              })}
              {get(userDetails, "isAdmin", false) ? (
                <Redirect from="/" to="/device" />
              ) : (
                  <Redirect from="/" to="/devices" />
              )}
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    );
  } else {
    /**
     * Commenting the following block to avoid confusion for the end-user
     *
    return (
    <Switch>
      <Redirect exact from='/status' to='/client-login' />
      <Redirect exact from='/spaces' to='/client-login' />
      <Redirect exact from='/change-password' to='/client-login' />
      <Redirect to="/login" />
    </Switch>);
    */
    return <Redirect to="/client-login" />;
  }
};

export default connect(null, null)(BaseLayout);
