import * as spacesService from "../services/SpacesService";
export const SPACES_FETCHED = "SPACES_FETCHED";
export const SPACE_CREATED = "SPACE_CREATED";
export const SPACE_DEVICES_ADDED = "SPACE_DEVICES_ADDED";
export const SPACE_DEVICES_FETCHED = "SPACE_DEVICES_FETCHED";
export const SPACE_DELETED = "SPACE_DELETED";
export const SPACE_DEVICE_DELETED = "SPACE_DEVICE_DELETED";
export const ADD_SPACE_SHARE_DEVICE = "ADD_SPACE_SHARE_DEVICE";
export const CREATE_SPACE_SHARE_DEVICE = "CREATE_SPACE_SHARE_DEVICE";
export const SHARED_WITH_USER = "SHARED_WITH_USER";
export const SPACES_REVOCATION = "SPACES_REVOCATION";
export const SPACES_SHARED_WITH_ME = "SPACES_SHARED_WITH_ME";
export const SHARED_SPACE_DEVICES_FETCHED = "SHARED_SPACE_DEVICES_FETCHED";
export const GET_SPACE_SHARED_CONTACT_LIST = "GET_SPACE_SHARED_CONTACT_LIST";

export const getSpacesAction = () => {
  return (dispatch) => {
    return spacesService.getSpaces().then((spaces) => {
      dispatch({
        type: SPACES_FETCHED,
        spaces: spaces.data || [],
      });
      return spaces;
    });
  };
};

export const getDevicesInSpaceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.getDevicesInSpace(spaceInfo).then((data) => {
      dispatch({
        type: SPACE_DEVICES_FETCHED,
        data,
      });
      return data;
    });
  };
};

export const getShareSpacesDeviceAction = async (spaceInfo) => {
  return (dispatch) => {
    return spacesService.getShareSpacesDevice(spaceInfo).then((data) => {
      dispatch({
        type: SHARED_SPACE_DEVICES_FETCHED,
        shared_space_devices: data.data || [],
      });
      return data;
    }).catch((error) => {
      return error;
    });
  };
};

export const getSpaceSharedContactListAction = async (contactList) => {
  return (dispatch) => {
    return spacesService.getSpaceSharedContactList(contactList).then((data) => {
      dispatch({
        type: GET_SPACE_SHARED_CONTACT_LIST,
        data,
      });
      return data;
    }).catch((error) => {
      return error;
    });
  };
};

export const createSpaceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.createSpace(spaceInfo).then((spaces) => {
      dispatch({
        type: SPACE_CREATED,
        spaces,
      });
      return spaces;
    });
  };
};

export const createSpaceShareDeviceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.createSpaceShareDevice(spaceInfo).then((spaces) => {
      dispatch({
        type: CREATE_SPACE_SHARE_DEVICE,
        spaces,
      });
      return spaces;
    }).catch((error) => {
      return error;
    });
  }
}

export const addDevicesToSpaceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.addDevicesToSpace(spaceInfo).then((spaces) => {
      dispatch({
        type: SPACE_DEVICES_ADDED,
        spaces,
      });
      return spaces;
    });
  };
};

export const deleteDeviceInSpaceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.deleteDeviceInSpace(spaceInfo).then((spaces) => {
      dispatch({
        type: SPACE_DEVICE_DELETED,
        spaces,
      });
      return spaces;
    });
  };
};

export const deleteSpaceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.deleteSpace(spaceInfo).then((spaces) => {
      dispatch({
        type: SPACE_DELETED,
        spaces,
      });
      return spaces;
    });
  };
};

export const shareSpaceDeviceAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.sharesSpaceDevices(spaceInfo).then((spaces) => {
      dispatch({
        type: ADD_SPACE_SHARE_DEVICE,
        spaces,
      });
      return spaces;
    }).catch((error) => {
      return error;
    })
  }
}

export const spaceSharedWithUser = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.spaceSharedWithUser(spaceInfo).then((spaces) => {
      dispatch({
        type: SHARED_WITH_USER,
        spaces,
      });
      return spaces;
    }).catch((error) => {
      return error;
    })
  }
}

export const sharedSpaceRevocationAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.sharedSpaceRevocation(spaceInfo).then((spaces) => {
      dispatch({
        type: SPACES_REVOCATION,
        spaces,
      });
      return spaces;
    }).catch((error) => {
      return error;
    })
  }
}

export const spaceShareWithMeAction = (spaceInfo) => {
  return (dispatch) => {
    return spacesService.spaceSharedWithMe(spaceInfo).then((spaces) => {
      dispatch({
        type: SPACES_SHARED_WITH_ME,
        spaces,
      });
      return spaces;
    }).catch((error) => {
      return error;
    })
  }
}

export const updateSharedSpaceDeviceStatusAction = (devices) => {
  return (dispatch) => {
    dispatch({
      type: SHARED_SPACE_DEVICES_FETCHED,
      shared_space_devices: devices,
    });
    return devices;
  };
};
