/* eslint-disable react/prop-types */
import { Formik } from "formik";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import LoadingSubmit from "../../components/LoadingSubmit";
import { saveManufacturer, editManufacturer } from "../../actions/deviceManufacturerActions";
import { getDefaultDeviceType } from "../../services/DeviceTypeService";
import globalHelper from '../../helper/globalHelper';
import Select from 'react-select';

const CreateUserModal = ({
    validations,
    saveManufacturer,
    editManufacturer,
    defaultValue,
    onHide,
    show,
}) => {

    const [deviceTypes, setDeviceTypes] = useState([]);

   useEffect(() => {
        getDefaultDeviceType().then(value => {
            value.forEach(element => {
                setDeviceTypes((types) => [...types, { value: element._id, label: element.name }]);
            });
        })
        // eslint-disable-next-line
    }, []);


    /**
    * Return create modal
    */
    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validations}
            onSubmit={(inputs, { setSubmitting, setErrors, resetForm }) => {
                const values = Object.assign({},inputs); 
                //Loader true
                values.deviceTypes = values.deviceTypes.map(element => element.value);
         
                setSubmitting(true)

                if (defaultValue._id) {
                    editManufacturer(values, defaultValue._id).then((result) => {
                        setSubmitting(false);
                        resetForm(defaultValue)
                        onHide();
                        globalHelper.setServerErrors(result, setErrors);
                    });
                } else {
                    saveManufacturer(values).then((result) => {
                        setSubmitting(false);
                        resetForm(defaultValue)
                        onHide();
                        globalHelper.setServerErrors(result, setErrors);
                    })
                }
            }}
            initialValues={defaultValue}>
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                setFieldTouched,
                errors,
                resetForm,
                setFieldValue,
                isSubmitting
            }) => (
                    <Modal
                        show={show}
                        onHide={onHide}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onShow={() => (show ? resetForm() : false)} >

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Add New Manufacturer
                        </Modal.Title>
                        </Modal.Header>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Row>
                                    {/* Name  */}
                                    <Form.Group as={Col} md="4" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" name="name"
                                            value={values.name} onChange={handleChange} isInvalid={touched.name && !!errors.name} />
                                        <Form.Control.Feedback type="invalid"> {errors.name} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Display name  */}
                                    <Form.Group as={Col} md="4" controlId="displayName">
                                        <Form.Label>Display name</Form.Label>
                                        <Form.Control type="text" placeholder="Display name" name="displayName"
                                            value={values.displayName} onChange={handleChange} isInvalid={touched.displayName && !!errors.displayName} />
                                        <Form.Control.Feedback type="invalid"> {errors.displayName} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Contact person name  */}
                                    <Form.Group as={Col} md="4" controlId="contactPersonName">
                                        <Form.Label>Contact person name</Form.Label>
                                        <Form.Control type="text" placeholder="Contact Person name" name="contactPersonName"
                                            value={values.contactPersonName} onChange={handleChange} isInvalid={touched.contactPersonName && !!errors.contactPersonName} />
                                        <Form.Control.Feedback type="invalid"> {errors.contactPersonName} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Business contact number  */}
                                    <Form.Group as={Col} md="6" controlId="businessContactNumber">
                                        <Form.Label>Business contact number</Form.Label>
                                        <Form.Control type="text" placeholder="Business contact number" name="businessContactNumber"
                                            value={values.businessContactNumber} onChange={handleChange} isInvalid={touched.businessContactNumber && !!errors.businessContactNumber} />
                                        <Form.Control.Feedback type="invalid"> {errors.businessContactNumber} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Business email  */}
                                    <Form.Group as={Col} md="6" controlId="businessEmail">
                                        <Form.Label>Business email</Form.Label>
                                        <Form.Control type="text" placeholder="Business email" name="businessEmail"
                                            value={values.businessEmail} onChange={handleChange} isInvalid={touched.businessEmail && !!errors.businessEmail} />
                                        <Form.Control.Feedback type="invalid"> {errors.businessEmail} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Support contact number  */}
                                    <Form.Group as={Col} md="6" controlId="supportContactNumber">
                                        <Form.Label>Support contact number</Form.Label>
                                        <Form.Control type="text" placeholder="Support contact number" name="supportContactNumber"
                                            value={values.supportContactNumber} onChange={handleChange} isInvalid={touched.supportContactNumber && !!errors.supportContactNumber} />
                                        <Form.Control.Feedback type="invalid"> {errors.supportContactNumber} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Support email  */}
                                    <Form.Group as={Col} md="6" controlId="supportEmail">
                                        <Form.Label>Support email</Form.Label>
                                        <Form.Control type="text" placeholder="Support email" name="supportEmail"
                                            value={values.supportEmail} onChange={handleChange} isInvalid={touched.supportEmail && !!errors.supportEmail} />
                                        <Form.Control.Feedback type="invalid"> {errors.supportEmail} </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* Manufacturer  */}
                                    <Form.Group as={Col} md="12" controlId="deviceTypes">
                                        <Form.Label>Device types </Form.Label>
                                        <Select
                                            name="deviceTypes"
                                            value={values.deviceTypes}
                                            onChange={value =>  setFieldValue("deviceTypes", value)}                                            
                                            options={deviceTypes}
                                            isMulti={true}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <LoadingSubmit isLoading={isSubmitting} text="Save" />
                                <Button variant="danger" onClick={onHide}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
        </Formik>
    );
};

export default connect('', {
    saveManufacturer,
    editManufacturer
})(CreateUserModal);
