import * as Yup from "yup";

export const validate = Yup.object({
    oldPassword: Yup.string().required("Old Password is required."),
    newPassword: Yup.string().required("New Password is required.")
                             .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/,
                             "Password must be 8 characters long and having numbers, special character, Upper and Lower case character."),
    confirmPassword: Yup.string().required("Confirm Password is required.")
                                 .oneOf([Yup.ref('newPassword'), null], 'Passwords need to match!')
})

export const defaultValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
};

