import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import { MultiSelect } from "react-multi-select-component";
import { Typography } from "@material-ui/core";
import { SketchPicker } from "react-color";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import LoadingSubmit from "../LoadingSubmit";
import {
    DEVICE_TYPE_SOCKET,
    DEVICE_TYPE_TEMP_SENSOR,
    DEVICE_TYPE_SWITCH_4,
    DEVICE_TYPE_SWITCH_2,
    DEVICE_TYPE_SWITCH,
    DEVICE_TYPE_FAN,
    DEVICE_TYPE_LIGHT_LW,
    DEVICE_TYPE_LIGHT_LWCT,
    DEVICE_TYPE_LIGHT_LRGB,
    DEVICE_TYPE_LIGHT_LRGBW,
    DEVICE_TYPE_LIGHT_LRGBWCT
} from "../../helper/constants";
import globalHelper from "../../helper/globalHelper";
import socket from "../../helper/socket";
import { DimmingSlider, ToneSlider } from "../../containers/devices/Sliders";


const SpaceDevicesMultipleDeviceControl = ({
    show, onHide, devices,
    shared_space_devices,
    validations, defaultValue,
    refreshComponent, spaceIdCondition,
    sharedSpaceDevicesCondition
}) => {

    const [spaceInfoDevices, setSpaceInfoDevices] = useState([]);
    const [devicesOptions, setDevicesOptions] = useState([]);
    const [showSpeedControl, setShowSpeedControl] = useState(false);
    const [showBrightnessControl, setShowBrightnessControl] = useState(false);
    const [showTemperatureControl, setShowTemperatureControl] = useState(false);
    const [showRGBControl, setShowRGBControl] = useState(false);


    useEffect(() => {
        if (sharedSpaceDevicesCondition === true) {
            shared_space_devices.forEach((sharedSpaceDevicesData) => {
                devicesOptions.push({
                    label: sharedSpaceDevicesData.spaceId !== undefined ? `${sharedSpaceDevicesData.instanceId.name} (${sharedSpaceDevicesData.deviceMacId}) - ${sharedSpaceDevicesData.spaceId.name}` : `${sharedSpaceDevicesData.instanceId.name} (${sharedSpaceDevicesData.deviceMacId})`,
                    value: sharedSpaceDevicesData.deviceMacId,
                    disabled: sharedSpaceDevicesData.instanceId.online ? false : true
                })
            });
        }
        else if (sharedSpaceDevicesCondition === undefined) {
            const spaceDevicesData = devices.filter((item) => item?.spaceId?._id === spaceIdCondition);
            if (spaceDevicesData.length > 0) {
                setSpaceInfoDevices(spaceDevicesData);
                spaceDevicesData.forEach((devicesData) => {
                    devicesOptions.push({
                        label: devicesData.spaceId !== undefined ? `${devicesData.instanceId.name} (${devicesData.deviceMacId}) - ${devicesData.spaceId.name}` : `${devicesData.instanceId.name} (${devicesData.deviceMacId})`,
                        value: devicesData.deviceMacId,
                        disabled: devicesData.instanceId.online ? false : true
                    })
                });
            }
        }

    }, []);

    const devicesFilterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
    };

    const devicesCustomValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => label.split(" (")[0]).toString()
            : "Select...";
    };

    const handleSpaceDevicesControl = (devicesSelected) => {

        setShowSpeedControl(false);
        setShowBrightnessControl(false);
        setShowTemperatureControl(false);
        setShowRGBControl(false);
        if (devicesSelected && devicesSelected.length > 0) {
            devicesSelected.forEach((selectedMacId) => {
                if (sharedSpaceDevicesCondition === true) {
                shared_space_devices.forEach((deviceDetailObj) => {
                    if ((selectedMacId.value === deviceDetailObj.deviceMacId) && (deviceDetailObj.deviceType.name === DEVICE_TYPE_FAN)) {
                        setShowSpeedControl(true);
                    }
                    if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                        (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LW ||
                            deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                            deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                            deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                        setShowBrightnessControl(true);
                    }
                    if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                        (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                            deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                        setShowTemperatureControl(true);
                    }
                    if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                        (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGB ||
                            deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                            deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                        setShowRGBControl(true);
                    }
                });
                }
                else if (sharedSpaceDevicesCondition === undefined) {
                    devices.forEach((deviceDetailObj) => {
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) && (deviceDetailObj.deviceType.name === DEVICE_TYPE_FAN)) {
                            setShowSpeedControl(true);
                        }
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                            (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LW ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                            setShowBrightnessControl(true);
                        }
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                            (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                            setShowTemperatureControl(true);
                        }
                        if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
                            (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGB ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
                            setShowRGBControl(true);
                        }
                    });
                }
            })
        }
        else {
            setShowBrightnessControl(false);
            setShowTemperatureControl(false);
            setShowRGBControl(false);
            setShowSpeedControl(false);
        }

    };

    const handleSubmit = async (evt, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        let newDeviceMacId = [];
        evt.devices.map((ids) => {
            newDeviceMacId.push({ label: ids.label.split(" (")[0], value: ids.value });
        });
        evt.devices = newDeviceMacId;
        evt.devices.forEach(async (selectedDevices) => {
            if (sharedSpaceDevicesCondition === true) {
            shared_space_devices.forEach(async (findDeviceDetail) => {
                if (findDeviceDetail.deviceMacId === selectedDevices.value) {
                    findDeviceDetail.pageName = "Rules";
                    let sendFanSpeedValue = undefined;
                    let payload;
                    findDeviceDetail.requestedDeviceStatus = evt.deviceStatus;
                    if (findDeviceDetail.deviceType.name === DEVICE_TYPE_FAN) {
                        sendFanSpeedValue = Number(evt.fanSpeed);
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, sendFanSpeedValue);
                    }
                    else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_4 ||
                        findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_2 ||
                        findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH ||
                        findDeviceDetail.deviceType.name === DEVICE_TYPE_TEMP_SENSOR ||
                        findDeviceDetail.deviceType.name === DEVICE_TYPE_SOCKET) {
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail);
                    }
                    else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LW) {
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness);
                    }
                    else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LWCT) {
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature);
                    }
                    else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGB) {
                        const RGBObj = {
                            r: evt.r,
                            g: evt.g,
                            b: evt.b
                        };
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, undefined, undefined, RGBObj);
                    }
                    else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW) {
                        const RGBObj = {
                            r: evt.r,
                            g: evt.g,
                            b: evt.b
                        };
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, undefined, RGBObj);
                    }
                    else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
                        const RGBObj = {
                            r: evt.r,
                            g: evt.g,
                            b: evt.b
                        };
                        payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature, RGBObj);
                    }
                    socket.io.emit("deviceDetail-v1", payload);
                }
            });
            }
            else if (sharedSpaceDevicesCondition === undefined) {
                devices.forEach(async (findDeviceDetail) => {
                    if (findDeviceDetail.deviceMacId === selectedDevices.value) {
                        findDeviceDetail.pageName = "Rules";
                        let sendFanSpeedValue = undefined;
                        let payload;
                        findDeviceDetail.requestedDeviceStatus = evt.deviceStatus;
                        if (findDeviceDetail.deviceType.name === DEVICE_TYPE_FAN) {
                            sendFanSpeedValue = Number(evt.fanSpeed);
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, sendFanSpeedValue);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_4 ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_2 ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_TEMP_SENSOR ||
                            findDeviceDetail.deviceType.name === DEVICE_TYPE_SOCKET) {
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LW) {
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LWCT) {
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGB) {
                            const RGBObj = {
                                r: evt.r,
                                g: evt.g,
                                b: evt.b
                            };
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, undefined, undefined, RGBObj);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW) {
                            const RGBObj = {
                                r: evt.r,
                                g: evt.g,
                                b: evt.b
                            };
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, undefined, RGBObj);
                        }
                        else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
                            const RGBObj = {
                                r: evt.r,
                                g: evt.g,
                                b: evt.b
                            };
                            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature, RGBObj);
                        }
                        socket.io.emit("deviceDetail-v1", payload);
                    }
                });
            }
        })
        onHide();
        setSubmitting(false);
        refreshComponent();

    };


    return (

        <>
            <Formik
                enableReinitialize={true}
                validationSchema={validations}
                onSubmit={handleSubmit}
                initialValues={defaultValue}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    setFieldTouched,
                    errors,
                    resetForm,
                    setFieldValue,
                    isSubmitting
                }) => (
                    <Modal
                        show={show}
                        onHide={onHide}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered
                        onShow={() => (show ? resetForm() : false)} >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Multiple Device Control
                            </Modal.Title>
                        </Modal.Header>
                        <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
                        <Form noValidate
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body className="show-grid">
                                <Form.Row>
                                    <Form.Label as={Col} md="2" className="mt-2">Target Devices <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Group as={Col} md="9" controlId="devices">
                                        <MultiSelect
                                            name="devices"
                                            className="m-input"
                                            options={devicesOptions}
                                            value={values.devices}
                                            valueRenderer={devicesCustomValueRenderer}
                                            filterOptions={devicesFilterOptions}
                                            onChange={(devicesArray) => {
                                                setFieldValue("devices", devicesArray);
                                                handleSpaceDevicesControl(devicesArray);
                                            }}
                                            labelledBy="Select"
                                            isInvalid={touched.devices && !!errors.devices}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {" "}
                                            {errors.devices}{" "}
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                            Note <InfoOutlined fontSize='medium' viewBox="0 0 35 9" color='action' /> Offline devices are disabled.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Label as={Col} md="2" className="mt-2">On/Off</Form.Label>
                                    <Form.Group as={Col} md="9" controlId="deviceStatus">
                                        <label className="switch">
                                            <input
                                                name="deviceStatus"
                                                type="checkbox"
                                                checked={values.deviceStatus}
                                                onChange={(status) => {
                                                    setFieldValue("deviceStatus", status.target.checked);
                                                }}
                                                value={values.deviceStatus}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </Form.Group>
                                    {showSpeedControl &&
                                        (<> <Form.Label as={Col} md="2">Speed</Form.Label>
                                            <Form.Group as={Col} md="3" controlId="deviceStatus">
                                                <div className="speedslider mt-4">
                                                    <input
                                                        name="speed"
                                                        type="range"
                                                        value={values.fanSpeed}
                                                        min="1"
                                                        max="5"
                                                        step="1"
                                                        className="slider"
                                                        id="myRange"
                                                        onChange={(speedValue) => {
                                                            setFieldValue("fanSpeed", speedValue.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Label as={Col} md="4">{values.fanSpeed}</Form.Label>
                                            <Form.Label as={Col} md="3"></Form.Label>
                                        </>)}
                                    {showBrightnessControl && (<>
                                        <Form.Label as={Col} md="2" className="mt-2">Dimming : {values.brightness}%</Form.Label>
                                        <Form.Group as={Col} md="9" controlId="brightness">
                                            <Typography style={{ width: "100%" }}>
                                                <DimmingSlider
                                                    onChangeCommitted={(event, value) => {
                                                        setFieldValue("brightness", value);
                                                    }}
                                                    min={1}
                                                    max={100}
                                                    value={values.brightness}
                                                />
                                            </Typography>
                                        </Form.Group>
                                    </>)}
                                    {showTemperatureControl && (<>
                                        <Form.Label as={Col} md="2" className="mt-3">Tone : {values.temperature}%</Form.Label>
                                        <Form.Group as={Col} md="9" controlId="temperature">
                                            <Typography style={{ width: "100%" }}>
                                                <ToneSlider
                                                    min={1}
                                                    max={100}
                                                    value={values.temperature}
                                                    onChangeCommitted={(event, value) => {
                                                        setFieldValue("temperature", value);
                                                    }}
                                                />
                                            </Typography>
                                        </Form.Group>
                                    </>)}
                                    {showRGBControl && (<>
                                        <Form.Label as={Col} md="2">Color Picker</Form.Label>
                                        <Form.Group as={Col} md="9" controlId="rgb">
                                            <Typography style={{ width: "25%" }}>
                                                <SketchPicker
                                                    color={{ r: values.r, g: values.g, b: values.b }}
                                                    disableAlpha={true}
                                                    onChangeComplete={(e) => {
                                                        setFieldValue("r", e.rgb.r);
                                                        setFieldValue("g", e.rgb.g);
                                                        setFieldValue("b", e.rgb.b);
                                                    }}
                                                />
                                            </Typography>
                                        </Form.Group>
                                    </>)}
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <LoadingSubmit isLoading={isSubmitting} text="Save" />
                                <Button variant="danger" onClick={onHide}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>

    )
}

const mapStateToProps = state => ({
    devices: state.devices,
    shared_space_devices: state.shared_space_devices
})

export default connect(mapStateToProps, {
})(SpaceDevicesMultipleDeviceControl);