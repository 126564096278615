import axios from "axios";
import apiurl from "../config/api";
import { get } from "lodash";
import {
  SetLocalStorage,
  removeLocalStorage,
  GetLocalStorage,
} from "./SMILocalStorage";

export function login(credentials) {
  return axios
    .post(apiurl.loginUrl, credentials)
    .then((result) => {
      SetLocalStorage("smi-user", { ...result.data.data, isAdmin: true });
      removeLocalStorage("client-logout");
      return result.data.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function logout() {
  const userDetails = GetLocalStorage("smi-user");

  if (!get(userDetails, "isAdmin", false)) {
    SetLocalStorage("client-logout", true);
  }
  removeLocalStorage("smi-user");
  return true;
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function forgotPassword(credentials) {
  axios.post(apiurl.forgotPassword, credentials).then((result) => {
    return result.data;
  });
}

export function clientLogin(credentials) {
  removeLocalStorage("smi-user");
  return axios
    .post(apiurl.clientLoginUrl, credentials)
    .then((result) => {
      console.log(result);
      SetLocalStorage("smi-user", { ...result.data.data, isAdmin: false });
      return result.data.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function clientSendOtp(credentials) {
  return axios
    .post(apiurl.clientResetPwdSendOtpUrl, credentials)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function clientVerifyOtp(credentials) {
  return axios
    .post(apiurl.clientResetPwdVerifyOtpUrl, credentials)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function clientResetPassword(credentials) {
  return axios
    .post(apiurl.clientResetPasswordUrl, credentials)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getCountryCodesList() {
  return axios
    .get(apiurl.getCountryCodes)
    .then((result) => {
      return result.data;
    }).catch((error) => {
      return error.response.data;
    });
}

export function deleteUserAccount(userId) {
  return axios
    .delete(`${apiurl.deleteUser}/${userId}`)
    .then((result) => {
      return result.data;
    }).catch((error) => {
      return error.response.data;
    });
}
