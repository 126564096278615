import * as statusService from '../services/StatusService'
export const STATUS_LOADED = 'STATUS_LOADED'

export const getStatusAction = () => {
  return (dispatch) => {   
    return statusService.getStatus()
      .then((status) => {
        dispatch({
          type: STATUS_LOADED,
          status
        })
        return status
      })
  }
}