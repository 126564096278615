import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { Typography } from "@material-ui/core";
import { SketchPicker } from "react-color";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import LoadingSubmit from "../LoadingSubmit";
import {
  DEVICE_TYPE_SOCKET,
  DEVICE_TYPE_TEMP_SENSOR,
  DEVICE_TYPE_SWITCH_4,
  DEVICE_TYPE_SWITCH_2,
  DEVICE_TYPE_SWITCH,
  DEVICE_TYPE_FAN,
  DEVICE_TYPE_LIGHT_LW,
  DEVICE_TYPE_LIGHT_LWCT,
  DEVICE_TYPE_LIGHT_LRGB,
  DEVICE_TYPE_LIGHT_LRGBW,
  DEVICE_TYPE_LIGHT_LRGBWCT
} from "../../helper/constants";
import globalHelper from "../../helper/globalHelper";
import socket from "../../helper/socket";
import { getSpacesSharedWithMeDevicesAction } from "../../actions/devicesActions";
import { DimmingSlider, ToneSlider } from "../../containers/devices/Sliders";

const MultipleDeviceControlModal = ({
  show, onHide, devices, spaces,
  validations, defaultValue,
  refreshComponent, getSpacesSharedWithMeDevicesAction }) => {

  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [devicesOptions, setDevicesOptions] = useState([]);
  const [spacesOptions, setSpacesOptions] = useState([]);
  const [spacesSharedWithMeData, setSpacesSharedWithMeData] = useState([]);
  const [showSpeedControl, setShowSpeedControl] = useState(false);
  const [showBrightnessControl, setShowBrightnessControl] = useState(false);
  const [showTemperatureControl, setShowTemperatureControl] = useState(false);
  const [showRGBControl, setShowRGBControl] = useState(false);

  useEffect(() => {
    callUseEffect();
  }, []);

  const callUseEffect = async () => {
    devices.forEach((ownerDevices) => {
      deviceTypeOptions.push({
        label: ownerDevices.deviceType.name,
        value: ownerDevices.deviceType.name
      })
    });
    let uniqueDeviceTypeOptions = [
      ...new Map(deviceTypeOptions.map((item) => [item["value"], item])).values(),
    ];
    setDeviceTypeOptions(uniqueDeviceTypeOptions);
    const getSpacesSharedWithMeData = await getSpacesSharedWithMeDevicesAction();
    getSpacesSharedWithMeData.data.forEach((spacesSharedDevices) => {
      deviceTypeOptions.push({
        label: spacesSharedDevices.deviceType.name,
        value: spacesSharedDevices.deviceType.name
      })
    });
    let uniqueSpacesSharedWithMeOptions = [
      ...new Map(deviceTypeOptions.map((item) => [item["value"], item])).values(),
    ];
    setDeviceTypeOptions(uniqueSpacesSharedWithMeOptions);
    if (getSpacesSharedWithMeData.status === 200) {
      setSpacesSharedWithMeData(getSpacesSharedWithMeData.data);
    }
  }

  const handleDeviceType = (deviceTypeValue, setFieldValue) => {
    let selectedTypeDevicesValue = [];
    devicesOptions.length = 0;
    setFieldValue("devices", []);
    devices.forEach((devicesData) => {
      if (deviceTypeValue === devicesData.deviceType.name) {
        if (devicesData.instanceId.online) {

          selectedTypeDevicesValue.push({
            label: devicesData.spaceId !== undefined ? `${devicesData.instanceId.name} (${devicesData.deviceMacId}) - ${devicesData.spaceId.name}` : `${devicesData.instanceId.name} (${devicesData.deviceMacId})`,
            value: devicesData.deviceMacId
          })
          setFieldValue("devices", selectedTypeDevicesValue);
        }

        devicesOptions.push({
          label: devicesData.spaceId !== undefined ? `${devicesData.instanceId.name} (${devicesData.deviceMacId}) - ${devicesData.spaceId.name}` : `${devicesData.instanceId.name} (${devicesData.deviceMacId})`,
          value: devicesData.deviceMacId,
          disabled: devicesData.instanceId.online ? false : true
        })
      }
    });

    spacesSharedWithMeData.forEach((spacesSharedWithMeItem) => {
      if (deviceTypeValue === spacesSharedWithMeItem.deviceType.name) {
        if (spacesSharedWithMeItem.instanceId.online) {
          selectedTypeDevicesValue.push({
            label: spacesSharedWithMeItem.spaceId !== undefined ? `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) - ${spacesSharedWithMeItem.spaceId.name} : (isShared)` : `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) : (isShared)`,
            value: spacesSharedWithMeItem.deviceMacId
          })
          setFieldValue("devices", selectedTypeDevicesValue);
        }

        devicesOptions.push({
          label: spacesSharedWithMeItem.spaceId !== undefined ? `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) - ${spacesSharedWithMeItem.spaceId.name} : (isShared)` : `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) : (isShared)`,
          value: spacesSharedWithMeItem.deviceMacId,
          disabled: spacesSharedWithMeItem.instanceId.online ? false : true
        })
      }
    });

    if (deviceTypeValue === DEVICE_TYPE_FAN) {
      setShowSpeedControl(true);
    }
    else {
      setShowSpeedControl(false);
    }
    if (deviceTypeValue === DEVICE_TYPE_LIGHT_LW ||
      deviceTypeValue === DEVICE_TYPE_LIGHT_LWCT ||
      deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBW ||
      deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBWCT) {
      setShowBrightnessControl(true);
    }
    else {
      setShowBrightnessControl(false);
    }
    if (deviceTypeValue === DEVICE_TYPE_LIGHT_LWCT ||
      deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBWCT) {
      setShowTemperatureControl(true);
    }
    else {
      setShowTemperatureControl(false);
    }
    if (deviceTypeValue === DEVICE_TYPE_LIGHT_LRGB ||
      deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBW ||
      deviceTypeValue === DEVICE_TYPE_LIGHT_LRGBWCT) {
      setShowRGBControl(true);
    }
    else {
      setShowRGBControl(false);
    }
  }

  const handleSpacesFieldOptions = (schedulerTypeValue) => {
    if (schedulerTypeValue === "space") {
      devicesOptions.length = 0;
      spacesOptions.length = 0;
      setShowSpeedControl(false);
      setShowBrightnessControl(false);
      setShowTemperatureControl(false);
      setShowRGBControl(false);
      spaces.forEach((spacesData) => {
        spacesOptions.push({
          label: spacesData.name,
          value: spacesData._id
        })
      });
      spacesSharedWithMeData.forEach((spacesSharedWithMeItem) => {
        spacesOptions.push({
          label: `${spacesSharedWithMeItem.spaceId.name} (isShared)`,
          value: spacesSharedWithMeItem.spaceId._id
        })
      });
      let uniqueSpacesOptions = [
        ...new Map(spacesOptions.map((item) => [item["value"], item])).values(),
      ];
      setSpacesOptions(uniqueSpacesOptions); 
    }
    else if (schedulerTypeValue === "type") {
      devicesOptions.length = 0;
      spacesOptions.length = 0;
      setShowSpeedControl(false);
      setShowBrightnessControl(false);
      setShowTemperatureControl(false);
      setShowRGBControl(false);
    }
  }

  const handleSpaceDevicesFieldOptions = (onChangeSpacesArr, setFieldValue, value) => {
    let selectedSpaceDevicesValue = [];
    devicesOptions.length = 0;
    setFieldValue("devices", []);
    setShowSpeedControl(false);
    setShowBrightnessControl(false);
    setShowTemperatureControl(false);
    setShowRGBControl(false);
    /* if (actionClear.action === "clear" || onChangeSpacesArr === null) {
      setFieldValue("devices", []);
    } */
    onChangeSpacesArr != null && onChangeSpacesArr.forEach((spacesElement) => {
      devices.forEach((ownerDevices) => {
        if ((ownerDevices.spaceId) && (ownerDevices.spaceId._id === spacesElement.value)) {
          if (ownerDevices.instanceId.online) {
            selectedSpaceDevicesValue.push({
              label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
              value: ownerDevices.deviceMacId
            })
            setFieldValue("devices", selectedSpaceDevicesValue);
            handleSpaceDevicesControl(selectedSpaceDevicesValue, "space");
          }
          devicesOptions.push({
            label: ownerDevices.spaceId !== undefined ? `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId}) - ${ownerDevices.spaceId.name}` : `${ownerDevices.instanceId.name} (${ownerDevices.deviceMacId})`,
            value: ownerDevices.deviceMacId,
            disabled: ownerDevices.instanceId.online ? false : true
          });
        }
      });
      spacesSharedWithMeData.forEach((spacesSharedWithMeItem) => {
        if ((spacesSharedWithMeItem.spaceId) && (spacesSharedWithMeItem.spaceId._id === spacesElement.value)) {
          if (spacesSharedWithMeItem.instanceId.online) {
            selectedSpaceDevicesValue.push({
              label: spacesSharedWithMeItem.spaceId !== undefined ? `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) - ${spacesSharedWithMeItem.spaceId.name} : (isShared)` : `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) : (isShared)`,
              value: spacesSharedWithMeItem.deviceMacId
            })
            setFieldValue("devices", selectedSpaceDevicesValue);
            handleSpacesSharedWithMeDevicesControl(selectedSpaceDevicesValue, "space");
          }
          devicesOptions.push({
            label: spacesSharedWithMeItem.spaceId !== undefined ? `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) - ${spacesSharedWithMeItem.spaceId.name} : (isShared)` : `${spacesSharedWithMeItem.instanceId.name} (${spacesSharedWithMeItem.deviceMacId}) : (isShared)`,
            value: spacesSharedWithMeItem.deviceMacId,
            disabled: spacesSharedWithMeItem.instanceId.online ? false : true
          });

        }
      });
    })
  }

  const handleSpaceDevicesControl = (devicesSelected, basedOnChecked) => {
    if (basedOnChecked === "space") {
      setShowSpeedControl(false);
      setShowBrightnessControl(false);
      setShowTemperatureControl(false);
      setShowRGBControl(false);
      if (devicesSelected && devicesSelected.length > 0) {
        devicesSelected.forEach((selectedMacId) => {
          devices.forEach((deviceDetailObj) => {
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) && (deviceDetailObj.deviceType.name === DEVICE_TYPE_FAN)) {
              setShowSpeedControl(true);
            }
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
              (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LW ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
              setShowBrightnessControl(true);
            }
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
              (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
              setShowTemperatureControl(true);
            }
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
              (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGB ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
              setShowRGBControl(true);
            }
          })
        })
      }
      else {
        setShowBrightnessControl(false);
        setShowTemperatureControl(false);
        setShowRGBControl(false);
        setShowSpeedControl(false);
      }
    }
  };

  const handleSpacesSharedWithMeDevicesControl = (devicesSelected, basedOnChecked) => {
    if (basedOnChecked === "space") {
      setShowSpeedControl(false);
      setShowBrightnessControl(false);
      setShowTemperatureControl(false);
      setShowRGBControl(false);
      if (devicesSelected && devicesSelected.length > 0) {
        devicesSelected.forEach((selectedMacId) => {
          spacesSharedWithMeData.forEach((deviceDetailObj) => {
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) && (deviceDetailObj.deviceType.name === DEVICE_TYPE_FAN)) {
              setShowSpeedControl(true);
            }
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
              (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LW ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
              setShowBrightnessControl(true);
            }
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
              (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LWCT ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
              setShowTemperatureControl(true);
            }
            if ((selectedMacId.value === deviceDetailObj.deviceMacId) &&
              (deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGB ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW ||
                deviceDetailObj.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT)) {
              setShowRGBControl(true);
            }
          })
        })
      }
      else {
        setShowBrightnessControl(false);
        setShowTemperatureControl(false);
        setShowRGBControl(false);
        setShowSpeedControl(false);
      }
    }
  }

  const devicesCustomStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: '80px',
      overflowY: state.hasValue ? 'scroll' : 'hidden',
    }),
  }

  const spacesFilterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
  };

  const spacesCustomValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => label.split(" (")[0]).toString()
      : "Select...";
  };

  const devicesFilterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
  };

  const devicesCustomValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => label.split(" (")[0]).toString()
      : "Select...";
  };

  const handleSubmit = async (evt, { setSubmitting, setErrors, resetForm }) => {
    setSubmitting(true);
    let newDeviceMacId = [];
    evt.devices.map((ids) => {
      newDeviceMacId.push({ label: ids.label.split(" (")[0], value: ids.value });
    });
    evt.devices = newDeviceMacId;
    evt.devices.forEach(async (selectedDevices) => {
      devices.forEach(async (findDeviceDetail) => {
        if (findDeviceDetail.deviceMacId === selectedDevices.value) {
          findDeviceDetail.pageName = "Rules";
          let sendFanSpeedValue = undefined;
          let payload;
          findDeviceDetail.requestedDeviceStatus = evt.deviceStatus;
          if (findDeviceDetail.deviceType.name === DEVICE_TYPE_FAN) {
            sendFanSpeedValue = Number(evt.fanSpeed);
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, sendFanSpeedValue);
          }
          else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_4 ||
            findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH_2 ||
            findDeviceDetail.deviceType.name === DEVICE_TYPE_SWITCH ||
            findDeviceDetail.deviceType.name === DEVICE_TYPE_TEMP_SENSOR ||
            findDeviceDetail.deviceType.name === DEVICE_TYPE_SOCKET) {
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail);
          }
          else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LW) {
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness);
          }
          else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LWCT) {
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature);
          }
          else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGB) {
            const RGBObj = {
              r: evt.r,
              g: evt.g,
              b: evt.b
            };
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, undefined, undefined, RGBObj);
          }
          else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBW) {
            const RGBObj = {
              r: evt.r,
              g: evt.g,
              b: evt.b
            };
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, undefined, RGBObj);
          }
          else if (findDeviceDetail.deviceType.name === DEVICE_TYPE_LIGHT_LRGBWCT) {
            const RGBObj = {
              r: evt.r,
              g: evt.g,
              b: evt.b
            };
            payload = await globalHelper.getDeviceSocketPayload(findDeviceDetail, evt.brightness, evt.temperature, RGBObj);
          }
          socket.io.emit("deviceDetail-v1", payload);
        }
      })
    })
    onHide();
    setSubmitting(false);
    refreshComponent();

  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validations}
        onSubmit={handleSubmit}
        initialValues={defaultValue}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          setFieldTouched,
          errors,
          resetForm,
          setFieldValue,
          isSubmitting
        }) => (
          <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            onShow={() => (show ? resetForm() : false)} >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Multiple Device Control
              </Modal.Title>
            </Modal.Header>
            <div style={{ padding: "25px 25px 0px 25px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
            <Form noValidate
              onSubmit={handleSubmit}
            >
              <Modal.Body className="show-grid">
                <Form.Row>
                  <Form.Label as={Col} md="2" className="mt-1">Based On</Form.Label>
                  <Form.Group as={Col} md="3" className="mt-1 form-group col-md-3 d-flex align-items-center radio-button--align" controlId="basedOn">
                    <Form.Label className="m-0">Device Type</Form.Label>
                    <Form.Control
                      className="m-0"
                      type="radio"
                      name="basedOn"
                      style={{ cursor: "pointer" }}
                      value="type"
                      checked={values.basedOn === "type" ? true : false}
                      onChange={(onChangeControlBy) => {
                        setFieldValue("basedOn", onChangeControlBy.target.value);
                        handleSpacesFieldOptions(onChangeControlBy.target.value);
                        setFieldValue("devices", []);
                        setFieldValue("spacesSelectedValue", []);
                        setFieldValue("deviceType", '');
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" className="mt-1 form-group col-md-3 d-flex align-items-center radio-button--align" controlId="basedOn">
                    <Form.Label className="m-0">Spaces</Form.Label>
                    <Form.Control
                      className="m-0"
                      type="radio"
                      name="basedOn"
                      style={{ cursor: "pointer" }}
                      value="space"
                      checked={values.basedOn === "space" ? true : false}
                      onChange={(onChangeControlBy) => {
                        setFieldValue("basedOn", onChangeControlBy.target.value);
                        handleSpacesFieldOptions(onChangeControlBy.target.value);
                        setFieldValue("devices", []);
                        setFieldValue("spacesSelectedValue", []);
                      }}
                    />

                  </Form.Group>
                  <Form.Label as={Col} md="3" className="mt-2"></Form.Label>
                  {(values.basedOn === "type") &&
                    (<>
                    <Form.Label as={Col} md="2" className="mt-2">Device Type <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Group as={Col} md="9" className="mr-2" controlId="deviceType">
                    <Select
                      onChange={(deviceTypeValue) => {
                        setFieldValue("devices", '');
                        setFieldValue("deviceType", deviceTypeValue.value);
                          handleDeviceType(deviceTypeValue.value, setFieldValue);
                      }}
                      options={deviceTypeOptions}
                      isInvalid={touched.deviceType && !!errors.deviceType}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.deviceType}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                    </>)
                  }
                  {(values.basedOn === "space") &&
                    (<>
                      <Form.Label as={Col} md="2" className="mt-2">Spaces <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Group as={Col} md="9" className="mr-3" controlId="spacesSelectedValue">
                      <MultiSelect
                        name="spacesSelectedValue"
                        className="m-input"
                        options={spacesOptions}
                        value={values.spacesSelectedValue}
                        valueRenderer={spacesCustomValueRenderer}
                        filterOptions={spacesFilterOptions}
                        onChange={(onChangeSpacesSelected) => {
                          setFieldValue("spacesSelectedValue", onChangeSpacesSelected);
                          handleSpaceDevicesFieldOptions(onChangeSpacesSelected, setFieldValue, values.devices);
                        }}
                        labelledBy="Select"
                      />
                        <Form.Control.Feedback type="invalid">
                          {" "}
                        {(values.basedOn === "space") &&
                            (values.spacesSelectedValue == null || values.spacesSelectedValue.length == 0) ?
                            "spaces is a required field" : ""}
                          {" "}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>)
                  }

                  <Form.Label as={Col} md="2" className="mt-2">Target Devices <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Group as={Col} md="9" controlId="devices">
                    <MultiSelect
                      name="devices"
                      className="m-input"
                      options={devicesOptions}
                      value={values.devices}
                      valueRenderer={devicesCustomValueRenderer}
                      filterOptions={devicesFilterOptions}
                      onChange={(devicesArray) => {
                        setFieldValue("devices", devicesArray);
                        handleSpaceDevicesControl(devicesArray, values.basedOn);
                      }}
                      labelledBy="Select"
                      isInvalid={touched.devices && !!errors.devices}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {errors.devices}{" "}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      Note <InfoOutlined fontSize='medium' viewBox="0 0 35 9" color='action' /> Offline devices are disabled.
                    </Form.Text>
                  </Form.Group>
                  <Form.Label as={Col} md="2" className="mt-2">On/Off</Form.Label>
                  <Form.Group as={Col} md="9" controlId="deviceStatus">
                    <label className="switch">
                      <input
                        name="deviceStatus"
                        type="checkbox"
                        checked={values.deviceStatus}
                        onChange={(status) => {
                          setFieldValue("deviceStatus", status.target.checked);
                        }}
                        value={values.deviceStatus}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Form.Group>
                  {showSpeedControl &&
                    (<> <Form.Label as={Col} md="2">Speed</Form.Label>
                      <Form.Group as={Col} md="3" controlId="deviceStatus">
                        <div className="speedslider mt-4">
                          <input
                            name="speed"
                            type="range"
                            value={values.fanSpeed}
                            min="1"
                            max="5"
                            step="1"
                            className="slider"
                            id="myRange"
                            onChange={(speedValue) => {
                              setFieldValue("fanSpeed", speedValue.target.value);
                            }}
                          />
                      </div>
                      </Form.Group>
                      <Form.Label as={Col} md="4">{values.fanSpeed}</Form.Label>
                    <Form.Label as={Col} md="3"></Form.Label>
                    </>)}
                  {showBrightnessControl && (<>
                    <Form.Label as={Col} md="2" className="mt-2">Dimming : {values.brightness}%</Form.Label>
                    <Form.Group as={Col} md="9" controlId="brightness">
                      <Typography style={{ width: "100%" }}>
                        <DimmingSlider
                          onChangeCommitted={(event, value) => {
                            setFieldValue("brightness", value);
                          }}
                          min={1}
                          max={100}
                          value={values.brightness}
                        />
                      </Typography>
                    </Form.Group>
                  </>)}
                  {showTemperatureControl && (<>
                    <Form.Label as={Col} md="2" className="mt-3">Tone : {values.temperature}%</Form.Label>
                    <Form.Group as={Col} md="9" controlId="temperature">
                      <Typography style={{ width: "100%" }}>
                        <ToneSlider
                          min={1}
                          max={100}
                          value={values.temperature}
                          onChangeCommitted={(event, value) => {
                            setFieldValue("temperature", value);
                          }}
                        />
                      </Typography>
                    </Form.Group>
                  </>)}
                  {showRGBControl && (<>
                    <Form.Label as={Col} md="2">Color Picker</Form.Label>
                    <Form.Group as={Col} md="9" controlId="rgb">
                      <Typography style={{ width: "25%" }}>
                        <SketchPicker
                          color={{ r: values.r, g: values.g, b: values.b }}
                          disableAlpha={true}
                          onChangeComplete={(e) => {
                            setFieldValue("r", e.rgb.r);
                            setFieldValue("g", e.rgb.g);
                            setFieldValue("b", e.rgb.b);
                          }}
                        />
                      </Typography>
                    </Form.Group>
                  </>)}
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <LoadingSubmit isLoading={isSubmitting} text="Save" />
                <Button variant="danger" onClick={onHide}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => ({
  devices: state.devices,
  spaces: state.spaces
})

export default connect(mapStateToProps, {
  getSpacesSharedWithMeDevicesAction
})(MultipleDeviceControlModal);