import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { getDeviceTypeAction } from "../../../actions/deviceTypeActions";

class TokenList extends Component {
  componentDidMount() {
    this.props.getDeviceType();
  }

  render() {
    const { deviceTypes } = this.props;
    const columns = [
      {
        label: "No.",
        name: "",
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return tableMeta.rowIndex + 1;
          }
        }
      },
      {
        lable: "Device Type Name",
        name: "name",
        sortable: true
      },
      {
        label: "Device Type",
        name: "deviceType.name",
        sortable: true
      },
      {
        label: "Created By",
        name: "userId.name",
        sortable: true
      },
      {
        label: "Created On",
        name: "createdAt",
        sortable: true,
        options: {
          customBodyRender: value => {
            const localDate = moment
              .utc(value)
              .local()
              .format("DD-MM-YYYY hh:mm:s A");
            return localDate;
          }
        }
      }
    ];
    const data = deviceTypes && deviceTypes.length ? deviceTypes : [];

    const options = {
      selectableRows: "none",
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      customToolbar: () => {
        return (
          <>
            <Link to="/device-type/add" className="btn btn-success">
              Add Type
            </Link>
          </>
        );
      }
    };
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="m-portlet m-portlet--mobile">
            <MUIDataTable
              title={"Devices Type"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  state => ({
    deviceTypes: state.deviceTypes
  }),
  dispatch => ({
    dispatch,
    getDeviceType: () => dispatch(getDeviceTypeAction())
  })
)(TokenList);
