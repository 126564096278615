import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { validate, defaultValue } from "./ChangePasswordValidation";
import { changePasswordAction } from "../../actions/changePasswordActions";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";


const NewChangePassword = () => {
    const [loading, setLoading] = useState(false);
    const [showOldPasswordText, setShowOldPasswordText] = useState(false);
    const [showNewPasswordText, setShowNewPasswordText] = useState(false);
    const [showConfirmPasswordText, setShowConfirmPasswordText] = useState(false);

    /* breadCrumb */
    const ChangePassword = [
        {
            title: "Change Password",
            link: "/change-password",
            classData: "active_anv_link",
        }
    ]

    const handleShowOldPassword = () => {
        setShowOldPasswordText(!showOldPasswordText);
    }

    const handleShowNewPassword = () => {
        setShowNewPasswordText(!showNewPasswordText);
    }

    const handleShowConfirmPassword = () => {
        setShowConfirmPasswordText(!showConfirmPasswordText);
    }

    /* for submit event */
    const handleSubmit = async (inputs, { setSubmitting, resetForm }) => {
        const values = Object.assign({}, inputs);
        setSubmitting(true);
        setLoading(true);
        const changePassword = await changePasswordAction(values);
        if (changePassword.status === 200) {
            toast.success(changePassword.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            toast.error(changePassword.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setSubmitting(false);
        setLoading(false);
        resetForm();
    }

    return (
        <>
            <Formik
                initialValues={defaultValue}
                validationSchema={validate}
                onSubmit={handleSubmit}
            >
                {
                    ({
                        handleSubmit,
                        handleChange,
                        errors,
                        values,
                        touched,
                    }) => (
                        <div className="m-grid__item m-grid__item--fluid m-wrapper">
                            <div className="m-content">
                                <div className="m-portlet m-portlet--mobile">
                                    <div className="m-portlet__head">
                                        <div className="m-portlet__head-caption">
                                            <div className="row justify-content-between align-items-center GroupDetails-Block title-breadcrums--alignment">

                                                    <h2>Change Password</h2>
                                                <CustomBreadCrumb breadCrumData={ChangePassword} />

                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "25px 25px 0px 35px" }}>( <span style={{ color: 'red' }}>*</span> ) Required fields</div>
                                    <Form
                                        className="m-form m-form--fit m-form--label-align-right m-form--group-seperator"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="m-portlet__body">
                                            <Form.Group className="form-group m-form__group row">
                                                <Form.Label className="col-lg-2 col-form-label">
                                                    Old Password <span style={{ color: 'red' }}>*</span>
                                                </Form.Label>
                                                <div className="col-lg-6 m-form_password">
                                                    <Form.Control
                                                        type={showOldPasswordText ? "text" : "password"}
                                                        name="oldPassword"
                                                        className="form-control m-input"
                                                        value={values.oldPassword}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    />
                                                    <Button onClick={() => { handleShowOldPassword(); }} >
                                                        <i className={showOldPasswordText ? 'm-menu__link-icon fa fa-eye-slash' : 'm-menu__link-icon fa fa-eye'}></i>
                                                    </Button>
                                                    {errors.oldPassword && touched.oldPassword && (
                                                        <div className="srv-validation-message">
                                                            {errors.oldPassword}
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group m-form__group row">
                                                <Form.Label className="col-lg-2 col-form-label">
                                                    New Password <span style={{ color: 'red' }}>*</span>
                                                </Form.Label>
                                                <div className="col-lg-6 m-form_password">
                                                    <Form.Control
                                                        type={showNewPasswordText ? "text" : "password"}
                                                        name="newPassword"
                                                        className="form-control m-input"
                                                        value={values.newPassword}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    />
                                                    <Button onClick={() => { handleShowNewPassword(); }}>
                                                        <i className={showNewPasswordText ? 'm-menu__link-icon fa fa-eye-slash' : 'm-menu__link-icon fa fa-eye'}></i>
                                                    </Button>
                                                    {errors.newPassword && touched.newPassword && (
                                                        <div className="srv-validation-message">
                                                            {errors.newPassword}
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group m-form__group row">
                                                <Form.Label className="col-lg-2 col-form-label">
                                                    Confirm Password <span style={{ color: 'red' }}>*</span>
                                                </Form.Label>
                                                <div className="col-lg-6 m-form_password">
                                                    <Form.Control
                                                        type={showConfirmPasswordText ? "text" : "password"}
                                                        name="confirmPassword"
                                                        className="form-control m-input"
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    />
                                                    <Button onClick={() => { handleShowConfirmPassword(); }}>
                                                        <i className={showConfirmPasswordText ? 'm-menu__link-icon fa fa-eye-slash' : 'm-menu__link-icon fa fa-eye'}></i>
                                                    </Button>
                                                    {errors.confirmPassword && touched.confirmPassword && (
                                                        <div className="srv-validation-message">
                                                            {errors.confirmPassword}
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="m-portlet__foot m-portlet__foot--fit">
                                            <div className="m-form__actions m-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-6">
                                                        <button type="submit" className={`btn btn-primary ${loading
                                                                ? "m-loader m-loader--right m-loader--light"
                                                                : ""
                                                            }`}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Formik>
        </>
    )
}

export default connect(null, {
    changePasswordAction
})(NewChangePassword);