import { USER_LOADED, GET_USER_DETAIL, DELETE_USER_ACCOUNT } from '../actions/userActions'

export function userReducer(state = null, action) {
  switch (action.type) {

    case USER_LOADED:
      return action.users

    case GET_USER_DETAIL:
      return action.users
    
    case DELETE_USER_ACCOUNT:
      return action.users

    default:
      return state
  }
}