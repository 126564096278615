import * as commandService from "../services/CommandService";
export const COMMAND_LOADED = "COMMAND_LOADED";
export const ADMIN_TYPE_LOADED = "ADMIN_TYPE_LOADED";
export const ADMIN_DEVICE_LOADED = "ADMIN_DEVICE_LOADED";
export const COMMAND_SEND = "COMMAND_SEND";

export const getCommandsAction = () => {
  return (dispatch) => {
    commandService.getCommands().then((commands) => {
      dispatch({
        type: COMMAND_LOADED,
        commands,
      });
    });
  };
};

export const getTypesAction = () => {
  return (dispatch) => {
    commandService.getDeviceTypes().then((deviceAdminType) => {
      dispatch({
        type: ADMIN_TYPE_LOADED,
        deviceAdminType,
      });
    });
  };
};

export const getDevicesAction = () => {
  return (dispatch) => {
    commandService.getDevices().then((deviceslist) => {
      dispatch({
        type: ADMIN_DEVICE_LOADED,
        deviceslist,
      });
    });
  };
};

export function sendCommandAction(credentials) {
  return (dispatch) => {
    return commandService.sendCommand(credentials).then((command) => {
      dispatch({
        type: COMMAND_SEND,
        command,
      });
    });
  };
}

