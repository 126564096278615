import axios from 'axios'
import apiurl from '../config/api'
//import { SetLocalStorage, removeLocalStorage } from "./SMILocalStorage";

export function createClient(credentials) {
  return axios.post(apiurl.createClient, credentials)
    .then(result => {
      return result.data.data
    })
}

export function revokeToken(tokenId) {
  return axios.post(apiurl.revokeToken, tokenId)
    .then(result => {
      return result.data.data
    })
}

export function getClient() {
  return axios.get(apiurl.getClient)
    .then(result => {
      return result.data.data
    }).catch(error => {
      return error.response.data;
    });
}

export function getAuthTokens() {
  return axios.get(apiurl.getTokens)
    .then(result => {
      return result.data.data
    }).catch(error => {
      return error.response.data;
    });
}

export function sendOtp() {
  return axios.get(apiurl.sendOtp)
    .then(result => {
      return result.data
    }).catch(error => {
      return error.response;
    });
}

export function verifyOtp(data) {
  return axios.post(apiurl.verifyOtp, data)
    .then(result => {
      return result.data.data
    }).catch(error => {
      return error.response;
    });
}

export function getAuthClient(data) {
  return axios.post(apiurl.getAuthClient, data)
    .then(result => {
      return result.data.data
    }).catch(error => {
      return error.response;
    });
}