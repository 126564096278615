import * as dashboardService from "../services/DashBoardService";
export const LINECHARTDATA_FETCHED = "LINECHARTDATA_FETCHED"; 

export const getLineChartAction =   (reqObj) => {
    return (dispatch) => {
      return  dashboardService.getLineChart(reqObj).then((linechartData) => {
        dispatch({
          type: LINECHARTDATA_FETCHED,
          linechartData,
        });
        return linechartData;
      });
    };
  };