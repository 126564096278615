import {
  DEVICES_FETCHED,
  DEVICE_EDITED,
  DEVICE_DELETED,
  GET_SCHEDULED_DEVICES,
  GET_SCHEDULED_DEVICES_LOGS,
  ADD_SHARED_DEVICES,
  UPDATE_DEVICE_FIRMWARE,
  CREATE_MULTIPLE_SCHEDULER,
} from "../actions/devicesActions";

export function devicesReducer(state = [], action) {
  switch (action.type) {
    case DEVICES_FETCHED:
      return action.devices;

    default:
      return state;
  }
}

export function scheduledDevicesReducer(state = [], action) {
  switch (action.type) {
    case GET_SCHEDULED_DEVICES:
      return action.scheduledDevices;

    default:
      return state;
  }
}

export function createMultipleDeviceSchedulerReducer(state = [], action) {
  switch (action.type) {
    case CREATE_MULTIPLE_SCHEDULER:
      return action.scheduledDevices;

    default:
      return state;
  }
}

export function scheduledDevicesLogsReducer(state = [], action) {
  switch (action.type) {
    case GET_SCHEDULED_DEVICES_LOGS:
      return action.scheduledDevicesLogs;

    default:
      return state;
  }
}

export function saveSharedDevices(state = [], action) {
  switch (action.type) {
    case ADD_SHARED_DEVICES:
      return action.devices;

    default:
      return state;
  }
}

export function editDevicesReducer(state = [], action) {
  switch (action.type) {
    case DEVICE_EDITED:
      return action.devices;

    default:
      return state;
  }
}

export function updateDeviceFirmwareReducer(state = [], action) {
  switch (action.type) {
    case UPDATE_DEVICE_FIRMWARE:
      return action.devices;

    default:
      return state;
  }
}

export function deleteDeviceReducer(state = [], action) {
  switch (action.type) {
    case DEVICE_DELETED:
      return action.devices;

    default:
      return state;
  }
}
